// mui
import { Button, Grid, Stack, Typography } from "@mui/material";

//grid row component
export default function GridRow({ row, editChannels, removeEdit }) {
  const PrimaryRow = () => (
    <Grid container>
      <Grid
        item
        xs={4}
        style={{
          borderBottom: "1px solid #EBEBEB",
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Typography py="30px" fontSize={18} fontWeight={500} align="center">
          {row.channel}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          borderBottom: "1px solid #EBEBEB",
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Typography py="30px" fontSize={18} fontWeight={500} align="center">
          {row.primaryChannel}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          borderBottom: "1px solid #EBEBEB",
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Typography py="30px" fontSize={18} fontWeight={500} align="center">
          {row.refChannel || "N/A"}
        </Typography>
      </Grid>
    </Grid>
  );

  const BackupRow = ({ backupRow }) => (
    <Grid container>
      <Grid
        item
        xs={6}
        style={{
          borderBottom: "1px solid #EBEBEB",
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Typography
          py="30px"
          fontSize={18}
          fontWeight={500}
          align="center"
          color="#8A8A8A"
        >
          {backupRow.backupPrimaryChannel}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          borderBottom: "1px solid #EBEBEB",
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Typography
          py="30px"
          fontSize={18}
          fontWeight={500}
          align="center"
          color="#8A8A8A"
        >
          {backupRow.backupRefChannel || "NULL"}
        </Typography>
      </Grid>
    </Grid>
  );

  const BackupRows = ({ backup }) => (
    <Grid container>
      <Grid
        item
        xs={4}
        style={{
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Typography
            py="30px"
            fontSize={18}
            fontWeight={500}
            align="center"
            color="#8A8A8A"
          >
            Backup Channels
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={8}>
        {backup.map((backupRow, index) => (
          <BackupRow key={index} backupRow={backupRow} />
        ))}
      </Grid>
    </Grid>
  );

  const Row = () => (
    <Grid container>
      <Grid item xs={removeEdit ? 12 : 9}>
        <Stack direction="column">
          <PrimaryRow />
          {row.backups.length ? (
            <BackupRows backup={row.backups || []} />
          ) : null}
        </Stack>
      </Grid>
      {removeEdit ? (
        <></>
      ) : (
        <Grid
          item
          xs={3}
          style={{
            borderBottom: "1px solid #EBEBEB",
          }}
        >
          <Stack alignItems="center" justifyContent="center" height="100%">
            <Button
              sx={{ color: "#2F7EC7", textTransform: "none" }}
              size="small"
              variant="outlined"
              color="primary"
              onClick={(event) => {
                editChannels();
              }}
            >
              <Typography fontSize={16} fontWeight={500} color=" #2F7EC7">
                Edit Channel
              </Typography>
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  );

  return (
    <Stack
      direction="column"
      style={{
        backgroundColor: "#fff",
        borderRadius: "3px",
      }}
    >
      <Row />
    </Stack>
  );
}
