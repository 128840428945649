/////////////////React //////////////////////
import { useState, useEffect, useContext, useRef } from "react";

////////////////MUI//////////////////////////
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

////////////Internal Components///////////
import EdfStatus from "../components/dashboard_components/EdfStatus";
import DashboardCredits from "../components/credits/DashboardCredits";
import OngoingProcess from "../components/dashboard_components/OngoingProcess";
import RecentMontages from "../components/dashboard_components/RecentMontages";
import CreditsChart from "../components/charts/CreditsChart";

///////////////////////Internal Components///////////////////
import CreateMontage from "../components/montages/CreateMontage";
import ComponentLoader from "../components/loader/ComponentLoader";

// utils & helpers
import { httpErrorHandler, axiosConfig } from "../utils/helpers";

// context
import { notifyContext } from "../context/notifyContext";

// 3rd party
import { useNavigate } from "react-router-dom";
import AuthHOC from "../hoc/AuthHOC";
//axiosInstance
// import { axiosInstance } from "../utils/axiosInstance";
import useAxios from "../hooks/useAxios";
import { globalContext } from "../context/globalContext";

// get the crdits info
const getCreditsData = async (
  setDataLoading,
  setCreditsData,
  notifyCtx,
  navigate,
  axiosInstance,
  abortController
) => {
  setDataLoading((previousState) => {
    return { ...previousState, credits: { loading: true, error: true } };
  });
  try {
    const config = axiosConfig({ uri: "/credits/summary", method: "GET" });
    const response = await axiosInstance.current({
      ...config,
      signal: abortController.signal,
    });
    setCreditsData(response.data);

    setDataLoading((previousState) => {
      return { ...previousState, credits: { loading: false, error: false } };
    });
  } catch (error) {
    httpErrorHandler(error, notifyCtx, navigate);
    if (!error.message === "canceled")
      setDataLoading((previousState) => {
        return { ...previousState, credits: { loading: false, error: true } };
      });
  }
};
// get the recent processed montages
const getRecentMontagesData = async (
  setDataLoading,
  setRecentlyCreatedMontagesData,
  notifyCtx,
  navigate,
  axiosInstance,
  abortController
) => {
  setDataLoading((previousState) => {
    return {
      ...previousState,
      montages: { loading: true, error: false },
    };
  });
  try {
    const config = axiosConfig({ uri: "/montages/overview/", method: "GET" });
    const response = await axiosInstance.current({
      ...config,
      signal: abortController.signal,
    });

    setRecentlyCreatedMontagesData(response.data);

    setDataLoading((previousState) => {
      return {
        ...previousState,
        montages: { loading: false, error: false },
      };
    });
  } catch (error) {
    httpErrorHandler(error, notifyCtx, navigate);
    if (!error.message === "canceled")
      setDataLoading((previousState) => {
        return { ...previousState, montages: { loading: false, error: true } };
      });
  }
};
// get graph data
const getGraphData = async (
  setDataLoading,
  setGraphData,
  notifyCtx,
  navigate,
  axiosInstance,
  abortController
) => {
  setDataLoading((previousState) => {
    return { ...previousState, graph: { loading: true, error: true } };
  });
  try {
    const config = axiosConfig({
      uri: "/credits/usage-history-overview",
      method: "GET",
    });
    const response = await axiosInstance.current({
      ...config,
      signal: abortController.signal,
    });
    setGraphData(response.data);
    setDataLoading((previousState) => {
      return { ...previousState, graph: { loading: false, error: false } };
    });
  } catch (error) {
    httpErrorHandler(error, notifyCtx, navigate);
    if (!error.message === "canceled")
      setDataLoading((previousState) => {
        return { ...previousState, graph: { loading: false, error: true } };
      });
  }
};

// get edf status data
const getEdfStatusData = async (
  setDataLoading,
  setEdfStatusData,
  notifyCtx,
  navigate,
  axiosInstance,
  abortController
) => {
  setDataLoading((previousState) => {
    return { ...previousState, edfStatus: { loading: true, error: true } };
  });
  try {
    const config = axiosConfig({
      uri: "/calls/summary",
      method: "GET",
    });
    const response = await axiosInstance.current({
      ...config,
      signal: abortController.signal,
    });
    setEdfStatusData(response.data);
    setDataLoading((previousState) => {
      return { ...previousState, edfStatus: { loading: false, error: false } };
    });
  } catch (error) {
    httpErrorHandler(error, notifyCtx, navigate);
    if (!error.message === "canceled")
      setDataLoading((previousState) => {
        return { ...previousState, edfStatus: { loading: false, error: true } };
      });
  }
};

function Dashboard({ user, dispatch, ...props }) {
  const [creditsData, setCreditsData] = useState({});
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const [graphData, setGraphData] = useState([]);
  const [edfStatusData, setEdfStatusData] = useState({});
  const [recentlyCreatedMontagesData, setRecentlyCreatedMontagesData] =
    useState([]);
  const [dataLoading, setDataLoading] = useState({
    credits: { loading: false, error: false },
    calls: { loading: false, error: false },
    graph: { loading: false, error: false },
    edfStatus: { loading: false, error: false },
    montages: { loading: false, error: false },
  });

  const navigate = useNavigate();
  const notifyCtx = useContext(notifyContext);
  const { setPageTitle } = useContext(globalContext);

  useEffect(() => {
    setPageTitle("Dashboard");
    const abortController = new AbortController();
    (async () => {
      getCreditsData(
        setDataLoading,
        setCreditsData,
        notifyCtx,
        navigate,
        axiosInstance,
        abortController
      );
      getGraphData(
        setDataLoading,
        setGraphData,
        notifyCtx,
        navigate,
        axiosInstance,
        abortController
      );
      getEdfStatusData(
        setDataLoading,
        setEdfStatusData,
        notifyCtx,
        navigate,
        axiosInstance,
        abortController
      );
      getRecentMontagesData(
        setDataLoading,
        setRecentlyCreatedMontagesData,
        notifyCtx,
        navigate,
        axiosInstance,
        abortController
      );
    })();

    return () => {
      abortController.abort();
    };
  }, [notifyCtx, navigate, setPageTitle]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper
      sx={{
        px: { xs: "26px", sm: "36px", md: "36px", lg: "40px" },
        background: "#F5F6FA",
        height: "100%",
        pb: "24px",
      }}
      elevation={0}
    >
      <Grid container columnSpacing={3} alignItems="stretch">
        <Grid item xs={12} lg={12} xl={7}>
          <Grid container columnSpacing={3} rowSpacing={3}>
            <Grid item xs={12} sm={6}>
              {dataLoading.credits.loading || dataLoading.credits.error ? (
                <ComponentLoader
                  error={dataLoading.credits.error}
                  loading={dataLoading.credits.loading}
                  retry={() => {
                    getCreditsData(
                      setDataLoading,
                      setCreditsData,
                      notifyCtx,
                      navigate,
                      axiosInstance
                    );
                  }}
                />
              ) : (
                <>
                  <DashboardCredits
                    percentage={
                      creditsData.used
                        ? (creditsData.used /
                            (creditsData.available + creditsData.used)) *
                          100
                        : 0
                    }
                    text="Total Credits Used"
                    credits={creditsData.used}
                    progressColor="#315CAF"
                    progressText="#315CAF"
                    shadow="rgba(31, 117, 197, 0.2)"
                    sx={{ my: 0, mx: 0, mb: "24px" }}
                  ></DashboardCredits>
                  <DashboardCredits
                    percentage={
                      creditsData.available
                        ? (creditsData.available /
                            (creditsData.available + creditsData.used)) *
                          100
                        : 0
                    }
                    text="Total Credits Available"
                    credits={creditsData.available}
                    progressColor="#D15B5B"
                    shadow="#f6dedf"
                    sx={{ my: 0, mx: 0 }}
                  ></DashboardCredits>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {dataLoading.edfStatus.loading || dataLoading.edfStatus.error ? (
                <ComponentLoader
                  error={dataLoading.edfStatus.error}
                  loading={dataLoading.edfStatus.loading}
                  retry={() => {
                    getEdfStatusData(
                      setDataLoading,
                      setEdfStatusData,
                      notifyCtx,
                      navigate,
                      axiosInstance
                    );
                  }}
                />
              ) : (
                <EdfStatus edfStatusData={edfStatusData} />
              )}
            </Grid>
            <Grid item xs={12} sx={{ mb: "40px" }}>
              <OngoingProcess />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} xl={5}>
          <Grid container sx={{}}>
            <Grid item xs={12} sx={{}}>
              {dataLoading.graph.loading || dataLoading.graph.error ? (
                <ComponentLoader
                  error={dataLoading.graph.error}
                  loading={dataLoading.graph.loading}
                  retry={() => {
                    getGraphData(
                      setDataLoading,
                      setGraphData,
                      notifyCtx,
                      navigate,
                      axiosInstance
                    );
                  }}
                />
              ) : (
                <Paper
                  elevation={0}
                  sx={{ width: "100%", height: "100%", p: "24px" }}
                >
                  <Typography
                    component="h3"
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "23px",
                      textAlign: "left",
                      color: "#0F0F0F",
                      mb: "16px",
                    }}
                  >
                    YOUR CREDITS USAGE
                  </Typography>
                  <CreditsChart graphData={graphData} />
                </Paper>
              )}
            </Grid>
            <Grid item xs={12}>
              {dataLoading.montages.loading || dataLoading.montages.error ? (
                <ComponentLoader
                  error={dataLoading.montages.error}
                  loading={dataLoading.montages.loading}
                  retry={() => {
                    getRecentMontagesData(
                      setDataLoading,
                      setRecentlyCreatedMontagesData,
                      notifyCtx,
                      navigate,
                      axiosInstance
                    );
                  }}
                />
              ) : (
                <RecentMontages
                  data={recentlyCreatedMontagesData}
                  sx={{ p: "24px" }}
                />
              )}
              <CreateMontage />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default AuthHOC(Dashboard);
