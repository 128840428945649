import { createContext, useState, useReducer } from "react";
import { logout, HandleAuthToken, DEBUG } from "../utils/helpers";

//global context
export const globalContext = createContext();

function signInUser({ user, accessToken = null, refreshToken = null }) {
  const authToken = new HandleAuthToken();
  authToken.addData(user, accessToken, refreshToken);
  return { ...user };
}

async function signOutUser(globalCtx) {
  const authToken = new HandleAuthToken();

  // construct logout request body for debug and prod env
  await logout({
    refreshToken: authToken.retrieveRefreshToken(),
    abortController: globalCtx.abortController,
  });
  authToken.clearData();
  return null;
}

function updateUser(user) {
  const authToken = new HandleAuthToken();
  authToken.addUser(user);
  return { ...user };
}

function updateToken({ token, expiration }) {
  const authToken = new HandleAuthToken();
  if (DEBUG) authToken.addToken(token);
  authToken.addTokenExp(expiration);
}
function updateRefreshToken({ token, expiration }) {
  const authToken = new HandleAuthToken();
  if (DEBUG) authToken.addToken(token);
  authToken.addTokenExp(expiration);
}

const initialState = {
  authenticated: false,
  user: undefined,
  loading: true,
  error: null,
  autoLogoutIntervalId: undefined,
};
function reducer(state, action) {
  switch (action.type) {
    case "signin":
      return {
        authenticated: true,
        loading: false,
        user: signInUser({ ...action.payload }),
        error: action.error,
      };
    case "signout":
      return {
        authenticated: false,
        loading: false,
        user: signOutUser(action.globalCtx),
        error: action.error,
      };
    case "update_user":
      return {
        ...state,
        user: updateUser(action.user),
      };
    case "app_loaded":
      return {
        authenticated: action.authenticated,
        loading: false,
        error: action.error,
        user: action.user,
      };
    case "auto_logout_interval_id":
      return {
        ...state,
        autoLogoutIntervalId: action.autoLogoutIntervalId,
      };
    case "update_token":
      updateToken({ ...action.payload });
      return { ...state };
    case "update_refresh_token":
      updateRefreshToken({ ...action.payload });
      return { ...state };
    default:
      return state;
  }
}
export const GlobalContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [pageTitle, setPageTitle] = useState("");
  const [isGettingToken, setIsGettingToken] = useState(false);
  const [openProgressStatus, setOpenProgressStatus] = useState(false);
  const [createMontageDialog, setCreateMontageDialog] = useState(false);
  const [montageNamingDone, setMontageNamingDone] = useState(false);
  const [progressStatusMessage, setProgressStatusMessage] =
    useState("Loading ");
  const abortController = new AbortController();
  ////consent opened (previously uploaded files found) then don't redirect from file upload
  const [isUserConsentOpen, setIsUserConsentOpen] = useState(false);
  // const [channelFetchingProgress, setChannelFetchingProgress] = useState(false);
  //sidebar credits
  const [sidebarCredits, setSidebarCredits] = useState({
    loading: false,
    available: 0,
    used: 0,
    error: false,
  });
  const [showErrorPage, setShowErrorPage] = useState(false);
  //////////User consent //////
  const [userConsentTitle, setUserConsentTitle] = useState("");
  const [showUserConsent, setShowUserConsent] = useState(false);
  const [consentMessage, setConsentMessage] = useState([]);
  const [consentAction, setConsentAction] = useState({
    onNext: {
      title: "Contnue",
      handler: () => {},
      disable: false,
    },
    onBack: {
      title: "Back",
      handler: () => {},
      disable: false,
    },
    onCancel: {
      title: "Cancel",
      handler: () => {},
      disable: false,
    },
  });
  const setConsent = (show, title = "", message = "", actions = {}) => {
    setShowUserConsent(show);
    setUserConsentTitle(title);
    setConsentMessage(message);
    if (Object.keys(actions).length > 0) {
      setConsentAction({
        onNext: {
          title: actions.onNext.title,
          handler: actions.onNext.handler,
          disable: actions.onNext.disable,
        },
        onBack: {
          title: actions.onBack.title,
          handler: actions.onBack.handler,
          disable: actions.onBack.disable,
        },
        onCancel: {
          title: actions.onCancel.title,
          handler: actions.onCancel.handler,
          disable: actions.onCancel.disable,
        },
      });
    }
  };
  //set montage naming is done
  const setMontageNaming = (status) => {
    setMontageNamingDone(status);
    localStorage.setItem("mnd", status);
  };

  return (
    <globalContext.Provider
      value={{
        createMontageDialog,
        setCreateMontageDialog,
        pageTitle,
        setPageTitle,
        openProgressStatus,
        setOpenProgressStatus,
        progressStatusMessage,
        setProgressStatusMessage,
        montageNamingDone,
        setMontageNamingDone,
        userConsentTitle,
        setUserConsentTitle,
        showUserConsent,
        setShowUserConsent,
        consentMessage,
        setConsentMessage,
        consentAction,
        setConsentAction,
        setConsent,
        setMontageNaming,
        isUserConsentOpen,
        setIsUserConsentOpen,
        sidebarCredits,
        setSidebarCredits,
        showErrorPage,
        setShowErrorPage,
        state,
        dispatch,
        isGettingToken,
        setIsGettingToken,
        abortController,
      }}
    >
      {props.children}
    </globalContext.Provider>
  );
};
