///////////////MUI////////////
import Stack from "@mui/material/Stack";
///////Internal Components////////////
import DashboardCredits from "../credits/DashboardCredits";
import CreditsTable from "../CreditsTable";
import CustomPagination from "../CustomPagination";

///////Main Code///////////////
const TotalCredits = (props) => {
  const {
    totalCreditsUsed,
    totalCreditsPurchased,
    totalCreditsAvailable,
    data,
  } = props;
  const cols = [
    {
      name: "Credits Added",
    },
    {
      name: "Credits Available",
    },
    {
      name: "Credits Used",
    },
    {
      name: "Added On",
    },
    {
      name: "Credits Validity",
    },
    {
      name: "Active",
    },
  ];
  return (
    <Stack
      sx={{ width: "100%", mb: "4rem" }}
      direction="column"
      alignItems="center"
      spacing={1}
    >
      <Stack
        sx={{
          width: "100%",

          my: "18px",
          justifyContent: { xs: "center", sm: "flex-start" },
          alignItems: "center",
        }}
        direction="row"
        spacing={1}
        flexWrap="wrap"
        gap={3}
      >
        {/* START: TOTAL CREDITS USED CARD */}
        <DashboardCredits
          percentage={
            totalCreditsPurchased
              ? parseFloat(
                  (totalCreditsUsed / totalCreditsPurchased) * 100
                ).toFixed(2)
              : 0
          }
          text="Total Credits Used"
          credits={totalCreditsUsed}
          progressColor="#315CAF"
          progressText="#315CAF"
          shadow="rgba(31, 117, 197, 0.2)"
          sx={{
            // mr: { xs: 0, sm: "24px" },
            // mb: { xs: "24px", sm: "0", md: 0 },
            // minWidth: { xs: 0, md: "332px" },
            width: { xs: "100%", sm: "332px" },
          }}
        />
        {/* END: TOTAL CREDITS USED CARD */}
        {/* START: TOTAL CREDITS AVAILABLE CARD */}
        <DashboardCredits
          percentage={
            totalCreditsPurchased
              ? parseFloat(
                  (totalCreditsAvailable / totalCreditsPurchased) * 100
                ).toFixed(2)
              : 0
          }
          text="Total Credits Available"
          credits={totalCreditsAvailable}
          progressColor="#D15B5B"
          shadow="#f6dedf"
          sx={{
            ml: "0 !important",
            // minWidth: { xs: 0, md: "332px" },
            width: { xs: "100%", sm: "332px" },
          }} //removing margin set by stack
        />
        {/* END: TOTAL CREDITS AVAILABLE CARD */}
      </Stack>
      {/* START: TABLE COMPONENT */}
      <CreditsTable cols={cols} rows={data} status={true} />
      {/* END: TABLE COMPONENT */}
      {/* START: PAGINATION */}
      {!props.data.previous && !props.data.next ? null : (
        <CustomPagination
          disabled={props.loading}
          last_page_no={Math.ceil(props.data.count / props.PAGE_SIZE)}
          limit={props.data.results.length}
          handlePaginationChange={(_, value) => props.setPage(value)}
        />
      )}
      {/* END: PAGINATION */}
    </Stack>
  );
};

export default TotalCredits;

TotalCredits.defaultProps = {
  totalCreditsAvailable: 0,
  totalCreditsPurchased: 0,
  totalCreditsUsed: 0,
  data: [],
};
