import { useContext, memo } from "react";
//////////MUI/////////
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import LinearProgress from "@mui/material/LinearProgress";
import CloseIcon from "@mui/icons-material/Close";

//context
import { globalContext } from "../../context/globalContext";
//assets
import SmallFileIcon from "../../assets/montage/small_file_icon.svg";
import FileMissingIcon from "../../assets/montage/FileMissingIcon.svg";

export const SmallFileIconSvg = (props) => {
  return (
    <Icon sx={{ ...props.sx }}>
      <img
        src={SmallFileIcon}
        alt="small_file_icon"
        style={{ maxWidth: "100%" }}
      ></img>
    </Icon>
  );
};
export const SmallFileMissingIconSvg = (props) => {
  return (
    <Icon sx={{ ...props.sx }}>
      <img
        src={FileMissingIcon}
        alt="small_file_icon"
        style={{ maxWidth: "100%" }}
      ></img>
    </Icon>
  );
};
export const FileComponent = (props) => {
  return (
    <Paper
      sx={{
        p: "20px 27px",
        borderBottom: "1px solid #D2D2D2",
        background: "#F5F6FA",
      }}
      elevation={0}
    >
      <Stack direction="row" alignItems="center">
        <Box minWidth="40%">
          {props.skipped ? (
            <SmallFileMissingIconSvg sx={{ mr: "18px", width: "14px" }} />
          ) : (
            <SmallFileIconSvg sx={{ mr: "18px", width: "14px" }} />
          )}

          <Typography
            component="span"
            sx={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "23px",
              color: "#0F0F0F",
              mr: "22px",
            }}
            title={`${props.name}`}
          >
            {props.name.length > 30
              ? props.name.slice(0, 30) + "..."
              : props.name}
          </Typography>

          {props.size ? (
            <Typography
              component="span"
              sx={{
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "23px",

                color: "#8A8A8A",
              }}
            >
              {props.size < 10 ** 5
                ? (props.size * 0.000977).toFixed(2) + "Kb"
                : (props.size * 0.00000095).toFixed(2) + "Mb"}
            </Typography>
          ) : (
            <></>
          )}
        </Box>
        {props.showProgress ? (
          <Box minWidth="50%">
            <LinearProgress
              sx={{
                "& .MuiLinearProgress-bar": {
                  background: props.progress === 100 ? "#10A44B" : "#2CA9E3",
                },
              }}
              variant="determinate"
              value={props.progress}
            />
          </Box>
        ) : (
          <></>
        )}
        {props.closeIcon ? (
          <Box minWidth="10%" textAlign={"right"}>
            <CloseIcon size="small" sx={{ cursor: "pointer" }} />
          </Box>
        ) : (
          <></>
        )}
      </Stack>
    </Paper>
  );
};

function UserConsent(props) {
  const ctx = useContext(globalContext);

  const [title, description, severity] = ctx.consentMessage;

  return (
    <div style={{ width: "800px" }}>
      <Dialog
        open={ctx.showUserConsent}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <Paper
          elevation={0}
          sx={{
            background: "#F5F6FA",
            // px: "32px",
            pt: "32px",
          }}
        >
          <Stack sx={{ px: "24px", mb: "16px" }}>
            <Typography
              id="alert-dialog-title"
              sx={{
                color: "#0F0F0F",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "26px",
              }}
            >
              {props.montageInfo ? props.montageInfo.title : " "}
            </Typography>
            <Typography
              component="span"
              sx={{
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",

                color: "#8A8A8A",
              }}
            >
              Last updated on: 24 April 2022 at 6:00 PM
            </Typography>
          </Stack>
          <Paper elevation={0} sx={{ p: "16px", mx: "24px" }}>
            <Stack>
              <Typography
                component="span"
                sx={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "21px",
                  color:
                    severity === "success"
                      ? "#10A44B"
                      : severity === "error"
                      ? "#E03A3A"
                      : "rgb(41, 182, 246)",
                  mb: "8px",
                }}
              >
                {title}
              </Typography>
              <Typography
                component="span"
                sx={{
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#959595",
                }}
              >
                {description}
              </Typography>
            </Stack>
          </Paper>
          <Box sx={{ overflowY: "scroll", height: "215px" }}>
            {props.files.length > 0 ? (
              props.files.map((file, index) => {
                return <FileComponent key={index} name={file.file_name} />;
              })
            ) : (
              <></>
            )}
            {props.skipped.length > 0 ? (
              props.skipped.map((file, index) => {
                return (
                  <FileComponent
                    key={index}
                    name={file.file_name}
                    skipped={true}
                  />
                );
              })
            ) : (
              <></>
            )}
            {props.anonymizing.length > 0 ? (
              props.anonymizing.map((file, index) => {
                return (
                  <FileComponent
                    key={index}
                    name={file.file_name}
                    skipped={true}
                  />
                );
              })
            ) : (
              <></>
            )}
          </Box>
          <Paper elevation={0} sx={{ py: "15px", px: "24px" }}>
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <Button
                  onClick={ctx.consentAction.onCancel.handler}
                  disabled={ctx.consentAction.onCancel.disable}
                  sx={{ color: "#959595", textTransform: "capitalize" }}
                >
                  {ctx.consentAction.onCancel.title}
                </Button>
              </Box>
              <Box>
                <Button
                  sx={{
                    mr: "8px",
                    color: "#2F7EC7",
                    textTransform: "capitalize",
                  }}
                  onClick={ctx.consentAction.onBack.handler}
                  disabled={ctx.consentAction.onBack.disable}
                >
                  {ctx.consentAction.onBack.title}
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    background: "#2F7EC7",
                    color: "#fff",
                    ":hover": {
                      background: "#266baa",
                    },
                    textTransform: "capitalize",
                  }}
                  onClick={ctx.consentAction.onNext.handler}
                  disabled={ctx.consentAction.onNext.disable}
                  autoFocus
                >
                  {ctx.consentAction.onNext.title}
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Paper>
      </Dialog>
    </div>
  );
}

export default memo(UserConsent);

UserConsent.defaultProps = {
  files: [],
  skipped: [],
  anonymizing: [],
  montageInfo: null,
};
