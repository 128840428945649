///////////////////React//////////////////
import { useState, useContext, useEffect, useCallback, useRef } from "react";
///////////////MUI///////////////////////
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
//////////////////Internal components////////////////
import Status from "../../components/status/Status";
import ComponentLoader from "../loader/ComponentLoader";
import CustomPagination from "../CustomPagination";
//////////////3rd party//////////////////
import { Link, useNavigate } from "react-router-dom";
//hooks
import useAxios from "../../hooks/useAxios";
//helpers & utils
import { httpErrorHandler } from "../../utils/helpers";
//context
import { notifyContext } from "../../context/notifyContext";
import { axiosConfig } from "../../utils/helpers";
////////////Main Code/////////////////

///////custom table header cell////////////
const TableCellHeader = styled(TableCell, {
  name: "StyledTableCellHeader",
})({
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "18px",
  /* identical to box height */
  color: "#4A4A4A",
  border: 0,
});

///////custom table body cell////////////
const TableCellBody = styled(TableCell)({
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "21px",
  /* identical to box height */
  color: "#0F0F0F",
  border: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: "150px",
});

const table_header = ["Montage Name", "EDF Name", "Status", ""];

export default function OngoingProcess(props) {
  const [status, setStatus] = useState("processed");
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 5;
  const [count, setCount] = useState(0);
  const isStatusChanged = useRef(null);

  const [dataLoading, setDataLoading] = useState({
    loading: false,
    error: false,
  });
  const [filteredCalls, setFilteredCalls] = useState([]);

  const getCallsData = useCallback(
    async (
      setFilteredCalls,
      setDataLoading,
      notifyCtx,
      navigate,
      status,
      setCount,
      abortController
    ) => {
      setDataLoading((previousState) => {
        return { ...previousState, loading: true };
      });
      try {
        const config = axiosConfig({
          uri: "/calls",
          method: "GET",

          params: {
            status: status,
            page: isStatusChanged.current ? 1 : page,
            page_size: PAGE_SIZE,
            ordering: "-created_on",
          },
        });

        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setFilteredCalls(response.data.results);
        setCount(response.data?.count);
        setDataLoading((previousState) => {
          return { ...previousState, loading: false };
        });
      } catch (error) {
        httpErrorHandler(error, notifyCtx, navigate);
        if (!error.message === "canceled") {
          setDataLoading((previousState) => {
            return { loading: false, error: true };
          });
        }
      }
    },
    [page]
  );

  const notifyCtx = useRef();
  notifyCtx.current = useContext(notifyContext);
  const navigate = useRef();
  navigate.current = useNavigate();

  const handleChange = async (event) => {
    setStatus(event.target.value);
    setPage(1);
  };
  useEffect(() => {
    const abortController = new AbortController();

    getCallsData(
      setFilteredCalls,
      setDataLoading,
      notifyCtx,
      navigate,
      status,
      setCount,
      abortController
    );
    return () => {
      abortController.abort();
    };
  }, [status, getCallsData]);
  return (
    <Paper
      elevation={0}
      sx={{
        // minHeight: "565px",
        height: "565px",
        position: "relative",
      }}
    >
      <Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            sx={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "23px",
              color: "#0F0F0F",
              p: "24px",
            }}
          >
            ONGOING PROCESSING
          </Typography>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Status</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={status}
              label="Status"
              onChange={handleChange}
            >
              <MenuItem value="processing">Processing</MenuItem>
              <MenuItem value="init">Not Started</MenuItem>
              <MenuItem value="error">Error</MenuItem>
              <MenuItem value="processed">Completed</MenuItem>
            </Select>
          </FormControl>
        </Box>
        {dataLoading.loading || dataLoading.error ? (
          <ComponentLoader
            loading={dataLoading.loading}
            error={dataLoading.error}
            retry={() => {
              getCallsData(
                setFilteredCalls,
                setDataLoading,
                notifyCtx,
                navigate,
                status
              );
            }}
            sx={{ height: "80%" }}
          />
        ) : (
          <TableContainer
            sx={{
              width: "100%",
              boxShadow: "none",
              overflowY: "auto",
              position: "absolute",
              left: 0,
              height: "490px",
            }}
            component={Paper}
          >
            <Table sx={{ height: "min-content" }}>
              <TableHead
                sx={{
                  background: "#F9F9F9",
                  borderColor: "transparent",
                  position: "sticky",
                  top: 0,
                }}
              >
                <TableRow>
                  {table_header.map((element, index) => {
                    return (
                      <TableCellHeader
                        key={index}
                        className="table-cell-header"
                        sx={{
                          "&  .MuiTableCell-root": {
                            backgroundColor: "pink",
                            display: "none",
                          },
                        }}
                      >
                        {element}
                      </TableCellHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCalls.length ? (
                  filteredCalls.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCellBody title={row?.montage?.title}>
                          {row?.montage?.title}
                        </TableCellBody>
                        <TableCellBody title={row?.file_name}>
                          {row?.file_name}
                        </TableCellBody>
                        <TableCellBody sx={{ minWidth: "170px" }}>
                          <Status status={row?.status} />
                        </TableCellBody>
                        <TableCellBody
                          sx={{
                            fontSize: "14px",
                            lineHeight: "18px",
                            fontWeight: "500",
                            color: row?.montage?.config
                              ? "#2F7EC7"
                              : "#C4C4C4;",
                          }}
                        >
                          <Link
                            onClick={() => {
                              localStorage.setItem(
                                "current_montage",
                                JSON.stringify(row.montage)
                              );
                            }}
                            style={{
                              cursor: row?.montage?.config
                                ? "pointer"
                                : "default",
                            }}
                            to={
                              row?.montage?.config
                                ? `/montages/view/${row.montage.title}/${row.file_name}/${row.id}`
                                : "#"
                            }
                            title={
                              row?.montage?.config
                                ? `/montages/view/${row.montage.title}/${row.file_name}/${row.id}`
                                : "Montage Not Configured"
                            }
                          >
                            View
                          </Link>
                        </TableCellBody>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCellBody>
                      <Typography component="span" sx={{ px: "1rem" }}>
                        Nothing to show here
                      </Typography>
                    </TableCellBody>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      <CustomPagination
        disabled={dataLoading?.loading}
        last_page_no={Math.ceil(count / PAGE_SIZE)}
        limit={filteredCalls?.length}
        handlePaginationChange={(_, value) => {
          setPage(value);
        }}
        sx={{
          mt: "2rem",
          position: "absolute",

          paddingLeft: "0",
        }}
      />
    </Paper>
  );
}
