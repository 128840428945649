//react
import {
  createContext,
  useState,
  useMemo,
  useEffect,
  useContext,
  useRef,
} from "react";
// 3rd party

import ReconnectingWebSocket from "reconnecting-websocket";
//utils and helper
import { WEBSOCKET_URL, axiosConfig } from "../utils/helpers";
import { socketMessageHandler } from "../utils/helpers";
//context
import { notifyContext } from "../context/notifyContext";
import { globalContext } from "../context/globalContext";
//hooks
import useAxios from "../hooks/useAxios";

export const socketContext = createContext();
export function SocketProvider({ children }) {
  const notifyCtx = useContext(notifyContext);
  const globalCtx = useContext(globalContext);

  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //
  // const [getNewToken, setGetNewToken] = useState(true);

  //   websocket states
  const [connecting, setConnecting] = useState(true);
  const [connected, setConnected] = useState(false);
  const [message, setMessage] = useState({});
  // const [socketClosed, setSocketClosed] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const config = axiosConfig({ method: "GET", uri: "/websocket/token" });
        const response = await axiosInstance.current({
          ...config,
        });
        setToken(response.data.UID);
      } catch (error) {
        setError(error.request);
      }
      setLoading(false);
    })();
  }, []);

  // connect with websocket
  const socket = useMemo(() => {
    // const options = {
    //   minReconnectionDelay: 5000,
    //   connectionTimeout: 5000,
    //   maxRetries: 20,
    // };
    //For more info refer - https://github.com/joewalnes/reconnecting-websocket

    const url = WEBSOCKET_URL + `/ws/live/?token=${token}`;
    if (token) return new ReconnectingWebSocket(url, []);
    return null;
  }, [token]);

  useEffect(() => {
    // let intervalId = null;
    if (socket) {
      socket.onopen = (e) => {
        // do any more configs when socket conn is opened
        setConnected(true);
        setConnecting(false);
      };

      socket.onmessage = (e) => {
        try {
          setMessage(JSON.parse(e.data));

          socketMessageHandler(setMessage, globalCtx, notifyCtx, e.data);
        } catch (error) {
          setMessage(e.data);
        }
      };
      socket.onclose = (e) => {
        setConnected(false);
        setConnecting(true);
      };
      socket.onerror = (e) => {
        // console.log(e);
      };
    }
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, [socket]); // eslint-disable-line react-hooks/exhaustive-deps

  const props = {
    connecting,
    connected,
    error,
    fetchingToken: loading,
    message,
    setMessage,
    socket,
  };

  return (
    <socketContext.Provider value={props}>{children}</socketContext.Provider>
  );
}
