import { useContext, memo } from "react";
/////////////////////////////MUI///////////////////////////
import { Paper, Stack, Typography, Box } from "@mui/material";

////////////////3rd party////////////////////////////
import { Link } from "react-router-dom";
////////////////////internal components//////////////
import Btn from "../buttons/Btn";

//////////Utils and helpers ///////////////
import urls from "../../utils/urls.json";
//////////////context///////////////
import { globalContext } from "../../context/globalContext";

const RecentMontageInner = (props) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: "14px",
        background: "#F5F6FA",
        borderRadius: "8px",
        mb: "8px",
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography
          component="span"
          sx={{
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "21px",
            textAlign: "left",
            color: "#0F0F0F",
          }}
        >
          {props.montageName}
        </Typography>
        <Link
          to={`${urls.montage_view}${encodeURIComponent(props.montageName)}/${
            props.montageId
          }`}
          style={{
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: "500",
            color: "#2F7EC7",
            fontFamily: "Inter,sans-serif",
          }}
        >
          View
        </Link>
      </Stack>
    </Paper>
  );
};
function RecentMontages(props) {
  const context = useContext(globalContext);
  const createMontageHandler = (event) => {
    context.setCreateMontageDialog(true);
  };
  return (
    <Paper
      sx={{ height: "100%", minHeight: "340px", ...props.sx }}
      elevation={0}
      className="recent-montages"
    >
      <Typography
        component="h3"
        sx={{
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "23px",
          textAlign: "left",
          color: "#0F0F0F",
          mb: "8px",
        }}
      >
        RECENTLY CREATED MONTAGES
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "90%",
        }}
      >
        {props.data.length ? (
          <Stack>
            {props.data.map((montage, index) => {
              return (
                <RecentMontageInner
                  key={index}
                  montageName={montage.title}
                  montageId={montage.id}
                />
              );
            })}
          </Stack>
        ) : (
          <Typography>Nothing to show here</Typography>
        )}
        <Box>
          <Btn
            title="Create a New Montage"
            type="contained"
            color="#ffffff"
            sx={{ width: "100%", mb: "8px" }}
            onClick={createMontageHandler}
          ></Btn>
          <Link to={urls.montages}>
            <Btn
              title="Upload an EDF"
              type="outlined"
              color="#2F7EC7"
              sx={{
                width: "100%",
                borderColor: "#2F7EC7",
                "&:hover": {
                  color: "#0f0f0f",
                  background: "#ffffff",
                },
              }}
            ></Btn>
          </Link>
        </Box>
      </Box>
    </Paper>
  );
}
export default memo(RecentMontages);
RecentMontageInner.defaultProps = {
  montageName: "Oura Study",
};

RecentMontages.defaultProps = {
  data: ["Oura_Study", "Biostrap_Test#1", "Biostrap_Test#2"],
};
