//react
import { createContext, useState } from "react";

export const notifyContext = createContext();

export function NotifyProvider(props) {
  //set error
  const [notify, setNotify] = useState({
    open: false,
    severity: "success",
    title: "",
    message: "",
    action: () => {
      console.log("Action done on notification");
    },
  });

  return (
    <notifyContext.Provider value={{ notify, setNotify }}>
      {props.children}
    </notifyContext.Provider>
  );
}
