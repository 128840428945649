//react
import { useContext } from "react";
//3rd party
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
//internal
import ForgotPasswordForm from "../components/forms/forgotPasswordForm";
//hooks
import useAuth from "../hooks/useAuth";
//utils and helpers
import { axiosConfig, notificationsHandler } from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
import { httpErrorHandler } from "../utils/helpers";
//context
import { notifyContext } from "../context/notifyContext";

function ForgotPassword() {
  const [auth] = useAuth();

  const notifyCtx = useContext(notifyContext);

  const navigate = useNavigate();

  const handleSubmit = async (email) => {
    try {
      const config = axiosConfig({
        uri: `/auth/password/reset/`,
        method: "POST",
        data: { email },
      });

      await axios({
        ...config,
      });
      notificationsHandler(
        notifyCtx,
        "success",
        "Reset password email has been sent"
      );
    } catch (error) {
      httpErrorHandler(error, notifyCtx, navigate);
    }
  };

  if (auth.authenticated) {
    return <Navigate to={urlpatterns.dashboard} />;
  }

  return (
    <div>
      <ForgotPasswordForm handleSubmit={handleSubmit} />
    </div>
  );
}

export default ForgotPassword;
