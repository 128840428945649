// --------------------React-------------------
import { useContext } from "react";
// -----------------MUI-------------------
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
//----------------Images----------------
import NoMontageImg from "../../assets/no_montages/no_montage_image.svg";
//----------------Internal Components---------------
import Btn from "../buttons/Btn";
// ------------------------Context--------------------------
import { globalContext } from "../../context/globalContext";
import CreateMontage from "./CreateMontage";

export const NoMontageIconSvg = (props) => {
  return (
    <Icon sx={{ ...props.sx }}>
      <img
        src={NoMontageImg}
        alt="no_montages"
        style={{ maxWidth: "100%" }}
      ></img>
    </Icon>
  );
};
export default function NoMontages(props) {
  const context = useContext(globalContext);
  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        background: "transparent",
        ...props.sx,
      }}
      elevation={0}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          mb: "28px",
        }}
      >
        <NoMontageIconSvg
          sx={{
            width: { xs: "100px", md: "150px", lg: "200px", xl: "100%" },
            height: "100%",
          }}
        />
      </Box>
      <Box sx={{ alignSelf: "center" }}>
        <Btn
          title="Create Montage"
          onClick={() => {
            context.setCreateMontageDialog(true);
          }}
          color="#fff"
        />
      </Box>
      <CreateMontage />
    </Paper>
  );
}
