//3rd party
import { Outlet } from "react-router-dom";

//hocs
import AuthHOC from "../hoc/AuthHOC";

//////////////////MUI Components////////////
import Box from "@mui/material/Box";

function Montage({ dispatch }) {
  // const [loading, setLoading] = useState(true);
  // const [data, setData] = useState([1, 2, 3]); // TODO: Replace this with actual array
  // const [error, setError] = useState(null);

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: " #F5F6FA",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        // px: "56px",
      }}
      className="Montage-Page"
    >
      <Outlet />
    </Box>
  );
}

export default AuthHOC(Montage);
