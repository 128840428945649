// ----------React Imports--------------
import { useState, useEffect, useContext, useCallback, useRef } from "react";

// --------------MUI Imports---------------
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import CheckBox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Stack from "@mui/material/Stack";

////////////////3rd party/////////
import { useNavigate, useParams } from "react-router-dom";
import { formatDistance } from "date-fns";
//hooks
import useAxios from "../../hooks/useAxios";

// -----------------Internal Components-----------------
import Btn from "../../components/buttons/Btn";
import Status from "../../components/status/Status";
import { GetStatusButton } from "./utils";

import ViewMontageConfigDialog from "../../components/dialog_components/ViewMontageConfigDialog";
import FileUploadDialog from "../../components/dialog_components/FileUploadDialog";
import DownloadFormatPopup from "../../components/montages/DownloadFormatPopup";
import ProgressStatus from "../../components/progress_status/ProgressStatus";

import PageLoader from "../../components/loader/PageLoader";
import ComponentLoader from "../../components/loader/ComponentLoader";

//--------------------HOC's-------------------------------
import AuthHOC from "../../hoc/AuthHOC";
import MontageHOC from "../../hoc/MontageHOC";
// Context
import { globalContext } from "../../context/globalContext";
import { notifyContext } from "../../context/notifyContext";
import { socketContext } from "../../context/socketContext";
//////////////Helpers and utils/////////////
import { axiosConfig, notificationsHandler } from "../../utils/helpers";

import { table_headers } from "./utils";
import { httpErrorHandler } from "../../utils/helpers";
import CustomPagination from "../../components/CustomPagination";
import CustomServicesCell from "../../components/custom_service";

//////////////custom col table cell for the table header cells//////////////
const ColTableCell = styled(TableCell)({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "21px",
  /* identical to box height */
  color: "#888888",
  textAlign: "center",
});

//////////////custom table cell fro the table body cells/////////////
const RowTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "21px",
  color: "#0F0F0F",
  textAlign: "center",
}));

///////////call status component//////////////

function CallList(props) {
  const { montageId, montageName } = useParams();
  const [showMontageConfigurations, setShowMontageConfigurations] =
    useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [callList, setCallList] = useState([]);
  const [filesDownload, setFilesDownload] = useState([]);
  const [configData, setConfigData] = useState({});
  const [optionals, setOptionals] = useState([]);
  const [updateCallsList, setUpdateCallsList] = useState(false);
  const [page, setPage] = useState(1);
  const [fetchingCalls, setFetchingCalls] = useState(true);
  const [error, setError] = useState(null);
  const [montageData, setMontageData] = useState(null);
  const [reload, setReload] = useState(false);
  const [loader, setLoader] = useState({
    loading: false,
    message: "Loading",
  });
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const PAGE_SIZE = 10;

  let navigate = useNavigate();
  let globalCtx = useRef();
  //context
  globalCtx.current = useContext(globalContext);
  const notifyCtx = useContext(notifyContext);
  const socketCtx = useContext(socketContext);

  //button loading states
  const [buttonLoading, setButtonLoading] = useState({
    downloading: [],
    processing: [],
    retrying: [],
  });

  //call detail info
  const handleEdfInfo = (event, callId, callName, montageName) => {
    navigate(`/montages/view/${montageName}/${callName}/${callId}`);
  };

  const fetchCalls = useCallback(
    async (paginationCall = false, abortController = null) => {
      if (paginationCall) {
        setFetchingCalls(true);
      } else {
        setLoader((previousState) => {
          return { ...previousState, loading: true };
        });
      }
      try {
        const config = axiosConfig({
          uri: `/montages/${montageId}/`,
          method: "GET",
          params: {
            page: page,
            page_size: PAGE_SIZE, // TODO: CHANGE THIS
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController ? abortController.signal : null,
        });
        if (response.data.config === null) {
          navigate(`/montages`);
          notificationsHandler(
            notifyCtx,
            "warning",
            "Montage not configured. Please configure it first"
          );
        } else {
          setCallList(response.data.calls);
          setConfigData(response.data.config.channels);
          setOptionals(response.data.config.optionals);
          delete response.data.calls;
          delete response.data.config;
          setMontageData(response.data);
        }
      } catch (error) {
        setError(error);
        httpErrorHandler(error, notifyCtx, navigate);
      }
      if (paginationCall) {
        setFetchingCalls(false);
      } else {
        setLoader((previousState) => {
          return { ...previousState, loading: false };
        });
      }
    },
    [montageId, page, navigate, notifyCtx]
  );

  //set page title
  useEffect(() => {
    globalCtx.current.setPageTitle(
      JSON.parse(localStorage.getItem("current_montage"))?.title
        ? JSON.parse(localStorage.getItem("current_montage"))?.title
        : "Montages"
    );
  }, []);

  //fetch calls from api
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      await fetchCalls(true, abortController);
    })();
    return () => {
      abortController.abort();
    };
  }, [page, fetchCalls, reload]);

  //handle ws events
  useEffect(() => {
    (async () => {
      if (
        "type" in socketCtx.message &&
        (socketCtx.message.type === "edf.processing" ||
          socketCtx.message.type === "edf.processing_failed" ||
          socketCtx.message.type === "edf.processing_success" ||
          socketCtx.message.type === "edf.scored" ||
          socketCtx.message.type === "anonymize.fail" ||
          socketCtx.message.type === "anonymize.success")
      ) {
        const socketMessageCall =
          typeof socketCtx.message.data.call === "string"
            ? JSON.parse(socketCtx.message.data.call)
            : socketCtx.message.data.call;

        setCallList((prevCalls) => {
          return {
            ...prevCalls,
            results: prevCalls?.results?.map((call, index) => {
              if (call.id === socketMessageCall.id) {
                return socketMessageCall;
              }
              return call;
            }),
          };
        });

        //enable buttons
        setButtonLoading((prevState) => {
          return {
            ...prevState,
            processing: prevState.processing.filter((call_id, ind) => {
              return call_id !== socketMessageCall.id;
            }),
            retrying: prevState.retrying.filter((call_id, ind) => {
              return call_id !== socketMessageCall.id;
            }),
          };
        });

        if ("credits_remaining" in socketCtx.message.data) {
          globalCtx.current.setSidebarCredits((prev) => {
            return {
              ...prev,
              loading: false,
              available: Number(socketCtx.message.data.credits_remaining),
              used:
                prev?.used +
                Number(socketCtx.message.data?.credits_deducted || 0),
            };
          });
        }
        socketCtx.setMessage({});
      } else if (updateCallsList) {
        fetchCalls(false);
        setUpdateCallsList(false);
      } else if (socketCtx.message.type === "zip_files.success") {
        try {
          const url = socketCtx.message.data.files[0];
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Scored_EDF.zip"); //or any other extension
          document.body.appendChild(link);
          link.click();
          socketCtx.setMessage({});
        } catch (error) {
          httpErrorHandler(error, notifyCtx, navigate);
        }
      }
    })();
  }, [socketCtx.message, updateCallsList]); // eslint-disable-line react-hooks/exhaustive-deps

  //breadcrumbs
  const breadcrumbs = [
    <Link key="1" to="/montages">
      <Typography
        sx={{ color: "#969696", fontSize: "18px", fontWeight: "500" }}
      >
        Montages
      </Typography>
    </Link>,
    <Typography
      key="2"
      sx={{ color: "#969696", fontSize: "18px", fontWeight: "500" }}
    >
      Montages View
    </Typography>,
  ];

  return (
    <>
      <ProgressStatus />
      {loader.loading ? (
        <PageLoader open={loader.loading} message={loader.message} />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "space-between",
            position: "relative",
          }}
        >
          {/* START: Breadcrumb */}
          <Stack direction="row" sx={{ width: "100%", px: "40px" }}>
            <Breadcrumbs
              separator={
                <NavigateNextIcon
                  fontSize="small"
                  style={{ color: "#969696" }}
                />
              }
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
          {/* END: Breadcrumb */}
          <Box sx={{ px: "40px" }}>
            {showMontageConfigurations ? (
              <ViewMontageConfigDialog
                open={showMontageConfigurations}
                configData={configData}
                montageData={montageData}
                optionals={optionals}
                handleClose={() => {
                  setShowMontageConfigurations(false);
                }}
              />
            ) : (
              ""
            )}
            {/* file upload dialog starts*/}
            <FileUploadDialog
              open={showFileUpload ? showFileUpload : false}
              handleClose={() => {
                setShowFileUpload(false);
              }}
              montage={{ name: montageName, id: montageId }}
              // updateCalls={true}
              setCallList={setCallList}
            />
            {/* file upload dialog ends*/}
            <Box
              sx={{ mb: "30px", display: "flex", justifyContent: "flex-end" }}
            >
              <Btn
                type="text"
                title="View Montage Configuiration"
                sx={{ mr: "20px" }}
                color="rgba(47, 126, 199, 1)"
                onClick={() => {
                  setShowMontageConfigurations(!showMontageConfigurations);
                }}
              ></Btn>
              <Btn
                startIcon={<FileUploadOutlinedIcon />}
                color="#fff"
                title="Upload Files"
                onClick={() => {
                  setShowFileUpload(true);
                }}
              />
            </Box>
            {/* TABLE START HERE */}
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", mb: "60px" }}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ width: "50px", p: "0" }}
                    ></TableCell>
                    {table_headers.map((element, index) => {
                      return (
                        <ColTableCell sx={element.styles} key={index}>
                          {element.name}
                        </ColTableCell>
                      );
                    })}
                    <ColTableCell
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "21px",
                        /* identical to box height */
                        color: "#888888",
                      }}
                      align="center"
                    ></ColTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fetchingCalls || error ? (
                    <TableRow sx={{ width: "100%" }}>
                      <RowTableCell colSpan={8}>
                        <ComponentLoader
                          loading={fetchingCalls}
                          error={error}
                          message={""}
                          transparent={true}
                          retry={() => {
                            setReload((prev) => {
                              return !prev;
                            });
                          }}
                        />
                      </RowTableCell>
                    </TableRow>
                  ) : (
                    <>
                      {callList.count ? (
                        callList.results.map((element, index) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{
                                ":hover": {
                                  backgroundColor: "#f9f9f9",
                                  cursor: "pointer",
                                },
                              }}
                              onClick={(event) => {
                                handleEdfInfo(
                                  event,
                                  element.id,
                                  element.file_name,
                                  montageName
                                );
                              }}
                            >
                              <RowTableCell
                                sx={{ height: "88px", p: "0" }}
                                align="left"
                                component="th"
                              >
                                <CheckBox
                                  id={`${element.id}`}
                                  disabled={
                                    element.status === "processed"
                                      ? false
                                      : true
                                  }
                                  indeterminate={
                                    element.status === "processed"
                                      ? false
                                      : true
                                  }
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "#10A44B",
                                    },
                                    ml: "10px",
                                  }}
                                  onChange={(event) => {
                                    setFilesDownload(
                                      event.target.checked
                                        ? [...filesDownload, event.target.id]
                                        : filesDownload.filter(
                                            (fileId, index) => {
                                              return event.target.id !== fileId;
                                            }
                                          )
                                    );
                                  }}
                                />
                              </RowTableCell>
                              <RowTableCell
                                align="left"
                                component="th"
                                sx={{
                                  cursor: "pointer",
                                  textAlign: "left",
                                }}
                                id={element.id}
                              >
                                <span title={element.file_name}>
                                  {element.file_name.length > 15
                                    ? element.file_name.slice(0, 15) + "..."
                                    : element.file_name}
                                </span>
                              </RowTableCell>
                              <RowTableCell
                                sx={{ height: "88px", minWidth: "180px" }}
                                align="left"
                                component="th"
                              >
                                <Status status={element.status} />
                              </RowTableCell>
                              <RowTableCell
                                sx={{ height: "88px" }}
                                align="left"
                                component="th"
                              >
                                {element.credit_used
                                  ? Number(element.credit_used).toFixed(2)
                                  : 0}
                              </RowTableCell>
                              <RowTableCell
                                sx={{ height: "88px" }}
                                align="left"
                                component="th"
                              >
                                <span>
                                  {element?.time_call_ended &&
                                  element?.time_call_started
                                    ? formatDistance(
                                        new Date(element?.time_call_ended),
                                        new Date(element?.time_call_started)
                                      )
                                    : "-"}
                                </span>
                              </RowTableCell>

                              <RowTableCell
                                sx={{ height: "88px" }}
                                align="left"
                                component="th"
                              >
                                <CustomServicesCell
                                  data={element}
                                  sx={{ mx: "auto" }}
                                />
                              </RowTableCell>
                              <TableCell sx={{ width: "200px" }}>
                                <GetStatusButton
                                  notifyCtx={notifyCtx}
                                  globalCtx={globalCtx.current}
                                  callId={element.id}
                                  status={element.status}
                                  setUpdateCallsList={setUpdateCallsList}
                                  buttonLoading={buttonLoading}
                                  setButtonLoading={setButtonLoading}
                                  navigate={navigate}
                                  setCallList={setCallList}
                                  axiosInstance={axiosInstance}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* TABLE ENDS HERE */}
            {/* CUSTOM PAGINATION COMPONENT  */}
            {!callList.previous && !callList.next ? null : (
              <CustomPagination
                disabled={fetchingCalls}
                last_page_no={Math.ceil(callList.count / PAGE_SIZE)}
                limit={callList.results.length}
                handlePaginationChange={(_, value) => setPage(value)}
              />
            )}
            {/* CUSTOM PAGINATION COMPONENT ENDS */}
          </Box>
          {filesDownload.length > 0 ? (
            <Box
              sx={{
                position: "fixed",
                bottom: 0,
                width: { xs: "100%", lg: "calc(100% - 272px)" },
              }}
            >
              <DownloadFormatPopup filesDownload={filesDownload} />
            </Box>
          ) : (
            <></>
          )}
        </Box>
      )}
    </>
  );
}

export default AuthHOC(MontageHOC(CallList));
