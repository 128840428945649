//////////////////////MUI////////////
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
//////////////Assets///////////
import coins from "../../../assets/credits/Coins.svg";
///////Internal components/////////
import CustomPagination from "../../CustomPagination";
import TableComponent from "../../table";
//utils
import { usageTableCols } from "./utils";

// START: USAGE COMPONENT
const YourUsage = (props) => {
  return (
    <Stack
      sx={{ width: "100%", mb: "2rem" }}
      direction="column"
      alignItems="center"
      spacing={1}
    >
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        alignItems="center"
        spacing={1}
      >
        {/* START: CREDIT'S CARD */}
        <Paper
          elevation={0}
          sx={{
            padding: "20px",
            paddingRight: "76px",
            // width: "100%",
            // mx: "36px",
            my: "18px",
            borderRadius: "8px",
          }}
        >
          <Stack
            sx={{ height: "100%" }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#8A8A8A",
              }}
            >
              CREDITS USED BY YOU
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={1}
              sx={{ width: "100%" }}
            >
              <img src={coins} alt="credits_icon" />
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "700",
                  color: "#4A4A4A",
                }}
              >
                {props.totalUsed ? props.totalUsed.used : 0}
              </Typography>
            </Stack>
          </Stack>
        </Paper>
        {/* END: CREDIT'S CARD*/}
      </Stack>
      {/* START: TABLE */}
      <TableComponent
        cols={usageTableCols}
        rows={props.usageData}
        loading={props?.loading}
      />
      {/* END: TABLE */}
      {/* START: PAGINATION */}
      {!props.usageData.previous && !props.usageData.next ? null : (
        <CustomPagination
          disabled={props.loading}
          last_page_no={Math.ceil(props.usageData.count / props.PAGE_SIZE)}
          limit={props.usageData.results.length}
          handlePaginationChange={(_, value) => props.setPage(value)}
        />
      )}
      {/* END: PAGINATION */}
    </Stack>
  );
};

// END: USAGE COMPONENT

export default YourUsage;

YourUsage.defaultProps = {
  usageData: [],
};
