////////////////Mui/////////////
import { Paper, Box, Typography, Stack, Grid } from "@mui/material";
import { styled } from "@mui/system";
import Icon from "@mui/material/Icon";

///////////Assets////////////////
import FinishedIcon from "../../assets/edf_status/finished.svg";
import ProcessingIcon from "../../assets/edf_status/processing.svg";
import ErrorIcon from "../../assets/edf_status/error.svg";
import NotStartedIcon from "../../assets/edf_status/notstarted.svg";

///////////Custom styled components////////////
const TypographyStatus = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#2CA9E3",
});
const TypographyCount = styled(Typography)({
  display: "block",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "25px",
  color: "#2CA9E3",
});

///////////////SVG Icons/////////////////////
const ProcessingIconSvg = (props) => {
  return (
    <Icon sx={{ ...props.sx, width: "100%", height: "100%" }}>
      <img
        src={ProcessingIcon}
        style={{ maxWidth: "100%" }}
        alt="download_icon"
      ></img>
    </Icon>
  );
};
const FinishedIconSvg = (props) => {
  return (
    <Icon sx={{ ...props.sx, width: "100%", height: "100%" }}>
      <img
        src={FinishedIcon}
        style={{ maxWidth: "100%" }}
        alt="download_icon"
      ></img>
    </Icon>
  );
};
const NotStartedIconSvg = (props) => {
  return (
    <Icon sx={{ ...props.sx, width: "100%", height: "100%" }}>
      <img
        src={NotStartedIcon}
        style={{ maxWidth: "100%" }}
        alt="download_icon"
      ></img>
    </Icon>
  );
};
const ErrorIconSvg = (props) => {
  return (
    <Icon sx={{ ...props.sx, width: "100%", height: "100%" }}>
      <img
        src={ErrorIcon}
        style={{ maxWidth: "100%" }}
        alt="download_icon"
      ></img>
    </Icon>
  );
};

/////////Main code//////////////
export default function EdfStatus(props) {
  return (
    <Paper sx={{ p: "20px", height: "100%" }} elevation={0}>
      <Stack>
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "23px",
            color: "#0F0F0F",
            display: "block",

            fontStyle: "normal",
            fontWeight: "500",
            mb: "30px",
          }}
        >
          EDF's STATUS
        </Typography>
        <Grid container rowSpacing={4} columnSpacing={2}>
          <Grid item xs={6} sx={{ wordBreak: "break-all" }}>
            <Stack direction="row">
              <Box sx={{ mr: "8px", width: "40px" }}>
                <ProcessingIconSvg />
              </Box>
              <Box>
                <TypographyStatus component="span">Processing</TypographyStatus>
                <TypographyCount component="span" sx={{}}>
                  {Object.keys(props.edfStatusData).length
                    ? props.edfStatusData.calls.processing
                    : ""}
                </TypographyCount>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={6} sx={{ wordBreak: "break-all" }}>
            <Stack direction="row">
              <Box sx={{ mr: "8px", width: "40px" }}>
                <FinishedIconSvg />
              </Box>
              <Box>
                <TypographyStatus component="span" sx={{ color: " #10A44B" }}>
                  Completed
                </TypographyStatus>
                <TypographyCount component="span" sx={{ color: " #10A44B" }}>
                  {Object.keys(props.edfStatusData).length
                    ? props.edfStatusData.calls.processed
                    : ""}
                </TypographyCount>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={6} sx={{ wordBreak: "break-all" }}>
            <Stack direction="row">
              <Box sx={{ mr: "8px", width: "40px" }}>
                <NotStartedIconSvg />
              </Box>
              <Box>
                <TypographyStatus component="span" sx={{ color: " #959595" }}>
                  Not Started
                </TypographyStatus>
                <TypographyCount component="span" sx={{ color: " #959595" }}>
                  {Object.keys(props.edfStatusData).length
                    ? props.edfStatusData.calls.not_started
                    : ""}
                </TypographyCount>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={6} sx={{ wordBreak: "break-all" }}>
            <Stack direction="row">
              <Box sx={{ mr: "8px", width: "40px" }}>
                <ErrorIconSvg />
              </Box>
              <Box>
                <TypographyStatus component="span" sx={{ color: " #E03A3A" }}>
                  Error
                </TypographyStatus>
                <TypographyCount component="span" sx={{ color: " #E03A3A" }}>
                  {Object.keys(props.edfStatusData).length
                    ? props.edfStatusData.calls.error
                    : ""}
                </TypographyCount>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
}
EdfStatus.defaultProps = {
  edfStatusData: {},
};
