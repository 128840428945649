//react
import { useEffect, useState, useContext } from "react";

//3rd party
import { useParams } from "react-router-dom";
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
//internal
import EmailVerified from "./emailVerified";
//context
import { notifyContext } from "../context/notifyContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function VerifyEmail(props) {
  const { token } = useParams();
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState(null);
  const styles = {
    display: "flex",
    flexFlow: "column",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  };
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false);

  const notifyCtx = useContext(notifyContext);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const config = axiosConfig({
          uri: `/auth/registration/manual/verify-email/`,
          method: "POST",
          data: { key: token },
        });
        await axios({
          ...config,
        });
        setVerifying(false);
        setIsEmailConfirmed(true);
      } catch (error) {
        setError(error);
        setVerifying(false);
        httpErrorHandler(error, notifyCtx, navigate);
      }
    })();
  }, [token, navigate, notifyCtx]);
  const [counter, setCounter] = useState(10);
  useEffect(() => {
    let intervalId;
    let timeoutId;
    if (isEmailConfirmed) {
      intervalId = setInterval(() => {
        setCounter((value) => {
          if (value !== 0) return value - 1;
          return 0;
        });
      }, 1000);
      timeoutId = setTimeout(() => {
        navigate("/accounts/signin");
      }, 10000);
    }
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, [navigate, isEmailConfirmed]);

  return (
    <div style={styles}>
      <EmailVerified verifying={verifying} error={error} counter={counter} />
    </div>
  );
}
