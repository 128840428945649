//////////////MUI//////////////
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
///////////////assets///////////////
import coins from "../../assets/credits/Coins.svg";

export default function DashboardCredits(props) {
  return (
    <Paper
      elevation={0}
      sx={{
        px: "18px",
        py: "20px",

        width: { xs: "100%", sm: "100%", md: "initial" },
        // mx: "56px",
        // my: "48px",
        borderRadius: "8px",
        ...props.sx,
      }}
    >
      <Stack
        sx={{ height: "100%", width: "100%" }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress
            variant="determinate"
            sx={{
              color: props.progressColor,
              boxShadow: `inset 0 0 0px 7px ${props.shadow}`,
              borderRadius: "100%",
            }}
            size={75}
            value={Number(props.percentage) ? Number(props.percentage) : 0}
          />
          <Typography
            position="absolute"
            fontSize="15px"
            fontWeight="700"
            color={props.progressColor}
            component="span"
          >
            {Number(props.percentage) ? Number(props.percentage).toFixed(1) : 0}
            %
          </Typography>
        </Box>
        <Stack
          sx={{ height: "100%" }}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{ fontSize: "14px", fontWeight: "500", color: "#8A8A8A" }}
            component="span"
          >
            {props.text.toUpperCase()}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
            sx={{ width: "100%" }}
          >
            <img src={coins} alt="credits_icon" />
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                color: "#4A4A4A",
              }}
              component="span"
            >
              {props.credits ? props.credits : 0}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}

DashboardCredits.defaultProps = {
  percentage: 75,
  text: "TOTAL CREDITS percentage",
  credits: 4200,
  progressColor: "primary",
  progressText: "primary",
  shadow: "rgba(31, 117, 197, 0.2)",
};
