////////////MUI//////////////////
import { Stack, Typography, Paper } from "@mui/material";

export default function SupervisorInfo(props) {
  const getDate = (datetimestring) => {
    if (datetimestring == null) {
      return "-";
    }
    const options = { day: "numeric", month: "short", year: "numeric" };
    const date_ = new Intl.DateTimeFormat("en-GB", options).format(
      new Date(datetimestring)
    );
    return date_;
  };
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          padding: "32px",
        }}
      >
        <Stack
          direction="column"
          spacing={1}
          sx={{ pb: "8px", borderBottom: " 1px solid #EAEAEA" }}
        >
          <Typography fontSize="20px" fontWeight="500" color="#0F0F0F">
            Other info
          </Typography>
          <Typography fontSize="14px" fontWeight="500" color="#898989">
            View supervisor and organisation details here{" "}
          </Typography>
        </Stack>

        <Stack direction="column" spacing={2} sx={{ my: "38px" }}>
          <Stack direction="row" alignItems="center" spacing={7}>
            <Typography fontSize="16px" fontWeight="400" color="#898989">
              Supervisor Name
            </Typography>
            <Typography fontSize="16px" fontWeight="400" color="#0F0F0F">
              {props.supervisorData &&
              Object.keys(props.supervisorData).length > 0
                ? props.supervisorData.user.first_name.concat(
                    " ",
                    props.supervisorData.user.last_name
                  )
                : ""}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={7}>
            <Typography fontSize="16px" fontWeight="400" color="#898989">
              Supervisor Email
            </Typography>

            <Typography fontSize="16px" fontWeight="400" color="#0F0F0F">
              {props.supervisorData &&
              Object.keys(props.supervisorData).length > 0
                ? props.supervisorData.user.email
                : ""}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={5}>
            <Typography fontSize="16px" fontWeight="400" color="#898989">
              Organisation Name
            </Typography>

            <Typography fontSize="16px" fontWeight="400" color="#0F0F0F">
              {props.supervisorData &&
              Object.keys(props.supervisorData).length > 0
                ? props.supervisorData.profile.organization
                  ? props.supervisorData.profile.organization
                  : "-"
                : ""}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            //   justifyContent="space-between"
            alignItems="center"
            spacing={12}
          >
            <Typography fontSize="16px" fontWeight="400" color="#898989">
              Created On
            </Typography>

            <Typography fontSize="16px" fontWeight="400" color="#0F0F0F">
              {props.supervisorData &&
              Object.keys(props.supervisorData).length > 0
                ? getDate(props.supervisorData.user.date_joined)
                : ""}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
}

SupervisorInfo.defaultProps = {
  supervisorData: [],
};
