//////////////react ///////////////
import { memo, useState } from "react";
///////////////////MUI////////////////

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// -----------------Internal Components-----------------
import Btn from "../buttons/Btn";
import SingleFileDownloadPopup from "./SingleFileDownloadPopup";

function DownloadFormatPopup(props) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };
  const handleClose = (event) => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          handleClose();
        }}
      >
        <Box>
          <SingleFileDownloadPopup
            open={open}
            setOpen={setOpen}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            location="top"
            disableClickAway={true}
            downloadCalls={props.downloadCalls}
            filesDownload={props.filesDownload}
          />
          <Paper
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "70px",
              px: "46px",
              position: "sticky",
              bottom: 0,
              zIndex: 1000,
            }}
            elevation={2}
          >
            <Btn
              onClick={handleClose}
              color="#000"
              type="text"
              title="Cancel"
              sx={{ mr: 2, p: 0 }}
            />
            <Btn
              onClick={handleClick}
              type="outlined"
              title={`Download (${props.filesDownload.length})`}
              startIcon={<FileDownloadOutlinedIcon />}
              sx={{
                color: "#10A44B",
                border: "1px solid #10A44B",

                ":hover": {
                  color: "#fff",
                  backgroundColor: "#10A44B",
                  border: "1px solid #10A44B",
                },
              }}
            />
          </Paper>
        </Box>
      </ClickAwayListener>
    </>
  );
}

export default memo(DownloadFormatPopup);
