// ---------- React Imports----------------
import { useContext } from "react";
// ----------MUI Imports ----------------------
import Paper from "@mui/material/Paper";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
// -----------Global Context------------------
import { globalContext } from "../../context/globalContext";

export default function ProgressStatus(props) {
  const context = useContext(globalContext);
  // const handleClose = () => {
  //   context.setOpenProgressStatus(false);
  // };
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: props.componentLevel ? 2 : (theme) => theme.zIndex.drawer + 1,
        position: props.componentLevel ? "absolute" : "fixed",
      }}
      open={context.openProgressStatus}
      // onClick={handleClose}
    >
      <Paper
        sx={{
          width: { xs: "280px", sm: "340px", md: "440px" },
          height: { xs: "150px", sm: "190px", md: "250px" },
          display: "flex",
          flexFlow: "nowrap column",
          justifyContent: "center",
          alignItems: "center",
          p: "20px",
        }}
        elevation={0}
      >
        <Typography
          component="span"
          sx={{
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "26px",
            /* identical to box height */
            textAlign: "center",
            color: "#0F0F0F",
            mb: "16px",
          }}
        >
          {context.progressStatusMessage}
        </Typography>
        <CircularProgress
          sx={{
            "& .MuiCircularProgress-svg": {
              //   color:
              //     "linear-gradient(45deg,rgba(44, 169, 227, 1),rgba(255, 255, 255, 0))",
              color: "rgba(44, 169, 227, 1)",
            },
          }}
        />
      </Paper>
    </Backdrop>
  );
}
