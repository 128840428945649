////////////////React//////////////
import { useEffect, useState, useContext, useRef, useCallback } from "react";
///////////////MUI///////////////
import { Box, Tab, Tabs } from "@mui/material";

///////////////Internal Components/////////////

import YourUsage from "../../components/credits/YourUsage";
import PageLoader from "../../components/loader/PageLoader";
import TransactionActivity from "../../components/credits/TransactionActivity";
///////////////HOc////////////
import AuthHOC from "../../hoc/AuthHOC";
//////////////Helpers and utils/////////////
import { axiosConfig } from "../../utils/helpers";
import { httpErrorHandler } from "../../utils/helpers";
///////////////Context////////////
import { notifyContext } from "../../context/notifyContext";
import { useNavigate } from "react-router-dom";

//hooks
import useAxios from "../../hooks/useAxios";
import { globalContext } from "../../context/globalContext";
/////////Tab component///////////
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Usage({ ...props }) {
  const { setPageTitle } = useContext(globalContext);
  // states
  const [loader, setLoader] = useState({
    loading: false,
    message: "Loading",
  });
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const PAGE_SIZE = 10;
  const [usageData, setUsageData] = useState([]);
  const [totalUsed, setTotalUsed] = useState(0);
  const [page, setPage] = useState(1);
  const [fetchingCalls, setFetchingCalls] = useState(true);

  const navigate = useNavigate();
  const notifyCtx = useContext(notifyContext);
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  useEffect(() => {
    setPageTitle("Your Usage");
  }, [setPageTitle]);

  //call to fetch your usage data
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      let httpError;
      setLoader((previousState) => {
        return { ...previousState, loading: true };
      });
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/summary`,
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setTotalUsed(response.data);
      } catch (error) {
        httpError = error;
        httpErrorHandler(error, notifyCtx, navigate);
      }
      if (httpError && !httpError.message === "canceled")
        setLoader((previousState) => {
          return { ...previousState, loading: false };
        });
      else {
        setLoader((previousState) => {
          return { ...previousState, loading: false };
        });
      }
    })();
    return () => {
      abortController.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchCalls = useCallback(
    async (abortController) => {
      try {
        const config = axiosConfig({
          method: "GET",
          uri: `/calls/`,
          params: {
            page: page,
            page_size: PAGE_SIZE,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setUsageData(response.data);
      } catch (error) {
        httpErrorHandler(error, notifyCtx, navigate);
        return error;
      }
    },
    [page, navigate] // eslint-disable-line react-hooks/exhaustive-deps
  );
  //for fetching calls
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setFetchingCalls(true);
      const error = await fetchCalls(abortController);

      if (error && !error.message === "canceled") {
        setFetchingCalls(false);
      } else {
        setFetchingCalls(false);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [page, fetchCalls]);

  return (
    <>
      {loader.loading ? (
        <PageLoader
          open={loader.message || fetchingCalls}
          message={loader.message}
        />
      ) : (
        <Box
          sx={{
            width: "100%",
            backgroundColor: " #F5F6FA",
            height: "100%",
            px: "40px",
            mb: "1rem",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "#C4C4C4" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab
                className="usage-tabs"
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  textTransform: "none",
                  mr: "40px",
                  px: 0,
                  py: 0,
                }}
                label="Your Credits Usage"
                {...a11yProps(1)}
              />
              <Tab
                className="usage-tabs"
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  textTransform: "none",
                  mr: "40px",
                  px: 0,
                  py: 0,
                }}
                label="Transaction Activity"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <YourUsage
              usageData={usageData}
              totalUsed={totalUsed}
              page={page}
              setPage={setPage}
              loading={fetchingCalls}
              PAGE_SIZE={PAGE_SIZE}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TransactionActivity />
          </TabPanel>
        </Box>
      )}
    </>
  );
}

export default AuthHOC(Usage);
