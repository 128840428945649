//////////////React imports///////////

import { useEffect, useState, useContext, useRef } from "react";
//////////////////MUI Components////////////
import {
  Box,
  Stepper,
  Step,
  StepButton,
  Paper,
  StepConnector,
} from "@mui/material";

/////////////////Internal Components/////////////
import StepperControl from "./StepperControl";
///////Utils and helper//////

///////////////HOc//////////////
import CreateMontageHOC from "../../hoc/CreateMontageHOC";
////context///////
import { globalContext } from "../../context/globalContext";
import { notifyContext } from "../../context/notifyContext";
//////////3rd Party//////////////////////
import { useNavigate, Outlet, useParams } from "react-router-dom";

//////////Utils and helper////////////////
import urls from "../../utils/urls.json";
import {
  createFinalConfigJson,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";
import { axiosConfig, setStepperActiveStep } from "../../utils/helpers";
//hooks
import useAxios from "../../hooks/useAxios";
const sendChannelsConfigData = async (
  ctx,
  navigate,
  data,
  montage_name,
  montage_id,
  notifyCtx,
  axiosInstance
) => {
  try {
    ctx.setOpenProgressStatus(true);
    ctx.setProgressStatusMessage("Saving your montage configuration");
    const config = axiosConfig({
      uri: `/montages/config/?montage_id=${montage_id}
    `,
      method: "PATCH",
      data: data,
    });

    const response = await axiosInstance.current({
      ...config,
    });
    if (response.status === 200) {
      ctx.setOpenProgressStatus(false);

      notificationsHandler(
        notifyCtx,
        "success",
        "Montage Configured Successfully"
      );
      navigate(`${urls.montage_view}${montage_name}/${montage_id}`);
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        notificationsHandler(notifyCtx, "error", error.response.data.detail);
      }
    }
    httpErrorHandler(error, notifyCtx, navigate);
    ctx.setOpenProgressStatus(false);
  }
};

function StepperComponent(props) {
  //context
  const ctx = useContext(globalContext);

  const { montageName } = useParams();
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const notifyCtx = useContext(notifyContext);
  const [activeStep, setActiveStep] = useState(() => {
    const acs = localStorage.getItem("acs");

    if (acs) {
      return Number(acs);
    }
    return 0;
  });
  const [disableNext, setDisableNext] = useState(false);
  const [completed, setCompleted] = useState({});
  const steps = ["Upload Edf's", "Map Channels", "Save & Preview"];
  const navigate = useNavigate();

  const totalSteps = () => {
    return steps.length;
  };

  // const completedSteps = () => {
  //   return Object.keys(completed).length;
  // };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  // const allStepsCompleted = () => {
  //   return completedSteps() === totalSteps();
  // };

  // will check active step and redirect to the right page
  useEffect(() => {
    if (activeStep === 0) {
      navigate(`/montages/${encodeURIComponent(montageName)}`);
    } else if (activeStep === 1) {
      navigate(`/montages/${encodeURIComponent(montageName)}/config`);
    } else if (activeStep === 2) {
      navigate(`/montages/${encodeURIComponent(montageName)}/preview`);
    }
  }, [activeStep]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleNext = () => {
    if (activeStep + 1 === 0) {
      navigate(`/montages/${encodeURIComponent(montageName)}`);
    } else if (activeStep + 1 === 1) {
      //setting is user consent open to false to enable not going to file upload explicitly until consent open set this to true and redirect to upload file page
      ctx.setIsUserConsentOpen(false);

      navigate(`/montages/${encodeURIComponent(montageName)}/config`);
    } else if (activeStep + 1 === 2) {
      navigate(`/montages/${encodeURIComponent(montageName)}/preview`);
    }
    if (activeStep < 3) {
      setActiveStep((previous) => {
        setStepperActiveStep(activeStep + 1, setActiveStep);
        return activeStep + 1;
      });
    }
  };

  const handleBack = () => {
    if (activeStep - 1 === 0) {
      navigate(`/montages/${montageName}`);
    } else if (activeStep - 1 === 1) {
      navigate(`/montages/${montageName}/config`);
    } else if (activeStep - 1 === 2) {
      navigate(`/montages/${montageName}/preview`);
    }
    setActiveStep((previous) => {
      setStepperActiveStep(activeStep - 1, setActiveStep, true);
      return activeStep - 1;
    });
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const handleDone = async () => {
    let montage = {};
    if (localStorage.getItem("current_montage")) {
      montage = JSON.parse(localStorage.getItem("current_montage"));

      let finalConfigData = {};
      const configData = localStorage.getItem("montages_configurations");
      if (configData && configData !== "undefined") {
        const parsed_data = JSON.parse(
          localStorage.getItem("montages_configurations")
        );
        finalConfigData = createFinalConfigJson(parsed_data);
      }

      if (Object.keys(finalConfigData.channels).length) {
        await sendChannelsConfigData(
          ctx,
          navigate,
          finalConfigData,
          montage.title,
          montage.id,
          notifyCtx,
          axiosInstance
        );
      } else {
        notifyCtx.setNotify({
          open: true,
          severity: "error",
          title: "",
          message: `Atleast one channel should be configured`,
          action: () => {
            console.log("Action done on notification");
          },
        });
      }
    } else {
      notifyCtx.setNotify({
        open: true,
        severity: "error",
        title: "",
        message: `Montage Info not found. Try restarting the process`,
        action: () => {
          console.log("Action done on notification");
        },
      });
    }
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box
      sx={{
        // position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "100%",
        width: "100%",
      }}
      className="Stepper-Component"
    >
      <Paper
        sx={{
          borderRadius: "8px",

          mb: "26px",
          mx: "36px",
        }}
        elevation={0}
      >
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<StepConnector sx={{ mt: "28px", height: "20px" }} />}
          sx={{
            "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
              borderTop: "3px solid #10A44B",
              backgroundColor: "transparent",
            },
            height: "120px",
          }}
        >
          {steps.map((label, index) => (
            <Step
              key={label}
              completed={completed[index]}
              sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: "#10A44B", // circle color (COMPLETED)
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "#10A44B", // Just text label (COMPLETED)
                  },
                "& .MuiStepLabel-root .MuiSvgIcon-root.Mui-active": {
                  color: " #fff",
                  border: "2px solid #10A44B",
                  borderRadius: "50%",
                },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                  {
                    color: "#10A44B", // Just text label (ACTIVE)
                  },
                "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                  fill: "#10A44B", // circle's number (ACTIVE)
                },

                "& .Mui-disabled .MuiStepIcon-root": {
                  color: "#fff",
                  border: "2px solid #C4C4C4",
                  borderRadius: "50%",
                },
                "& .Mui-disabled .MuiStepIcon-text": {
                  fill: "#959595",
                },
                "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel":
                  {
                    color: "#8A8A8A", // Just text label (DISABLED)
                  },

                height: "100%",
              }}
            >
              <StepButton
                // color="inherit"
                onClick={handleStep(index)}
                sx={{
                  "& .MuiStepLabel-root": {
                    py: "35px",
                    "& .MuiSvgIcon-root": {
                      width: "40px",
                      height: "40px",
                      // color: "#10A44B",
                    },
                  },

                  height: "100%",
                  p: 0,
                  m: 0,
                }}
              >
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Paper>
      <Box sx={{ mx: "36px", height: "calc(100% - 250px)" }}>
        <Outlet
          context={{ activeStep, setActiveStep, disableNext, setDisableNext }}
        />
        {/* {activeStep === 0 ? (
          <FileUpload />
        ) : activeStep === 1 ? (
          <MapChannels />
        ) : (
          <PreviewChannels stepperHandleBack={handleBack} />
        )} */}
      </Box>

      <Paper
        elevation={0}
        sx={{
          mt: "30px",
          position: "fixed",
          bottom: "0",
          width: { xs: "100%", lg: "calc(100vw - 292px)" },
          px: "36px",
          py: "20px",
        }}
      >
        <StepperControl
          handleBack={handleBack}
          handleNext={handleNext}
          handleReset={handleReset}
          handleStep={handleStep}
          isLastStep={isLastStep}
          handleComplete={handleComplete}
          activeStep={activeStep}
          handleDone={handleDone}
          disableNext={disableNext}
          setDisableNext={setDisableNext}
        />
      </Paper>
    </Box>
  );
}
// export default CreateMontageHOC(StepperComponent);
export default CreateMontageHOC(StepperComponent);
