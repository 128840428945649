//react
import { useState, useContext, useEffect, useCallback, useRef } from "react";
//context
import { notifyContext } from "../context/notifyContext";
//utils and helpers
import { axiosConfig, httpErrorHandler } from "../utils/helpers";

//3rd party
import { Navigate, useParams } from "react-router-dom";
//hooks
import useAxios from "../hooks/useAxios";

//internal
import PageLoader from "../components/loader/PageLoader";

export default function MontageHOC(WrappedComponent) {
  return function Component(props) {
    const axiosInstance = useRef();
    axiosInstance.current = useAxios();
    const { montageId } = useParams();

    const getMontageDetail = useCallback(
      async (montageId, notifyCtx, navigate) => {
        try {
          const config = axiosConfig({
            uri: `/montages/${montageId}`,
            method: "GET",
          });
          const response = await axiosInstance.current({
            ...config,
          });
          return response;
        } catch (error) {
          httpErrorHandler(error, notifyCtx, navigate);
        }
      },
      []
    );

    const [montageDataResponse, setMontageDataResponse] = useState([]);

    const [loader, setLoader] = useState({
      loading: true,
      message: "Loading",
    });

    let notifyCtx = useRef();
    notifyCtx.current = useContext(notifyContext);

    useEffect(() => {
      (async () => {
        const response = await getMontageDetail(montageId, notifyCtx.current);
        setMontageDataResponse(response);

        localStorage.setItem("current_montage", JSON.stringify(response.data));
        setLoader((previousState) => {
          return { ...previousState, loading: false };
        });
      })();
    }, [getMontageDetail, montageId]);

    const conditionalComponentRederer = () => {
      // montage contains calls
      if (montageDataResponse.data.calls.count > 0) {
        localStorage.setItem("acs", 1);
        //montage creation status (True when montage is being created from scratch)
        localStorage.setItem("mc_status", false);
        // montage not configured
        if (montageDataResponse.data.config === null) {
          return (
            <Navigate
              to={`/montages/${encodeURIComponent(
                montageDataResponse.data.title
              )}/config`}
              replace={true}
            />
          );
        }
        // montage is configured
        else {
          localStorage.setItem(
            "current_montage",
            JSON.stringify(montageDataResponse.data)
          );
          return <WrappedComponent />;
        }
      } else {
        // not contains calls
        localStorage.setItem("acs", 0);
        return (
          <Navigate
            to={`/montages/${encodeURIComponent(
              montageDataResponse.data.title
            )}`}
            replace={true}
          />
        );
      }
    };

    return (
      <>
        {loader.loading ? (
          <PageLoader open={loader.loading} message={loader.message} />
        ) : (
          conditionalComponentRederer()
        )}
      </>
    );
  };
}
