// react
import { useState, useContext } from "react";

import { Navigate, useParams } from "react-router";
//internal
import ResetPasswordForm from "../components/forms/resetPasswordForm";

//hooks
import useAuth from "../hooks/useAuth";

//helpers
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
//context
import { notifyContext } from "../context/notifyContext";
//lib
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PasswordResetSuccessfull from "./resetSuccessfull";

function ResetPassword() {
  const [auth] = useAuth();
  const { id, token } = useParams();
  const [loading, setLoading] = useState(false);
  const [isResetDone, setIsResetDone] = useState(false);
  //ctx
  const notifyCtx = useContext(notifyContext);

  const navigate = useNavigate();

  const handleSubmit = async (new_password1, new_password2) => {
    setLoading(true);
    try {
      const config = axiosConfig({
        method: "POST",
        uri: `/auth/password/reset/confirm/`,
        data: { uid: id, token, new_password1, new_password2 },
      });
      await axios({
        ...config,
      });
      notificationsHandler(
        notifyCtx,
        "success",
        "Password reset done successfully"
      );
      setLoading(false);
      setIsResetDone(true);
    } catch (error) {
      if (error && error !== "canceled") setLoading(false);
      httpErrorHandler(error, notifyCtx, navigate);
    }
    setLoading(false);
  };

  if (auth.authenticated) {
    return <Navigate to={urlpatterns.dashboard} />;
  }

  return (
    <>
      {isResetDone ? (
        <PasswordResetSuccessfull />
      ) : (
        <ResetPasswordForm loading={loading} handleSubmit={handleSubmit} />
      )}
    </>
  );
}

export default ResetPassword;
