//////React Imports////////
import { useState, useEffect, useContext, useCallback, useRef } from "react";
////////////Mui Imports//////////
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

///////////assets///////////
import FileUploadIcon from "../../assets/montage/file_upload.svg";

//////////styles//////////
import * as styles from "./stepper_styles";
////////////////3rd party////////////
import { useOutletContext } from "react-router-dom";
//////////////helpers and utils////////
import {
  axiosConfig,
  setStepperActiveStep,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";
////////////Internal/////////
import AfterFileDrop from "./AfterFileDrop";
//3rd party
import { useNavigate } from "react-router-dom";

//context
import { globalContext } from "../../context/globalContext";
import { notifyContext } from "../../context/notifyContext";
//hooks
import useAxios from "../../hooks/useAxios";

function FileUpload(props) {
  const [dropAreaColor, setDropAreaColor] = useState("#F8F8F8");
  const [selectedFilesList, setSelectedFilesList] = useState([]);
  const [filesProgress, setFilesProgress] = useState([]);
  const { setActiveStep, setDisableNext } = useOutletContext();
  const [filesDropped, setFilesDropped] = useState(false);
  const ctx = useContext(globalContext);
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const notifyCtx = useContext(notifyContext);
  const navigate = useNavigate();

  useEffect(() => {
    //setting active step
    setStepperActiveStep(0, setActiveStep, true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //fetching calls of a montage
  useEffect(() => {
    const current_montage = JSON.parse(localStorage.getItem("current_montage"));
    ctx.setPageTitle(
      current_montage?.title ? current_montage?.title : "Montage"
    );
    setDisableNext(true);
    const getMontageDetail = async (ctx, montage) => {
      ctx.setOpenProgressStatus(true);
      ctx.setProgressStatusMessage(
        "Getting the calls information of the montage"
      );
      try {
        //setting the current montage which is clicked
        localStorage.setItem("current_montage", JSON.stringify(montage));

        const config = axiosConfig({
          uri: `/montages/${montage.id}`,
          method: "GET",
        });
        const response = await axiosInstance.current({
          ...config,
        });

        if (response.status === 200) {
          if (response.data.calls.count > 0 && !ctx.isUserConsentOpen) {
            setDisableNext(false);
            setStepperActiveStep(1, setActiveStep, true);
            //montage contains files
            localStorage.setItem("mc_status", false);
            notificationsHandler(
              notifyCtx,
              "warning",
              "Can't access this page directly if montage already contains files."
            );
            navigate(
              `/montages/${encodeURIComponent(response.data.title)}/config`
            );
          } else {
            setStepperActiveStep(0, setActiveStep, true);
            navigate(`/montages/${encodeURIComponent(response.data.title)}`);
          }
        }
      } catch (error) {
        httpErrorHandler(error, notifyCtx, navigate);
      }
      ctx.setOpenProgressStatus(false);
    };
    if (current_montage && current_montage !== "undefined") {
      getMontageDetail(ctx, current_montage);
    } else {
      notificationsHandler(notifyCtx, "error", "Montage Info Not Found");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dragOver = (e) => {
    e.preventDefault();
    // console.log("dragover ev triggered");
  };

  const dragEnter = (e) => {
    e.preventDefault();
    // console.log("drag enter triggered");
    setDropAreaColor("#fffffff");
  };

  const dragLeave = (e) => {
    e.preventDefault();
    // console.log("drag leave triggered");
    setDropAreaColor("#f8f8f8");
  };

  const fileDrop = useCallback(
    (e) => {
      e.preventDefault();
      const selectedFiles = e.dataTransfer.files;
      const maxFileCount = selectedFiles.length;
      let isValidFileFound = false;
      for (let file = 0; file < maxFileCount; file++) {
        if (selectedFiles[file].name.split(".")[1].toLowerCase() === "edf") {
          if (!filesDropped) {
            setFilesDropped(true);
          }

          setSelectedFilesList((previousFiles) => {
            return [
              ...previousFiles,
              {
                file: selectedFiles[file],
                fileIndex: previousFiles.length,
                progress: 0,
                uploaded: false,
              },
            ];
          });
          setFilesProgress((prev) => {
            return [
              ...prev,
              {
                file: selectedFiles[file],
                fileIndex: prev.length,
                progress: 0,
              },
            ];
          });
          isValidFileFound = true;
        }
      }

      if (!isValidFileFound)
        notificationsHandler(
          notifyCtx,
          "warning",
          "Please Select Edf Files Only"
        );
    },
    [notifyCtx, filesDropped]
  );

  const getFile = useCallback(
    (event) => {
      const selectedFiles = event.target.files;
      const maxFileCount = selectedFiles.length;
      let isValidFileFound = false;
      for (let file = 0; file < maxFileCount; file++) {
        if (selectedFiles[file].name.split(".")[1].toLowerCase() === "edf") {
          if (!filesDropped) {
            setFilesDropped(true);
          }
          setSelectedFilesList((previousFiles) => {
            return [
              ...previousFiles,
              {
                file: selectedFiles[file],
                fileIndex: previousFiles.length,
                progress: 0,
                uploaded: false,
              },
            ];
          });
          setFilesProgress((prev) => {
            return [
              ...prev,
              {
                file: selectedFiles[file],
                fileIndex: prev.length,
                progress: 0,
              },
            ];
          });
          isValidFileFound = true;
        }
      }

      if (!isValidFileFound)
        notificationsHandler(
          notifyCtx,
          "warning",
          "Please Select Edf Files Only"
        );
      // console.log(event.target.value);
      // event.target.value = "";
    },
    [notifyCtx, filesDropped]
  );

  return (
    <Paper
      sx={{ height: "100%", minHeight: "300px", mb: "7rem" }}
      elevation={0}
      className="FileUpload-Component"
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
    >
      {!filesDropped ? (
        <Paper
          sx={{
            height: { xs: "100%" },
            p: "32px",
          }}
          elevation={0}
        >
          <Paper
            sx={{
              background: `${dropAreaColor}`,
              width: "100%",
              height: "100%",
              border:
                dropAreaColor === "#ffffff" ? "none" : "1.5px dashed black",
            }}
            elevation={0}
          >
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <img
                src={FileUploadIcon}
                alt=""
                style={{
                  width: "100px",
                  height: "100px",
                  marginBottom: "10px",
                }}
              />
              <>
                <Box sx={{ ...styles.input_label_container }}>
                  <label htmlFor="edf_input" style={{ ...styles.label_button }}>
                    Browse Files
                  </label>
                  <input
                    type="file"
                    name="edfs"
                    id="edf_input"
                    style={{ ...styles.input_select }}
                    onInputCapture={(e) => {
                      getFile(e);
                    }}
                    // onInput={(e) => getFile(e)}
                    accept=".edf"
                    multiple
                  />
                </Box>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    lineHeight: "21px",
                    /* identical to box height */

                    letterSpacing: "0.02em",

                    color: "#000000",
                  }}
                >
                  Or drop file's here.
                </Typography>
              </>
            </Stack>
          </Paper>
        </Paper>
      ) : (
        <AfterFileDrop
          selectedFilesList={selectedFilesList}
          setSelectedFilesList={setSelectedFilesList}
          getFile={getFile}
          setDisableNext={setDisableNext}
          filesProgress={filesProgress}
          setFilesProgress={setFilesProgress}
        />
      )}
    </Paper>
  );
}

export default FileUpload;
