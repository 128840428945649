import React from "react";
//mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//encodings
import statusEncodings from "./statusEncoding.json";
//config
import { statusTooltipConfig } from "./statusTooltipConfig.js";
//internal
import { CustomTooltip, CustomTooltipContentComponent } from "./StatusTooltip";

//get color function
function getColorAndTitle(status, extraData) {
  if (extraData?.error) {
    return statusEncodings["error"]
      ? statusEncodings["error"]
      : {
          title: status,
          backgroundColor: "#EAEAEA",
          color: "#8A8A8A;",
        };
  }
  return statusEncodings[status]
    ? statusEncodings[status]
    : {
        title: status,
        backgroundColor: "#EAEAEA",
        color: "#8A8A8A;",
      };
}

//return the status inner component
function getStatus(
  status,
  colorMode,
  enableStatusTooltip,
  statusDetail,
  extraData,
  sx
) {
  if (enableStatusTooltip && Object.keys(statusTooltipConfig).length) {
    if (statusTooltipConfig[status]?.tooltip || extraData?.error) {
      return (
        <CustomTooltip
          title={
            <CustomTooltipContentComponent
              title={statusDetail?.code}
              description={statusDetail?.detail}
            />
          }
          placement="right-start"
          arrow
          sx={
            extraData?.error
              ? { ...statusTooltipConfig["error"]?.sx }
              : {
                  ...statusTooltipConfig[status]?.sx,
                }
          }
        >
          <Box
            sx={{
              cursor: "pointer",
              backgroundColor:
                colorMode === "bg"
                  ? getColorAndTitle(status, extraData)["backgroundColor"]
                  : "transparent",
              color:
                colorMode === "bg"
                  ? getColorAndTitle(status, extraData)["color"]
                  : colorMode === "text"
                  ? getColorAndTitle(status, extraData)["color"]
                  : "#0f0f0f",
              borderRadius: "5px",
              display: "flex",
              justifyContent: colorMode === "bg" ? "center" : "flex-start",
              alignItems: "center",
              maxWidth: "160px",
              minWidth: "150px",
              py: colorMode === "bg" ? "8px" : 0,
              px: colorMode === "bg" ? "24px" : 0,
              ...sx,
            }}
          >
            <Typography component="span">
              {getColorAndTitle(status, extraData).title}
            </Typography>
          </Box>
        </CustomTooltip>
      );
    }
  }
  return (
    <Box
      sx={{
        backgroundColor:
          colorMode === "bg"
            ? getColorAndTitle(status, extraData)["backgroundColor"]
            : "transparent",
        color:
          colorMode === "bg"
            ? getColorAndTitle(status, extraData)["color"]
            : colorMode === "text"
            ? getColorAndTitle(status, extraData)["color"]
            : "#0f0f0f",
        borderRadius: "5px",
        display: "flex",
        justifyContent: colorMode === "bg" ? "center" : "flex-start",
        alignItems: "center",
        maxWidth: "160px",
        minWidth: "150px",
        py: colorMode === "bg" ? "8px" : 0,
        px: colorMode === "bg" ? "24px" : 0,
        m: "auto",
        ...sx,
      }}
    >
      <Typography component="span">
        {getColorAndTitle(status, extraData).title}
      </Typography>
    </Box>
  );
}

export default function Status({
  status,
  enableStatusTooltip,
  colorMode,
  statusDetail,
  extraData,
  sx,
  ...props
}) {
  status = status.toLowerCase();
  return getStatus(
    status,
    colorMode,
    enableStatusTooltip,
    statusDetail,
    extraData,
    sx
  );
}

Status.defaultProps = {
  status: "Status", //any
  colorMode: "bg", //text or bg
};
