///////////////React//////////////
import { useState, useContext, useEffect, useRef } from "react";
//mui
import { Box } from "@mui/material";
//internal
import PageLoader from "../components/loader/PageLoader";
import UpdateProfileForm from "../components/forms/updateProfileForm";
//////////////////HOc//////////////
import AuthHOC from "../hoc/AuthHOC";
/////////////Helpers////////////
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../utils/helpers";
///////////////Context////////////
//axiosInstance
//hooks
import useAxios from "../hooks/useAxios";
import { notifyContext } from "../context/notifyContext";
//3rd party
import { useNavigate } from "react-router-dom";
import { globalContext } from "../context/globalContext";

function Profile({ user, dispatch }) {
  const [loading, setLoading] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const { setPageTitle } = useContext(globalContext);
  const [loader, setLoader] = useState({
    loading: false,
    message: "Loading",
  });
  const [refreshProfileData, setRefreshProfileData] = useState(false);

  //context
  const notifyCtx = useContext(notifyContext);
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle("Profile");
    const abortController = new AbortController();
    const getData = async () => {
      setLoader((previousState) => {
        return { ...previousState, loading: true };
      });
      try {
        const config = axiosConfig({
          uri: "/accounts/consumer/services/",
          method: "GET",
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setServicesData(response.data);
      } catch (error) {
        httpErrorHandler(error, notifyCtx, navigate);
      }
      try {
        const config = axiosConfig({
          uri: "/accounts/consumer/",
          method: "GET",
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });

        setProfileData(response.data);
        localStorage.setItem(
          "sp_info",
          JSON.stringify(response.data.supervisor)
        );
      } catch (error) {
        httpErrorHandler(error, notifyCtx, navigate);
      }
      setLoader((previousState) => {
        return { ...previousState, loading: false };
      });
    };
    getData();
    return () => {
      abortController.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (refreshProfileData) {
      setRefreshProfileData(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //////////////////////Profile Update Utility Function////////////////////
  const updateProfile = async (firstName, lastName) => {
    setLoading(true);
    try {
      const config = axiosConfig({
        uri: "/accounts/consumer/",
        data: { first_name: firstName, last_name: lastName },
        method: "PATCH",
      });

      const response = await axiosInstance.current({
        ...config,
      });
      dispatch({ type: "update_user", user: response.data });

      setProfileData(response.data);
      // navigate(0);
      notificationsHandler(notifyCtx, "success", "Name Updated Successfully");
    } catch (error) {
      httpErrorHandler(error, notifyCtx, navigate);
    }
    setLoading(false);
  };

  ////////////////////Password Update Function/////////////////////////
  const updatePassword = async (password1, password2, old_password) => {
    setLoading(true);
    try {
      const config = axiosConfig({
        uri: "/auth/password/change/",
        method: "POST",
        data: {
          new_password1: password1,
          new_password2: password2,
          old_password: old_password,
        },
      });
      await axiosInstance.current({
        ...config,
      });
      notificationsHandler(
        notifyCtx,
        "success",
        "Password Updated Successfully"
      );
    } catch (error) {
      httpErrorHandler(error, notifyCtx, navigate);
    }
    setLoading(false);
  };

  return (
    <>
      {loader.loading ? (
        <PageLoader open={loader.loading} message={loader.message} />
      ) : (
        <Box
          sx={{
            display: "flex",
            backgroundColor: " #F5F6FA",
            flexDirection: "column",
            alignItems: "start",
            height: "100%",
            px: "40px",
          }}
        >
          <UpdateProfileForm
            loading={loading}
            updateProfile={updateProfile}
            updatePassword={updatePassword}
            servicesData={servicesData}
            profileData={profileData}
            supervisorData={profileData.supervisor}
          />
        </Box>
      )}
    </>
  );
}

export default AuthHOC(Profile);
