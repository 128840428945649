////////////////// React//////////////////////
import PropTypes from "prop-types";
/////////////////MUI/////////////////////////
import { Box, Stack } from "@mui/material";

///////////////////Forms////////////////////

import ProfileForm from "./profileForm";

///////////////Internal Components/////////////
import SupervisorInfo from "../profile/SupervisorInfo";
import useAuth from "../../hooks/useAuth";

export default function UpdateProfileForm(props) {
  const [user] = useAuth();

  return (
    <>
      {/* START: PROFILE PAGE */}
      <Box sx={{ width: "100%" }}>
        <Stack
          sx={{ mb: "40px", width: "100%" }}
          direction="column"
          spacing={2}
        >
          {/* START: PERSONAL INFO FORM */}
          <ProfileForm
            firstName={user?.user?.first_name}
            lastName={user?.user?.last_name}
            email={user?.user?.email}
            updateProfile={props?.updateProfile}
            updatePassword={props?.updatePassword}
            created_on={user?.user?.date_joined}
          />
          {/* END: PERSONAL INFO FORM */}

          {/* START: OTHER INFO FORM  */}
          <SupervisorInfo
            supervisorData={
              localStorage.getItem("sp_info")
                ? JSON.parse(localStorage.getItem("sp_info"))
                : props.profileData.supervisor !== undefined
                ? props.profileData.supervisor
                : []
            }
          />
          {/* END: OTHER INFO FORM */}
        </Stack>
      </Box>
      {/* END: PROFILE PAGE */}
    </>
  );
}

UpdateProfileForm.defaultProps = {
  profileData: null,
};
UpdateProfileForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  updateProfile: PropTypes.func.isRequired,
};
