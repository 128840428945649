//react
import React from "react";
//mui
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

//custom tooltip
export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "none",

    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    borderRadius: "8px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000",
  },
}));
//custom tooltip content
export const CustomTooltipContentComponent = ({
  title,
  description,
  ...props
}) => {
  return (
    <Box sx={{ maxWidth: "300px", backgroundColor: "transparent", p: "12px" }}>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "12px",
          lineHeight: "18px",
          mb: "4px",
          color: "typography.grey700",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "18px",
          color: "typography.grey500",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};
