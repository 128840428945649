//react
import React, { useState } from "react";
//mui

import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
function AdditionalSettings({
  additionalSettings,
  setIsAdditionalSettingSelected,
  ...props
}) {
  const [selected, setSelected] = useState([]);
  const handleChange = (e) => {
    localStorage.setItem("additional_settings", JSON.stringify(e.target.value));
    setSelected(e.target.value);
  };
  return (
    <Grid
      container
      sx={{
        width: "100%",
        boxShadow: "0px 3px 20px 4px rgba(0, 0, 0, 0.04)",
        mb: "4rem",
        ...props.sx,
      }}
    >
      <Grid
        item
        xs={12}
        md={3}
        sx={{
          background: "#fff",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          p: "24px 56px",
          pr: 0,
        }}
      >
        <Typography
          fontWeight={500}
          fontSize={18}
          color="#000000"
          sx={{
            mr: "2rem",
          }}
        >
          Additional Settings
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        sx={{ py: "24px", pl: "26px", background: "#fff" }}
      >
        <Select
          labelId="primary-channel-select"
          id="primary-channels"
          multiple
          value={selected}
          onChange={(e) => {
            handleChange(e);
          }}
          renderValue={(selected) => selected.join(", ")}
          sx={{ MaxWidth: "100%", width: "70%", height: "40px" }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
              },
            },
          }}
        >
          {additionalSettings.map((channel, index) => {
            const [key, value] = Object.entries(channel)[0];
            return (
              <MenuItem key={index} value={key}>
                <Checkbox
                  checked={selected.includes(key)}
                  sx={{
                    "&.Mui-checked": {
                      color: "#2f7ec7",
                    },
                    ml: "10px",
                  }}
                />
                <ListItemText>
                  {value?.name?.includes("&#8805;") ? (
                    <>
                      <span>{value?.name?.split("&#8805;")[0]}&#8805;</span>
                      <span>{value?.name?.split("&#8805;")[1]}</span>
                    </>
                  ) : (
                    value?.name
                  )}
                </ListItemText>
              </MenuItem>
            );
          })}
        </Select>
      </Grid>
      <Grid item xs sx={{ backgroundColor: "#fff" }}></Grid>
    </Grid>
  );
}

AdditionalSettings.defaultProps = {
  additionalSettings: [],
};

export default AdditionalSettings;
