import { Stack, Pagination, Typography } from "@mui/material";
import { DRAWER_WIDTH } from "../components/Layout";
export default function CustomPagination({
  disabled,
  last_page_no,
  limit,
  handlePaginationChange,
  sx,
}) {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: "fixed",
        bottom: "0",
        right: "0",
        left: "0",
        backgroundColor: "#fff",
        width: "100%",
        paddingLeft: `${DRAWER_WIDTH}px `,
        ...sx,
      }}
    >
      {/* START: First Row */}
      <Stack
        px="48px"
        py="12px"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Pagination
          count={last_page_no}
          disabled={disabled}
          onChange={handlePaginationChange}
        />
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "17px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#7E7E7E",
          }}
        >
          Showing {limit} results
        </Typography>
      </Stack>
      {/* END: First Row */}
    </Stack>
  );
}
