// -----------------------------Packages-------------------------

import { toast } from "react-toastify";
//--------internal -------------
import WsMessages from "./wsMessages.json";

import axios from "axios";
import Status from "../components/status/Status";

export const DEBUG = process.env.NODE_ENV === "development";

// constants
export const API_URL = DEBUG
  ? process.env.REACT_APP_DEV_API_URL
  : process.env.REACT_APP_PROD_API_URL;

export const WEBSOCKET_URL = DEBUG
  ? process.env.REACT_APP_DEV_WEBSOCKET_URL
  : process.env.REACT_APP_PROD_WEBSOCKET_URL;

// generate axios params
export const axiosConfig = ({
  method,
  uri = null,
  url = null,
  data = null,
  params = {},
  headers = {},

  ...others
}) => {
  if (!uri && !url) {
    throw Error("You Must provide url or uri to proceed ");
  }
  let reqUrl = url
    ? url
    : uri.startsWith("/")
    ? `${API_URL}${uri}`
    : `${API_URL}/${uri}`;

  let options = {
    method,
    url: reqUrl,
    data,
    params: { ...params },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
    ...others,
  };

  // if (DEBUG) {
  //   const accessToken = new HandleAuthToken().retrieveToken();
  //   console.log(accessToken, "axios config");
  //   if (accessToken)
  //     options["headers"]["Authorization"] = `Bearer ${accessToken}`;
  //   return options;
  // }
  // options["withCredentials"] = true;
  return options;
};

// helpers for storing and retrieving user info
export class HandleLocalStorage {
  add = (key, value) => {
    localStorage.setItem(key, value);
    return value;
  };

  retrieve = (key) => {
    return localStorage.getItem(key);
  };

  clear = (key) => {
    const value = this.retrieve(key);
    localStorage.removeItem(key);
    return value;
  };
}

export class HandleAuthToken extends HandleLocalStorage {
  #USER = "user";
  #TOKEN = "nb-score-access-token";
  #REFRESH_TOKEN = "nb-score-refresh-token";
  #TOKEN_EXPIRE = "nb-score-access-token-expiration";
  #REFRESH_TOKEN_EXPIRE = "nb-score-refresh-token-expiration";

  addUser = (user) => this.add(this.#USER, JSON.stringify(user));
  clearUser = () => this.clear(this.#USER);
  retrieveUser = () => JSON.parse(this.retrieve(this.#USER));

  addToken = (token) => this.add(this.#TOKEN, token);
  clearToken = () => this.clear(this.#TOKEN);
  retrieveToken = () => this.retrieve(this.#TOKEN);

  addTokenExp = (expiration) => this.add(this.#TOKEN_EXPIRE, expiration);
  clearTokenExp = () => this.clear(this.#TOKEN_EXPIRE);
  retrieveTokenExp = () => this.retrieve(this.#TOKEN_EXPIRE);

  addRefreshToken = (token) => this.add(this.#REFRESH_TOKEN, token);
  clearRefreshToken = () => this.clear(this.#REFRESH_TOKEN);
  retrieveRefreshToken = () => this.retrieve(this.#REFRESH_TOKEN);

  addRefreshTokenExp = (expiration) =>
    this.add(this.#REFRESH_TOKEN_EXPIRE, expiration);
  clearRefreshTokenExp = () => this.clear(this.#REFRESH_TOKEN_EXPIRE);
  retrieveRefreshTokenExp = () => this.retrieve(this.#REFRESH_TOKEN_EXPIRE);

  //adding the data to local storage if debug=true (development)
  addData = (user, accessToken = null, refreshToken = null) => {
    this.addUser(user);
    if (accessToken) {
      if (DEBUG) this.addToken(accessToken.token);
      this.addTokenExp(accessToken.expiration);
    }
    if (refreshToken) {
      if (DEBUG) this.addRefreshToken(refreshToken.token);
      this.addRefreshTokenExp(refreshToken.expiration);
    }
  };

  clearData = () => {
    this.clearUser();
    this.clearTokenExp();
    this.clearRefreshTokenExp();
    this.clearToken();
    this.clearRefreshToken();
  };
}

export const logout = async ({
  refreshToken,
  notifyCtx,
  navigate,
  abortController,
}) => {
  try {
    const config = axiosConfig({
      method: "POST",
      uri: `/auth/logout/`,
    });
    if (DEBUG) {
      config["data"] = { refresh: refreshToken };
    }
    await axios({
      ...config,
      withCredentials: true,
    });
    abortController.abort();
  } catch (error) {
    // console.log(error);
    // httpErrorHandler(error, notifyCtx, () => {});
  }
};

// TODO: Change cellData to formatData
export const cellData = ({ cell, status, statusColorMode, ...props }) => {
  function isValidDate(dateString) {
    //accepted format - 2018-08-01T18:30:00.000Z
    const _regExp = new RegExp(
      "^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$"
    );
    return _regExp.test(dateString);
  }
  if (status) {
    return <Status status={`${cell}`} colorMode={statusColorMode} />;
  }
  if (typeof cell === "boolean") {
    return cell ? "✅" : "❌";
  }
  if (typeof cell === "number") {
    return cell;
  }
  if (cell === null) {
    return "Not performed yet";
  }
  if (isValidDate(cell)) {
    return new Date(cell).toDateString();
  }
  return cell;
};
export function genPageTitle(pathname) {
  if (pathname === "/") return "Dashboard";
  if (pathname === "/montages/") return "Montages";
  if (pathname === "/credits/") return "Credits";

  if (pathname.includes("/montages/view/")) {
    const entityName = decodeURIComponent(pathname).split("/")[3];
    // const entityId = decodeURIComponent(pathname).split("/")[4];

    return entityName + " ";
  }
  if (pathname.includes("/config")) {
    const entityName = decodeURIComponent(pathname).split("/")[2];
    return entityName;
    // return "Call Info";
  }

  if (pathname.includes("/montages/test")) {
    const entityName = decodeURIComponent(pathname).split("/")[2];
    return entityName;
  }

  if (pathname.includes("/montages/")) {
    const entityName = decodeURIComponent(pathname).split("/")[2];
    return entityName;
  }

  return pathname
    .split("/")
    .filter((el) => el !== "")
    .join(" ")
    .replace(/credits/g, "credit")
    .replace(/-/g, " ")
    .split("%");
}

export const getDate = (datetimestring) => {
  if (datetimestring == null) {
    return "-";
  }
  const options = { day: "numeric", month: "short", year: "numeric" };
  const date_ = new Intl.DateTimeFormat("en-GB", options).format(
    new Date(datetimestring)
  );
  return date_;
};
export const getTime = (datetimestring) => {
  if (datetimestring == null) {
    return "";
  }
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  const time_ = new Intl.DateTimeFormat([], options).format(
    new Date(datetimestring)
  );
  return time_;
};

//get time difference function
export function getTimeDifference(datetimestring) {
  if (datetimestring === null) {
    return "-";
  }
  const call_time_obj = new Date(datetimestring);

  const current_time_obj = new Date();

  const options_time = { hour: "numeric", minute: "numeric", hour12: true };

  const current_min = current_time_obj.getMinutes();
  const call_min = call_time_obj.getMinutes();
  let final_time = "";

  if (
    call_time_obj.getFullYear() === current_time_obj.getFullYear() &&
    call_time_obj.getDate() === current_time_obj.getDate() &&
    call_time_obj.getMonth() === current_time_obj.getMonth()
  ) {
    if (call_time_obj.getHours() === current_time_obj.getHours()) {
      final_time = current_min - call_min;
    } else {
      final_time = 60 - call_min + current_min;
    }
    if (final_time > 59) {
      //will return the time if exceed the 59mins
      const time_ = new Intl.DateTimeFormat([], options_time).format(
        new Date(datetimestring)
      );
      return [time_, call_time_obj];
    }
    return [final_time + "mins ago", call_time_obj];
  }
  //will display date if the call date is not same as current
  const options_date = { day: "numeric", month: "short", year: "2-digit" };
  const time_ = new Intl.DateTimeFormat([], options_date).format(
    new Date(datetimestring)
  );

  return [time_, call_time_obj];
}

//create final config json
export function createFinalConfigJson(data) {
  const finalChannelsConfigJson = {
    channels: {},
    formats: [],
    optionals: [],
    version: 1,
  };

  let schema = localStorage.getItem("schema");
  const createAdditionalSetting = () => {
    if (localStorage.getItem("additional_settings")) {
      const additionalSettings = JSON.parse(
        localStorage.getItem("additional_settings")
      );

      const temp_data = [];
      for (let setting of additionalSettings) {
        if (setting) temp_data.push(setting);
      }
      return temp_data;
    }
    return [];
  };
  const createBackupChannels = (backupChannels) => {
    const tempBackupChannels = [];
    backupChannels.map((backupChannel, index) => {
      tempBackupChannels.push({
        channel: backupChannel.backupPrimaryChannel,
        ref:
          backupChannel.backupRefChannel === "N/A"
            ? null
            : backupChannel.backupRefChannel,
      });
      return backupChannel;
    });
    return tempBackupChannels;
  };
  const createChannelsConfig = (data) => {
    let temp_data = {};

    if (
      data.some((channel, index) => {
        if (channel.primaryChannel !== "N/A") {
          return true;
        }
        return false;
      })
    ) {
      data.map((channel, index) => {
        if (channel.primaryChannel === "N/A") {
          return channel;
        }
        temp_data[channel.channel] = [
          {
            channel: channel.primaryChannel,
            ref: channel.refChannel === "N/A" ? null : channel.refChannel,
          },
          ...createBackupChannels(channel.backups),
        ];
        return channel;
      });
    }

    return temp_data;
  };
  if (schema) {
    schema = JSON.parse(schema);

    finalChannelsConfigJson.version = schema.version;
    //default to json
    finalChannelsConfigJson.formats = schema.formats;
    finalChannelsConfigJson.optionals = createAdditionalSetting();
    if (
      localStorage.getItem("montages_configurations") &&
      localStorage.getItem("montages_configurations") !== "undefined"
    ) {
      finalChannelsConfigJson.channels = createChannelsConfig(
        JSON.parse(localStorage.getItem("montages_configurations"))
      );
    }
    return finalChannelsConfigJson;
  }
}

//set active step for stepper
export function setStepperActiveStep(step, setActiveStep, local = true) {
  setActiveStep(step);
  if (local) {
    localStorage.setItem("acs", step);
  }
}

//verify schema - verify the locally saved montages config with the new schema got. If schema matches then loads the locally saved montages configurations
export function verifySchema(locallySavedConfigurations, newSchema) {
  let notFound = false;
  if ("channels" in newSchema && newSchema.channels.length) {
    //getting local channels
    const localSchemaChannels = locallySavedConfigurations.map(
      (channel, index) => {
        return channel.channel;
      }
    );
    //getting schema channels which might be updated
    const newChannels = newSchema.channels;
    notFound = newChannels.some((channel, index) => {
      if (!localSchemaChannels.includes(channel)) {
        //if localSchema not includes the channel return true to break the some.

        return true;
      }
      return false;
    });
  }
  //return boolean if atleast one channel not matched then it will return true else false
  return notFound;
}

//creates data for preview channel diaglog
export function createMontageConfigPreview(configData) {
  //extract backup channels
  const extractBackupChannels = (channels) => {
    let backupChannels = [];
    for (let index = 1; channels.length > index; index++) {
      backupChannels.push({
        id: index - 1,
        backupPrimaryChannel:
          channels[index].channel !== null ? channels[index].channel : "N/A",
        backupRefChannel:
          channels[index].channel !== null ? channels[index].channel : "N/A",
      });
    }
    return backupChannels;
  };
  if (Object.keys(configData).length <= 0) {
    return [];
  }

  let tempConfigData = [];
  let indexCount = 0;
  for (let channel in configData) {
    tempConfigData.push({
      id: indexCount,
      channel: channel,
      primaryChannel:
        configData[channel][0].channel !== null
          ? configData[channel][0].channel
          : "N/A",
      refChannel:
        configData[channel][0].ref !== null
          ? configData[channel][0].ref
          : "N/A",
      backups: extractBackupChannels(configData[channel]),
    });
    indexCount++;
  }

  return tempConfigData;
}

//start processing
export async function submitCalls({
  notifyCtx,
  callId,
  setUpdateCallsList,
  setButtonLoading,
  navigate,
  retry = false,
  setCallList,
  axiosInstance,
}) {
  try {
    //for disabling or enabling buttons
    setButtonLoading((prevState) => {
      return retry
        ? { ...prevState, retrying: [...prevState.processing, callId] }
        : { ...prevState, processing: [...prevState.processing, callId] };
    });
    //calllilst
    setCallList((prevCalls) => {
      return {
        ...prevCalls,
        results: prevCalls?.results.map((call, index) => {
          if (call.id === callId) {
            return { ...call, status: "submitted" };
          }
          return call;
        }),
      };
    });
    const config = axiosConfig({
      uri: `/calls/submit`,
      method: "POST",
      data: { call_id: callId },
    });
    await axiosInstance.current({
      ...config,
    });
    notificationsHandler(
      notifyCtx,
      "success",
      "Files Submitted For Processing "
    );
    setUpdateCallsList(true);
  } catch (error) {
    //for disabling or enabling buttons
    setButtonLoading((prevState) => {
      return retry
        ? {
            ...prevState,
            retrying: prevState.retrying.filter((call_id, ind) => {
              return call_id !== callId;
            }),
          }
        : {
            ...prevState,
            processing: prevState.processing.filter((call_id, ind) => {
              return call_id !== callId;
            }),
          };
    });
    setCallList((prevCalls) => {
      return {
        ...prevCalls,
        results: prevCalls?.results.map((call, index) => {
          if (call.id === callId) {
            return { ...call, status: "error" };
          }
          return call;
        }),
      };
    });
    httpErrorHandler(error, notifyCtx, navigate);
  }
}
//download files - default format csv if empty array passed or nothing passed
export async function downloadCalls(
  navigate,
  globalCtx,
  notifyCtx,
  callIds,
  axiosInstance,
  formats = []
) {
  try {
    globalCtx.setOpenProgressStatus(true);
    globalCtx.setProgressStatusMessage("Downloading Your Files");

    const config = axiosConfig({
      uri: `/calls/download-files`,
      method: "POST",
      data: {
        call_ids: callIds,
        formats: formats.length ? formats : ["csv"],
      },
    });
    await axiosInstance.current({
      ...config,
    });
    globalCtx.setOpenProgressStatus(false);

    notificationsHandler(
      notifyCtx,
      "success",
      "Files Submitted For Downloading"
    );
  } catch (error) {
    if (error.response) {
      httpErrorHandler(error, notifyCtx, navigate);
    } else if (error.request) {
      notificationsHandler(notifyCtx, "error", `${error.message}`);
    } else {
      notificationsHandler(notifyCtx, "error", `${error.message}`);
    }
    globalCtx.setOpenProgressStatus(false);
  }
}

//creates notification based on event type
export function socketMessageHandler(
  setMessage,
  globalCtx,
  notifyCtx,
  message
) {
  message = JSON.parse(message);
  globalCtx.setOpenProgressStatus(false);
  if (message.type === "zip_files.success") {
    try {
      const url = message.data.files[0];
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Scored_EDF.zip"); //or any other extension
      document.body.appendChild(link);
      link.click();
      setMessage({});
    } catch (error) {
      httpErrorHandler(error, notifyCtx, () => {});
    }
  }

  if (WsMessages[message.type]?.show_notification) {
    notificationsHandler(
      notifyCtx,
      WsMessages[message.type] ? WsMessages[message.type].severity : "info",
      WsMessages[message.type] ? WsMessages[message.type].message : message.type
    );
  } else if (WsMessages[message.type]) {
    notificationsHandler(
      notifyCtx,
      WsMessages[message.type] ? WsMessages[message.type].severity : "info",
      WsMessages[message.type] ? WsMessages[message.type].message : message.type
    );
  }
}

export const getConsentMessage = (
  calls_submitted,
  calls_skipped,
  calls_anonymizing
) => {
  if (calls_submitted.length > 0 && calls_skipped.length <= 0) {
    return [
      "Upload Successful",
      `These uploaded files are found. Would you like to  upload more or want to continue?`,
      "success",
    ];
  } else if (calls_submitted.length <= 0 && calls_skipped.length > 0) {
    return [
      "It seems that there was an error while uploding.",
      `These files were not uploaded. Re-upload the files or upload new files.`,
      "error",
    ];
  } else if (calls_submitted.length > 0 && calls_skipped.length > 0) {
    return [
      "Upload Error.",
      `Some files were not uploaded successfuly. Re-upload the same files, upload new files or continue to configure montage. `,
      "error",
    ];
  } else if (
    !calls_skipped.length &&
    !calls_submitted.length &&
    calls_anonymizing.length
  ) {
    return [
      "Files Under Anonymization.",
      `Your files are under anonymization. Please check status after some time. `,
      "info",
    ];
  } else {
    return [
      "No Valid Files Found",
      `No valid files found. There may be some upload error in previous uploaded files. `,
      "error",
    ];
  }
};

// customMessages = {
//   status: 400,
//   message: "Channel Extraction Already in Progress",
// };
export function httpErrorHandler(
  error,
  notifyCtx,
  navigate,
  customErrorMessage = []
) {
  //this function handles the custom error messages if sent
  function customErrorMessageHandler(customErrorMessage, status) {
    return customErrorMessage.find((element, index) => {
      return status === element.status;
    });
  }
  try {
    // for abort cancellor message ignore
    if (error.message === "canceled") {
      return;
    }

    //for error.response
    if (error.response) {
      if (error.response.status >= 500) {
        notificationsHandler(notifyCtx, "error", "Server Error Occured");
        navigate(`/error/${error.response.status}`);
      } else if (
        error.response.status === 401 ||
        error.response.status === 403
      ) {
        notificationsHandler(
          notifyCtx,
          "error",
          `${error?.response?.detail ? error.response.detail : error.message}`
        );
        // navigate(`/error/${error.response.status}`);
      } else {
        if (error.response.data.__proto__ !== Object.prototype) {
          notificationsHandler(
            notifyCtx,
            "error",
            "Not Found! Something Went Wrong"
          );
          return;
        } else if ("detail" in error.response.data) {
          const message = customErrorMessageHandler(
            customErrorMessage,
            error.response.status
          );
          notificationsHandler(
            notifyCtx,
            "error",
            `${
              customErrorMessage.length
                ? message !== undefined
                  ? message.message
                  : error.response.data.detail
                : error.response.data.detail
            }`
          );
          return;
        } else {
          const message = customErrorMessageHandler(
            customErrorMessage,
            error.response.status
          );
          for (let key in error.response.data) {
            notificationsHandler(
              notifyCtx,
              "error",
              `${
                customErrorMessage.length
                  ? message !== undefined
                    ? message.message
                    : error.response.data[key]
                  : error.response.data[key]
              }`
            );
          }
        }
      }
    }
    //for request errors
    else if (error.request) {
      notificationsHandler(
        notifyCtx,
        "error",
        `${
          customErrorMessage.length
            ? customErrorMessageHandler(
                customErrorMessage,
                error.request.status
              ).message
            : error.message
        }`
      );
    }
    //for any other errors
    else {
      notificationsHandler(notifyCtx, "error", `${error.message}`);
    }
  } catch (error) {
    //for handling any other error occured in handling above errors
    notificationsHandler(notifyCtx, "error", error.message);
  }
}
//todo - remove the notifyctx dependency from notification handler
export function notificationsHandler(notifyCtx, severity, message, title = "") {
  switch (severity) {
    case "error":
      toast.error(`${message}`, {});
      break;
    case "success":
      toast.success(`${message}`, {});
      break;
    case "info":
      toast.info(`${message}`, {});
      break;
    case "warning":
      toast.warning(`${message}`, {});
      break;
    default:
      toast.info(`${message}`, {});
      break;
  }
}
