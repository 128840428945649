//mui imports
import Paper from "@mui/material/Paper";
//assests
import NeurobitScoreLogo from "../../assets/logo/Neurobit-Score-Logo-Transparent 1.svg";
export default function Loading() {
  return (
    <Paper
      elevation={0}
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={NeurobitScoreLogo}
        alt="nb-score-logo"
        style={{ width: "200px" }}
      />
    </Paper>
  );
}
