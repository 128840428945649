import { Paper, Container, Stack, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../assets/logo/Neurobit-Score-Logo-Transparent 1.svg";
import success from "../assets/password/vector.svg";
export default function EmailVerified({ verifying, error, counter }) {
  return (
    <Container
      maxWidth={false}
      style={{
        padding: "50px",
        backgroundColor: "#E5E5E5",
        height: "100vh",
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          style={{
            width: "100%",
            maxWidth: "700px",
            padding: "64px 64px 140px 64px",
            borderRadius: "12px",
          }}
        >
          <Stack direction="column" spacing={4}>
            <Stack direction="column" spacing={8}>
              <Stack justifyContent="center" alignItems="center">
                <img src={logo} alt="logo" width={108} height={60} />
              </Stack>
              {!verifying && !error && (
                <Stack justifyContent="center" alignItems="center">
                  <img src={success} alt="done" width={100} height={100} />
                </Stack>
              )}
            </Stack>
            {verifying ? (
              <Typography
                sx={{
                  fontSize: "32px",
                  fontWeight: "500",
                  color: "#0F0F0F",
                  textAlign: "center",
                  lineHeight: "0.1em",
                  width: "100%",
                }}
              >
                Verifying Your Email ...
              </Typography>
            ) : !error ? (
              <Stack direction="column" spacing={4}>
                <Stack justifyContent="center" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: "32px",
                      fontWeight: "500",
                      color: "#0F0F0F",
                      textAlign: "center",
                      lineHeight: "0.1em",
                      width: "100%",
                    }}
                  >
                    Email Verified!{" "}
                  </Typography>
                </Stack>
                <Stack justifyContent="center" alignItems="center">
                  <Stack direction="column">
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#8A8A8A",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Your email address has been verified successfully!{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#8A8A8A",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      You will be redirected to the login page in {counter}{" "}
                      seconds.{" "}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack justifyContent="center" alignItems="center">
                  <Link to="/accounts/signin">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#2F7EC7",
                        padding: "10px",
                        minWidth: "200px",
                        borderRadius: "5px",
                        width: "40%",
                        ":hover": {
                          backgroundColor: "#225889",
                        },
                      }}
                    >
                      LOGIN
                    </Button>
                  </Link>
                </Stack>
              </Stack>
            ) : (
              <Stack direction="column" spacing={4} paddingTop="2rem">
                <Stack justifyContent="center" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: "32px",
                      fontWeight: "500",
                      color: "#0F0F0F",
                      textAlign: "center",
                      lineHeight: "0.1em",
                      width: "100%",
                    }}
                  >
                    Email Not Verified!{" "}
                  </Typography>
                </Stack>
                <Stack justifyContent="center" alignItems="center">
                  <Stack direction="column">
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: "400",
                        color: "#8A8A8A",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Something went wrong in verifying your email. <br></br>
                      Please try again after some time
                    </Typography>
                  </Stack>
                </Stack>
                {/* <Stack justifyContent="center" alignItems="center">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#2F7EC7",
                      padding: "10px",
                      borderRadius: "5px",
                      width: "40%",
                    }}
                    href="/accounts/signin"
                  >
                    LOGIN
                  </Button>
                </Stack> */}
              </Stack>
            )}

            <Stack direction="column">
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#8A8A8A",
                  textAlign: "center",
                  lineHeight: "0.1em",
                  width: "100%",
                  marginTop: "72px",
                }}
              >
                If you need any help, contact{" "}
                <font color="#2F7EC7">Neurobit PSG Support</font>
              </Typography>
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}
