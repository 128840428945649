//////React Imports////////
import React, { useState, useEffect, useContext, memo, useRef } from "react";
////////////Mui Imports//////////
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Icon from "@mui/material/Icon";
// import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
///////////assets///////////

import SmallFileIcon from "../../assets/montage/small_file_icon.svg";
//internal
import Status from "../../components/status/Status";
//////////////helpers and utils////////
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";

////////////////3rd party////////////

import { useNavigate } from "react-router-dom";
//context
import { notifyContext } from "../../context/notifyContext";
import { socketContext } from "../../context/socketContext";
//3rd
import axios from "axios";
//axiosInstance
//hooks
import useAxios from "../../hooks/useAxios";

/////////file icon/////
export const SmallFileIconSvg = (props) => {
  return (
    <Icon sx={{ ...props.sx }}>
      <img
        src={SmallFileIcon}
        alt="small_file_icon"
        style={{ maxWidth: "100%" }}
      ></img>
    </Icon>
  );
};

//This function will upload the file,will set the progress and will add the file to uploaded files once uploaded.
const uploadFile = async (
  montage_id,
  file,
  fileIndex,
  setUploadedFiles,
  selectedFiles,
  setSelectedFilesList,
  filesProgress,
  setFilesProgress,
  notifyCtx,
  navigate,
  axiosInstance
) => {
  try {
    const config = axiosConfig({
      uri: `/montages/file/`,
      method: "PUT",
      data: {
        montage_id: montage_id,
        file_name: file.file.name,
      },
    });
    const backendRes = await axiosInstance.current({
      ...config,
    });

    const call_id = backendRes.data.call.id;

    const gcsRes = await axios({
      url: `${backendRes.data.upload_url}`,
      method: "POST",
      data: {},
      headers: {
        "x-goog-resumable": "start",
        "Content-Type": "application/octet-stream",
        "x-goog-meta-trigger-backend": backendRes.headers["x-trigger-backend"],
        "x-goog-meta-file-signature": backendRes.headers["x-file-signature"],
      },
    });

    await axios({
      url: `${gcsRes.headers.location}`,
      method: "PUT",
      data: file.file,
      headers: {
        "Content-Type": "application/octet-stream",
        "Cache-Control": null,
        "X-Requested-With": null,
        Accept: null,
      },
      onUploadProgress: (event) => {
        setFilesProgress((prev) => {
          return prev.map((fileItem, index) => {
            if (fileItem.file === file.file) {
              return {
                ...fileItem,
                progress: (event.loaded / event.total) * 100,
              };
            } else {
              return fileItem;
            }
          });
        });
      },
    });

    setUploadedFiles((previousFiles) => {
      return [
        ...previousFiles,
        {
          call_id: call_id,
          file: file,
          fileIndex: fileIndex,
          anonymized: "anonymizing",
        },
      ];
    });
  } catch (error) {
    httpErrorHandler(error, notifyCtx, navigate);
  }
};

//This function checks for duplicate file. If file in uploaded file list then it ignores it.
function checkDuplicateFiles(uploadingFile, uploadedFiles) {
  const result = uploadedFiles.some((uploadFileItem, index) => {
    if (uploadFileItem.file.file === uploadingFile.file) {
      return true;
    } else {
      return false;
    }
  });
  return result;
}
//This is the parent function which will add the file one by one in the queue to upload and will wait for the file to be uploaded.
const filesUploader = async (
  montage_id,
  selectedFilesList,
  setSelectedFilesList,
  uploadedFiles,
  setUploadedFiles,
  filesProgress,
  setFilesProgress,
  processing,
  setProcessing,
  filesUploading,
  setFilesUploading,
  notifyCtx,
  navigate,
  axiosInstance
) => {
  if (processing) {
    return;
  }
  setProcessing(true);
  if (filesUploading === false) {
    setFilesUploading(true); //only for fileupload_dialog component this filesUploading state will be available.
  }
  for (let fileItem = 0; fileItem < selectedFilesList.length; fileItem++) {
    if (!checkDuplicateFiles(selectedFilesList[fileItem], uploadedFiles)) {
      await uploadFile(
        montage_id,
        selectedFilesList[fileItem],
        selectedFilesList[fileItem].fileIndex,
        setUploadedFiles,
        selectedFilesList,
        setSelectedFilesList,
        filesProgress,
        setFilesProgress,
        notifyCtx,
        navigate,
        axiosInstance
      );
    }
  }
  setProcessing(false);
  if (filesUploading === false) {
    setFilesUploading(false); //only for fileupload_dialog component this filesUploading state will be available.
  }
};

///////file item - the invdividual file upload component//////
const FileItem = memo(({ file, name, size, filesProgresss, uploaded }) => {
  return (
    <Paper
      sx={{ p: "20px 27px", borderBottom: "1px solid #D2D2D2" }}
      elevation={0}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box>
          <SmallFileIconSvg sx={{ mr: "18px", width: "14px" }} />
          <Typography
            component="span"
            sx={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "23px",
              color: "#0F0F0F",
              mr: "22px",
            }}
            title={name}
          >
            {name.length > 30 ? name.slice(0, 30) + "..." : name}
          </Typography>

          <Typography
            component="span"
            sx={{
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "23px",

              color: "#8A8A8A",
            }}
          >
            {size < 10 ** 5
              ? (size * 0.000977).toFixed(2) + "Kb"
              : (size * 0.00000095).toFixed(2) + "Mb"}
          </Typography>
        </Box>
        <Box
          minWidth="50%"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
        >
          <LinearProgress
            sx={{
              "& .MuiLinearProgress-bar": {
                background: filesProgresss
                  ? filesProgresss[file.fileIndex].progress &&
                    filesProgresss[file.fileIndex].progress === 100
                    ? "#10A44B"
                    : "#2CA9E3"
                  : "#2CA9E3",
              },
              borderRadius: "41px",
              height: "7px",
              minWidth: "80%",
              mr: "1rem",
            }}
            variant="determinate"
            value={
              filesProgresss[file.fileIndex].progress
                ? filesProgresss[file.fileIndex].progress
                : 0
            }
          />
          <Box sx={{ minWidth: "20%" }}>
            <Status
              status={
                uploaded
                  ? file?.anonymized === "anonymized"
                    ? "anonymized"
                    : file?.anonymized === "failed"
                    ? "failed"
                    : "anonymizing"
                  : "uploading"
              }
            />
          </Box>
        </Box>
        {/* <Box minWidth="10%" textAlign={"right"}>
          <CloseIcon
            size="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleFileDelete(call_id.current, notifyCtx);
            }}
          />
        </Box> */}
      </Stack>
    </Paper>
  );
});

//This function will check if the file selected exist in uploaded file list, then function will make sure to not to show that file to the user. (Both files selected and uploaded showed to the user. Once uploaded, that file will be filtered by this function exclude from showing.)
function filterSelectedFiles(
  selectedFiles,
  uploadedFiles,
  filesProgress,
  montageInfo,
  setSelectedFilesList,
  setUploadedFiles
) {
  const filteredUploadedFiles = [];
  selectedFiles.map((sFile, ind) => {
    if (
      !uploadedFiles.find((uFile, index) => {
        return uFile.file.file === sFile.file;
      })
    ) {
      filteredUploadedFiles.push(sFile);
    }
    return sFile;
  });
  return filteredUploadedFiles.map((file, index) => {
    return (
      <FileItem
        key={index}
        file={file}
        montageInfo={montageInfo}
        name={file.file.name}
        size={file.file.size}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        selectedFilesList={selectedFiles}
        filesProgresss={filesProgress}
        // handleFileDelete={handleFileDelete}
      />
    );
  });
}
//Parent component which renders the files (Selected and uploaded) and triggers the filesUploader function.
const FileItemHandler = memo(
  ({
    selectedFilesList,
    setSelectedFilesList,
    uploadedFiles,
    setUploadedFiles,
    filesProgress,
    setFilesProgress,
    filesUploading,
    setFilesUploading,
    axiosInstance,
  }) => {
    const notifyCtx = useContext(notifyContext);
    const navigate = useNavigate();
    const [montageInfo] = useState(
      JSON.parse(
        localStorage.getItem("current_montage")
          ? localStorage.getItem("current_montage")
          : ""
      )
    );
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
      (async () => {
        if (montageInfo) {
          await filesUploader(
            montageInfo.id,
            selectedFilesList,
            setSelectedFilesList,
            uploadedFiles,
            setUploadedFiles,
            filesProgress,
            setFilesProgress,
            processing,
            setProcessing,
            filesUploading,
            setFilesUploading,
            notifyCtx,
            navigate,
            axiosInstance
          );
        } else {
          notificationsHandler(
            notifyCtx,
            "error",
            "Montage Info not found. Try refreshing the page"
          );
        }
      })();
    }, [selectedFilesList, processing]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
      <>
        {montageInfo ? (
          selectedFilesList.length > 0 ? (
            filterSelectedFiles(
              selectedFilesList,
              uploadedFiles,
              filesProgress,
              montageInfo,
              setSelectedFilesList,
              setUploadedFiles
            )
          ) : (
            <></> //No uploaded files
          )
        ) : (
          // <>Montag info not found</>
          <></>
        )}
        {montageInfo ? (
          uploadedFiles.length > 0 ? (
            uploadedFiles.map((file, index) => {
              return (
                <FileItem
                  key={index}
                  file={file}
                  montageInfo={montageInfo}
                  name={file.file.file.name}
                  size={file.file.file.size}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  selectedFilesList={selectedFilesList}
                  filesProgresss={filesProgress}
                  uploaded={true}

                  // handleFileDelete={handleFileDelete}
                />
              );
            })
          ) : (
            <></> //No uploaded files
          )
        ) : (
          // <>Montag info not found</>
          <></>
        )}
      </>
    );
  }
);

//The Top level component
function AfterFileDrop(props) {
  const [dropAreaColor] = useState("#fff");
  const [montageInfo, setMontageInfo] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const notifyCtx = useContext(notifyContext);
  const socketCtx = useContext(socketContext);
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const navigate = useNavigate();

  //useEffect for listening to socket messages
  useEffect(() => {
    if (
      "type" in socketCtx.message &&
      socketCtx.message.type === "anonymize.success"
    ) {
      setUploadedFiles((prevUploadedFiles) => {
        const updatedState = prevUploadedFiles.map((prevUploadedFile, ind) => {
          if (prevUploadedFile.call_id === socketCtx.message.data.call.id) {
            return { ...prevUploadedFile, anonymized: "anonymized" };
          }
          return prevUploadedFile;
        });
        return updatedState;
      });
    } else if (
      "type" in socketCtx.message &&
      socketCtx.message.type === "anonymize.fail"
    ) {
      setUploadedFiles((prevUploadedFiles) => {
        const updatedState = prevUploadedFiles.map((prevUploadedFile, ind) => {
          if (prevUploadedFile.call_id === socketCtx.message.data.call.id) {
            return { ...prevUploadedFile, anonymized: "failed" };
          }
          return prevUploadedFile;
        });
        return updatedState;
      });
    }
  }, [socketCtx.message]);

  //for extracting the montage info on mount
  useEffect(() => {
    const montage_info = JSON.parse(
      localStorage.getItem("current_montage")
        ? localStorage.getItem("current_montage")
        : ""
    );
    if (montage_info) {
      setMontageInfo(montage_info);
    } else {
      notificationsHandler(notifyCtx, "error", "Montage Info Not Found");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //refreshing the page (for call list page)
  useEffect(() => {
    if (props.selectedFilesList.length === uploadedFiles.length) {
      if (props.updateCalls) {
        //updating calls - refreshing the page (for call list page)
        props.setFilesUploading(false);
        navigate(0);
      }

      if (!props.disableSetNext) {
        //disableSetNext props is used when afterfiledrop is used in fileupload pop up component inside a montage
        /*If this is true then don't set Disablenext. Set disableNext is used to disable next button on stepper control*/
        if (
          uploadedFiles.every((uploadedFile, index) => {
            if (uploadedFile.anonymized === "anonymized") {
              return true;
            }
            return false;
          })
        ) {
          props.setDisableNext(false);
        }
      }
      // notificationsHandler(notifyCtx, "success", "Files Uploaded Successfully");
    } else {
      if (!props.disableSetNext) {
        props.setDisableNext(true);
      }
    }
  }, [uploadedFiles, props.selectedFilesList]); // eslint-disable-line react-hooks/exhaustive-deps

  const Input = styled("input")({
    display: "none",
  });

  return (
    <Paper
      sx={{
        height: "100%",
        minHeight: "300px",
        background: dropAreaColor,
        mb: "8rem",
        position: "relative",
      }}
      elevation={0}
      className="AfterFileUpload-Component"
    >
      <Stack
        sx={{
          p: "16px",
          width: "100%",
          backgroundColor: "#fff",
          // border: "1px solid black",
          position: "sticky",
          zIndex: "4000",
          top: 0,
        }}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Box sx={{ mr: "24px" }}>
            <label htmlFor="contained-button-file">
              <Input
                accept=".edf"
                id="contained-button-file"
                multiple
                type="file"
                onChange={(e) => props.getFile(e)}
              />
              <Button
                disableElevation
                variant="contained"
                component="span"
                sx={{
                  background: "#EAEAEA",
                  color: "#6D6D6D",
                  ":hover": {
                    background: "#d9d8d8",
                  },
                  px: "10px",
                  py: "8px",
                  textTransform: "capitalize",
                }}
              >
                Add more files
              </Button>
            </label>
          </Box>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "21px",

              color: "#0F0F0",
            }}
          >
            or drop files here
          </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "21px",

            color: "#8A8A8A",
          }}
        ></Typography>
      </Stack>
      <FileItemHandler
        selectedFilesList={props.selectedFilesList}
        setSelectedFilesList={props.setSelectedFilesList}
        montageInfo={montageInfo}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        filesProgress={props.filesProgress}
        setFilesProgress={props.setFilesProgress}
        filesUploading={props.filesUploading}
        setFilesUploading={props.setFilesUploading}
        axiosInstance={axiosInstance}
      />
    </Paper>
  );
}

export default memo(AfterFileDrop);
