// material ui modules
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";
// import { CssBaseline  } from "@mui/material";

// material ui icons
import MenuIcon from "@mui/icons-material/Menu";
import useAuth from "../hooks/useAuth";

// custom compoents
import DropDown from "./DropDown";
//react
import { useContext } from "react";
import { globalContext } from "../context/globalContext";

// NavBar Component: Navbar for the application
export default function NavBar({ drawerWidth, handleDrawerToggle, height }) {
  const [auth] = useAuth();
  const { pageTitle } = useContext(globalContext);

  // const location = useLocation();

  return (
    <Box sx={{ height: `${height}px` }} className="Navbar-Component">
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: "#F5F6FA",
          color: "#022539",
          height: "90px",
        }}
        position="static"
      >
        <Toolbar
          sx={{
            pt: { xs: 0, sm: 4 },
            pb: { xs: 0, sm: 3 },
            width: "99%",
            margin: "auto",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { xs: "block", lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h5"
            fontWeight="600"
            component="div"
            sx={{
              flexGrow: 1,
              marginLeft: { xs: 0, lg: "0.5rem" },
              textTransform: "capitalize",
              fontSize: "24px",
              maxWidth: "200px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            title={pageTitle}
          >
            {/* {genPageTitle(location.pathname)} */}
            {pageTitle}
          </Typography>
          <Box component="div" sx={{ marginLeft: "auto" }}>
            <DropDown
              btnTitle={`${auth?.user?.first_name} ${auth?.user?.last_name}`}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
