import { useContext } from "react";
import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { notifyContext } from "../../context/notifyContext";
//sounds
// import NotificationSound from "../../assets/sounds/notify.mp3";

export default function Notify(props) {
  // const [open, setOpen] = useState(false);
  const ctx = useContext(notifyContext);

  const handleClose = () => {
    ctx.setNotify((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };
  // const audio = new Audio(NotificationSound);
  // useEffect(() => {
  //   audio.play();
  // }, [ctx.notify.open]);

  return (
    <Snackbar
      open={ctx.notify.open}
      anchorOrigin={{
        vertical: props.vertical || "top",
        horizontal: props.horizontal || "center",
      }}
      autoHideDuration={3000}
      onClose={handleClose}
      sx={{ fontFamily: "Inter, sans-serif" }}
      // action={ctx.notify.action}
    >
      <Alert
        onClose={handleClose}
        severity={
          ctx.notify.severity || "error"
        } /* success, error, warning, info */
        sx={{ width: "100%", fontFamily: "Inter, sans-serif" }}
      >
        {ctx.notify.message}
      </Alert>
    </Snackbar>
  );
}
