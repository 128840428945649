////////////React///////////////
import { useState, useEffect, useContext } from "react";
//libs
import { Navigate } from "react-router-dom";

////////////////Hooks////////////
import useAuth from "../hooks/useAuth";

/////////////Utils and helper//////////////
import { axiosConfig } from "../utils/helpers";
import urlpatterns from "../utils/urls.json";
import { httpErrorHandler, notificationsHandler } from "../utils/helpers";

////////////form/////////
import SigninForm from "../components/forms/signinForm";

////////////Internal///////
// import Error from "../components/helpers/error";
///////////////context//////////

import { notifyContext } from "../context/notifyContext";
import { globalContext } from "../context/globalContext";

//3rd party
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function SignIn() {
  const [auth, dispatch] = useAuth();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const notifyCtx = useContext(notifyContext);
  const globalCtx = useContext(globalContext);

  const handleSubmit = async (email, password) => {
    setLoading(true);
    const config = axiosConfig({
      method: "POST",
      uri: "/auth/login/",
      data: { email, password },
      withCredentials: true,
    });

    try {
      const response = await axios({
        ...config,
      });
      setLoading(false);
      return response;
    } catch (error) {
      httpErrorHandler(error, notifyCtx, navigate);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.authenticated && auth?.user?.type !== "CONSUMER") {
      dispatch({ type: "signout", error: null, globalCtx: globalCtx });
      notificationsHandler(
        notifyCtx,
        "error",
        "Please login with correct platform credentials"
      );
    }
  }, [auth.authenticated, auth?.user?.type, notifyCtx, dispatch, globalCtx]);

  return (
    <>
      {auth.authenticated && auth?.user?.type === "CONSUMER" ? (
        <Navigate to={urlpatterns.dashboard} />
      ) : (
        <SigninForm loading={loading} handleSubmit={handleSubmit} />
      )}
    </>
  );
}
