////////////////React//////////////
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import PropTypes from "prop-types";
///////////////MUI///////////////
import { Box, Tab, Tabs } from "@mui/material";

///////////////Internal Components/////////////

import TotalCredits from "../components/credits/TotalCredits";
import PageLoader from "../components/loader/PageLoader";
///////////////HOc////////////
import AuthHOC from "../hoc/AuthHOC";
//////////////Helpers and utils/////////////
import { axiosConfig } from "../utils/helpers";
import { httpErrorHandler } from "../utils/helpers";
///////////////Context////////////
import { notifyContext } from "../context/notifyContext";
import { useNavigate } from "react-router-dom";

//hooks
import useAxios from "../hooks/useAxios";
import { globalContext } from "../context/globalContext";
import ServicesInfo from "../components/services/ServicesInfo";
////////Main Code////////////

/////////Tab component///////////
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Credits({ user, dispatch, ...props }) {
  const { setPageTitle } = useContext(globalContext);
  const [value, setValue] = useState(0);
  const [totalCreditsUsed, setTotalCreditsUsed] = useState(0);
  const [totalCreditsAvailable, setTotalCreditsAvailable] = useState(0);
  const [totalCreditsPurchased, setTotalCreditsPurchased] = useState(0);
  const [creditsData, setCreditsData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [creditsPage, setCreditsPage] = useState(1);
  const [fetchingCredits, setFetchingCredits] = useState(false);
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const [loader, setLoader] = useState({
    loading: false,
    message: "Loading",
  });

  const notifyCtx = useContext(notifyContext);

  const PAGE_SIZE = 10;

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //call to fetch credits info
  const fetchCredits = useCallback(
    async (abortController) => {
      try {
        setFetchingCredits(true);
        const config = axiosConfig({
          method: "GET",
          uri: `/credits/`,
          params: {
            page: creditsPage,
            page_size: PAGE_SIZE,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setFetchingCredits(false);
        setCreditsData(response.data);
      } catch (error) {
        if (error.message !== "canceled") setFetchingCredits(false);
        httpErrorHandler(error, notifyCtx, navigate);
      }
    },
    [creditsPage] // eslint-disable-line react-hooks/exhaustive-deps
  );
  useEffect(() => {
    setPageTitle("Credits Info");
    const abortController = new AbortController();
    (async () => {
      await fetchCredits(abortController);
    })();

    return () => {
      abortController.abort();
    };
  }, [fetchCredits, setPageTitle]);

  //useEffect for fetching available and used credits (credits/summary)
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      setLoader((previousState) => {
        return { ...previousState, loading: true };
      });
      try {
        const config = axiosConfig({ method: "GET", uri: "/credits/summary" });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setTotalCreditsAvailable(response.data.available);
        setTotalCreditsUsed(response.data.used);
        setTotalCreditsPurchased(response.data.used + response.data.available);
        setLoader((previousState) => {
          return { ...previousState, loading: false };
        });
      } catch (error) {
        if (error.message !== "canceled")
          setLoader((previousState) => {
            return { ...previousState, loading: false };
          });
        httpErrorHandler(error, notifyCtx, navigate);
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [navigate, notifyCtx]);

  //fetch services info
  useEffect(() => {
    const abortController = new AbortController();
    const getData = async () => {
      setLoader((previousState) => {
        return { ...previousState, loading: true };
      });
      try {
        const config = axiosConfig({
          uri: "/accounts/consumer/services/",
          method: "GET",
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setServicesData(response.data);
      } catch (error) {
        httpErrorHandler(error, notifyCtx, navigate);
      }
      setLoader((previousState) => {
        return { ...previousState, loading: false };
      });
    };
    getData();
    return () => {
      abortController.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {fetchingCredits || loader.loading ? (
        <PageLoader
          open={loader.loading || fetchingCredits}
          message={loader.message}
        />
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: " #F5F6FA",
            px: "40px",
            mb: "1rem",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "#C4C4C4" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  textTransform: "none",
                  mr: "40px",
                  px: 0,
                }}
                label="Total Credits Overview"
                {...a11yProps(0)}
              />

              <Tab
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  textTransform: "none",
                  mr: "40px",
                  px: 0,
                }}
                label="Services - Credits Info"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <TotalCredits
              data={creditsData}
              totalCreditsUsed={totalCreditsUsed}
              totalCreditsAvailable={totalCreditsAvailable}
              totalCreditsPurchased={totalCreditsPurchased}
              page={creditsPage}
              setPage={setCreditsPage}
              loading={fetchingCredits}
              PAGE_SIZE={PAGE_SIZE}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <ServicesInfo servicesData={servicesData} />
          </TabPanel>
        </Box>
      )}
    </>
  );
}

export default AuthHOC(Credits);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
