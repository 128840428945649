//mui
import { Stack, Typography } from "@mui/material";
// Additional Settings Preview Component
export default function AdditionalSettings({ data, ...props }) {
  let additionalSettings = JSON.parse(
    localStorage.getItem("additional_settings")
  );

  return (
    <Stack
      style={{
        backgroundColor: "#FFF",
        padding: "41px 56px",
      }}
      direction="row"
      spacing={2}
    >
      <Typography
        align="center"
        sx={{ fontSize: "18px", fontWeight: "500", lineHeight: "22px" }}
        color="#8A8A8A"
      >
        Additional Settings:
      </Typography>
      <Typography
        align="center"
        sx={{ fontSize: "18px", fontWeight: "500", lineHeight: "22px" }}
      >
        {data ? data?.join(",") : additionalSettings?.join(", ")}
      </Typography>
    </Stack>
  );
}
