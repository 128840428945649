//react
import { useState } from "react";
//mui
import Paper from "@mui/material/Paper";
//3rd party
import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";

import {
  HoverState,
  EventTracker,
  Animation,
} from "@devexpress/dx-react-chart";

const tooltipContentTitleStyle = {
  fontWeight: "bold",
  paddingBottom: 0,
};
const tooltipContentBodyStyle = {
  paddingTop: 0,
};

const TooltipContent = (props) => {
  const { targetItem, text, ...restProps } = props;
  return (
    <div>
      <div>
        <Tooltip.Content
          {...restProps}
          style={tooltipContentTitleStyle}
          text={text}
        />
      </div>
      <div>
        <Tooltip.Content
          {...restProps}
          style={tooltipContentBodyStyle}
          text="Credits Used"
        />
      </div>
    </div>
  );
};

const createDate = (datestring) => {
  const options = {
    day: "numeric",
    month: "short",
  };

  const date = new Intl.DateTimeFormat("en-GB", options).format(
    new Date(datestring)
  );
  return date;
};
function createGraphData(data) {
  const graphData = data.map((element, index) => {
    createDate(element.created_on__date);
    return {
      date: createDate(element.created_on__date),
      credits: element.credits_used,
    };
  });

  return graphData;
}
// const labelStyle = { fill: "#BBDEFB" };
// const getPath = (x, width, y, y1) => {
//   return `M ${x} ${y1}
//    L ${width + x} ${y1}
//    L ${width + x} ${y + 30}
//    L ${x + width / 2} ${y}
//    L ${x} ${y + 30}
//    Z`;
// };
// const BarWithLabel = ({
//   arg,
//   barWidth,
//   maxBarWidth,
//   val,
//   startVal,
//   color,
//   value,
//   style,
// }) => {
//   let width = maxBarWidth * barWidth > 30 ? 30 : maxBarWidth * barWidth;

//   return (
//     <>
//       {/* <path
//         d={getPath(arg - width / 2, width, val, startVal)}
//         fill={color}
//         style={style}
//       /> */}
//       <rect
//         x={arg - width / 2}
//         y={val}
//         rx="100"
//         ry="20"
//         width={width}
//         height={startVal - val}
//         fill="#42A5F5"
//         visibility="visible"
//       ></rect>
//       {/* <Chart.Label
//         x={arg}
//         y={(val + startVal) / 2}
//         dominantBaseline="middle"
//         textAnchor="middle"
//         style={labelStyle}
//       >
//         {value}
//       </Chart.Label> */}
//     </>
//   );
// };

export default function CreditsChart(props) {
  const [graphData] = useState(
    props.graphData.length ? createGraphData(props.graphData) : []
  );
  const [tooltipEnabled] = useState(true);
  const [tooltipTarget, setTooltipTarget] = useState(null);
  const [hover, setHover] = useState(null);

  const changeHover = (hover) => {
    setHover(hover);
  };
  const changeTooltip = (targetItem) => {
    setTooltipTarget(targetItem);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        position: "relative",
        minHeight: "415px",
        "& > .Target-root": {
          position: "absolute",
          left: 0,
          width: "100%",
          height: "100% !important",
        },
      }}
    >
      <Chart data={graphData}>
        <ArgumentAxis />
        <ValueAxis max={7} />
        {/* <BarSeries
          valueField="credits"
          argumentField="date"
          pointComponent={BarWithLabel}
          cornerRadius={5}
        /> */}
        <BarSeries valueField="credits" argumentField="date" barWidth={0.2} />
        <Animation />
        <EventTracker />
        <HoverState hover={hover} onHoverChange={changeHover} />
        <Tooltip
          targetItem={tooltipEnabled && tooltipTarget}
          onTargetItemChange={changeTooltip}
          contentComponent={TooltipContent}
          style={{ background: "#F5F6FA" }}
        />
      </Chart>
    </Paper>
  );
}
