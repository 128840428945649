///MUI////
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

function PageLoader(props) {
  return (
    <Paper
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: props.open ? "flex" : "none",
        p: "16px",
      }}
      elevation={0}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress sx={{ color: "#2F7EC7" }} />
        <Typography sx={{ mt: ".8rem" }}>{props.message}</Typography>
      </Box>
    </Paper>
  );
}

export default PageLoader;

PageLoader.defaultProps = {
  message: "Loading",
  open: false,
  loading: true,
};
