/////////////////////////React///////////////////////////
import { useState, useEffect, useRef } from "react";

/////////////////MUI/////////////////////////////i
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

//////////////////Internal Components//////////////////
import Status from "../components/status/Status";
import SupervisorInfo from "../components/profile/SupervisorInfo";
import Btn from "../components/buttons/Btn";
import ProgressStatus from "../components/progress_status/ProgressStatus";
import PageLoader from "../components/loader/PageLoader";
import SingleFileDownloadPopup from "../components/montages/SingleFileDownloadPopup";
import CustomServicesCell from "../components/custom_service";
////////////////Third Party//////////////
//hooks
import useAxios from "../hooks/useAxios";
import { useNavigate, useParams } from "react-router-dom";
////////////Hoc//////////////////
import AuthHOC from "../hoc/AuthHOC";

//////Utils and Helpers///////////
import {
  getDate,
  getTime,
  httpErrorHandler,
  downloadCalls,
} from "../utils/helpers";
import { axiosConfig } from "../utils/helpers";

// Context
import { useContext } from "react";
import { notifyContext } from "../context/notifyContext";
import { globalContext } from "../context/globalContext";
//libs
import { saveAs } from "file-saver";
import axios from "axios";
const zip = require("jszip")();

///////custom table header cell////////////
const TableCellHeader = styled(TableCell)({
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "21px",
  color: "#888888",
  border: 0,
});

///////custom table body cell////////////
const TableCellBody = styled(TableCell)({
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "21px",
  color: "#0F0F0F",
  border: 0,
});

//////////////////Main code////////////////

///////////////Call Info //////////////////////
const CallInfoInner = ({
  callId,
  edfInfo,
  supervisorInfo,
  status,
  axiosInstance,
  notifyCtx,
  navigate,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [isGettingLogs, setIsGettingLogs] = useState(false);
  const handleLogsDownload = async () => {
    try {
      setIsGettingLogs(true);
      const config = axiosConfig({
        method: "POST",
        data: {
          call_id: callId,
        },
        uri: `/calls/download-logs`,
      });

      const response = await axiosInstance.current({
        ...config,
      });
      //saving file urls
      const fileUrls = response.data;

      for (let fileIndex = 0; fileIndex < response.data?.length; fileIndex++) {
        const response = await axios({
          url: fileUrls[fileIndex].url,
          method: "GET",
          responseType: "blob",
        });
        zip.file(`call-log-${fileIndex + 1}.txt`, response.data);
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `Call-${callId}.zip`);
      });
      setIsGettingLogs(false);
    } catch (err) {
      if (err.message !== "canceled") setIsGettingLogs(false);
      httpErrorHandler(err, notifyCtx, navigate);
    }
  };
  return (
    <Box
      width="100%"
      px="40px"
      sx={{ cursor: isGettingLogs ? "progress" : "initial" }}
    >
      <Stack alignItems="flex-end" sx={{ mb: "36px", width: "100%" }}>
        <Box>
          <Btn
            disabled={status === "processed" ? false : true}
            title="Download Report"
            startIcon={<FileDownloadOutlinedIcon />}
            color="#ffffff"
            bg={status === "processed" ? "#10A44B" : "#C4C4C4"}
            hover={status === "processed" ? "#0e8b40" : ""}
            sx={{ py: "12px", px: "14px" }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setOpen(true);
            }}
          ></Btn>
          {anchorEl ? (
            <SingleFileDownloadPopup
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              open={open}
              setOpen={setOpen}
              downloadCalls={downloadCalls}
              callId={callId}
            />
          ) : (
            <> </>
          )}
        </Box>
      </Stack>
      <Stack sx={{ mb: "40px", width: "100%" }}>
        <Paper elevation={0} sx={{ mb: "32px" }}>
          <Grid container>
            <Grid item xs={12} sx={{ overflow: "hidden" }}>
              <Stack
                sx={{
                  p: "24px 24px 24px",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Typography
                    component="h5"
                    sx={{
                      fontWeight: "500",
                      fontSize: "20px",
                      lineHeight: "26px",
                      /* identical to box height */
                      mb: "8px",
                      color: "#0F0F0F",
                    }}
                  >
                    Call Info
                  </Typography>
                  <Typography
                    component="p"
                    sx={{
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "18px",
                      /* identical to box height */
                      color: "#898989",
                    }}
                  >
                    View which service requires how much credits for processing
                  </Typography>
                </Box>
                <Btn
                  onClick={handleLogsDownload}
                  title="Download call logs"
                  color={"#2F7EC7"}
                  type="text"
                  startIcon={<FileDownloadOutlinedIcon />}
                  disabled={
                    (status === "error" || status === "warning"
                      ? false
                      : true) || isGettingLogs
                  }
                  sx={{ px: "20px" }}
                />
              </Stack>
              <TableContainer sx={{ width: "100%" }}>
                <Table sx={{}}>
                  <TableHead
                    sx={{ background: "#F9F9F9", borderColor: "transparent" }}
                  >
                    <TableRow>
                      <TableCellHeader>Credits Used</TableCellHeader>
                      <TableCellHeader>Montage Name</TableCellHeader>
                      <TableCellHeader>Edf Name</TableCellHeader>
                      <TableCellHeader>Status</TableCellHeader>
                      <TableCellHeader>Service Used</TableCellHeader>
                      <TableCellHeader>Started At</TableCellHeader>
                      <TableCellHeader>Ended At</TableCellHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCellBody>
                        {edfInfo?.credit_used
                          ? parseFloat(edfInfo?.credit_used).toFixed(2)
                          : 0}
                      </TableCellBody>
                      <TableCellBody>{edfInfo?.montage?.title}</TableCellBody>
                      <TableCellBody>
                        <span title={edfInfo?.file_name}>
                          {edfInfo?.file_name.length > 15
                            ? edfInfo?.file_name?.slice(0, 15) + "..."
                            : edfInfo?.file_name}
                        </span>
                      </TableCellBody>
                      <TableCellBody sx={{ minWidth: "150px" }}>
                        <Status status={edfInfo?.status} />
                      </TableCellBody>
                      <TableCellBody>
                        <CustomServicesCell
                          data={edfInfo}
                          sx={{ justifyContent: "flex-start" }}
                        />
                      </TableCellBody>

                      <TableCellBody>
                        {getDate(edfInfo.time_call_started)}
                        <br />
                        {getTime(edfInfo.time_call_started)}
                      </TableCellBody>
                      <TableCellBody>
                        {getDate(edfInfo.time_call_ended)}
                        <br />
                        {getTime(edfInfo.time_call_ended)}
                      </TableCellBody>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
        <SupervisorInfo supervisorData={supervisorInfo}></SupervisorInfo>
      </Stack>
    </Box>
  );
};

function CallInfo({ user, dispatch, ...props }) {
  const { callId } = useParams();
  const [callInfo, setCallInfo] = useState([]);
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const { setPageTitle } = useContext(globalContext);

  const [supervisorInfo, setSupervisorInfo] = useState(
    localStorage.getItem("sp_info")
      ? JSON.parse(localStorage.getItem("sp_info"))
      : false
  );
  const montageInfo = JSON.parse(localStorage.getItem("current_montage"));

  const [loader, setLoader] = useState({ loading: false, message: "Loading" });
  const breadcrumbs = [
    <Link key="1" to="/montages">
      <Typography
        sx={{ color: "#969696", fontSize: "18px", fontWeight: "500" }}
      >
        Montages
      </Typography>
    </Link>,
    <Link key="2" to={`/montages/view/${montageInfo.title}/${montageInfo.id}`}>
      <Typography
        sx={{ color: "#969696", fontSize: "18px", fontWeight: "500" }}
      >
        Montage View
      </Typography>
    </Link>,

    <Typography
      key="2"
      sx={{ color: "#969696", fontSize: "18px", fontWeight: "500" }}
    >
      Details
    </Typography>,
  ];
  const notifyCtx = useContext(notifyContext);
  const navigate = useNavigate();

  /////Gets the supervisor info if the data is not cached///////////////
  useEffect(() => {
    const abortController = new AbortController();

    (async () => {
      const getSupervisorData = async () => {
        try {
          const config = axiosConfig({
            uri: `/accounts/consumer/`,
            method: "GET",
          });
          const response = await axiosInstance.current({
            ...config,
            signal: abortController.signal,
          });
          setSupervisorInfo(response.data.supervisor);
        } catch (error) {
          httpErrorHandler(error, notifyCtx, navigate);
        }
      };
      const getCallInfo = async () => {
        try {
          const config = axiosConfig({
            uri: `/calls/${callId}`,
            method: "GET",
          });
          const response = await axiosInstance.current({
            ...config,
            signal: abortController.signal,
          });
          setPageTitle(
            response.data?.montage?.title
              ? response.data?.montage?.title
              : "Call Info"
          );
          setCallInfo(response.data);
        } catch (error) {
          httpErrorHandler(error, notifyCtx, navigate);
        }
      };
      setLoader({ loading: true, message: "Loading" });
      if (!supervisorInfo) {
        await getSupervisorData();
      }
      await getCallInfo();
      setLoader({ loading: false, message: "Loading" });
    })();
    return () => {
      abortController.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Gets the call detailed info//////////////

  return (
    <>
      <ProgressStatus />
      {loader.loading ? (
        <PageLoader open={loader.loading} message={loader.message} />
      ) : callInfo.id !== undefined ? (
        <>
          {/* START: Breadcrumb */}
          <Stack direction="row" sx={{ width: "100%", px: "40px" }}>
            <Breadcrumbs
              separator={
                <NavigateNextIcon
                  fontSize="small"
                  style={{ color: "#969696" }}
                />
              }
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
          {/* END: Breadcrumb */}
          <CallInfoInner
            edfInfo={callInfo}
            status={callInfo ? callInfo.status : ""}
            supervisorInfo={supervisorInfo}
            callId={callId}
            axiosInstance={axiosInstance}
            notifyCtx={notifyCtx}
            navigate={navigate}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default AuthHOC(CallInfo);
