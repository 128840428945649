import CustomServicesCell from "../../custom_service";
//utils
import { getDate } from "../../../utils/helpers";

export const usageTableCols = [
  {
    name: "Credits Used",
    accessor: "root",
    getAccessor: (el) => {
      return el?.credit_used ? parseFloat(el.credit_used).toFixed(2) : "-";
    },
  },
  {
    name: "Montage Name",
    accessor: "root",
    getAccessor: (el) => {
      return el?.montage?.title;
    },
  },
  {
    name: "EDF Name",
    accessor: "root",
    getAccessor: (el) => {
      return el?.file_name;
    },
    title: (el) => el?.file_name,
    // sx: {
    //   rowCell: {
    //     maxWidth: "100px",
    //     textOverflow: "ellipsis",
    //     whiteSpace: "nowrap",
    //     overflow: "hidden",
    //   },
    // },
  },
  {
    name: "Service Used",
    component: CustomServicesCell,
  },

  {
    name: "Date",
    accessor: "root",
    getAccessor: (el) => {
      return getDate(el.created_on);
    },
  },
];
