// react
import { useState, useContext, useEffect, useCallback, useRef } from "react";

//3rd party
import { Outlet, useNavigate } from "react-router-dom";

// material ui modules
import Box from "@mui/material/Box";

// Hoc
import AuthHoc from "../hoc/AuthHOC";
//hooks
import useAxios from "../hooks/useAxios";

// internal components
import NavBar from "./NavBar";
import SideBar from "./SideBar";

// context
import { globalContext } from "../context/globalContext";
import { notifyContext } from "../context/notifyContext";
import { SocketProvider } from "../context/socketContext";
//hooks

//utils and helpers
import { axiosConfig, httpErrorHandler } from "../utils/helpers";

export const DRAWER_WIDTH = 300;

// Layout Component: Defines the structure of the application
function Layout() {
  const [open, setOpen] = useState(true);
  const globalCtx = useContext(globalContext);
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const notifyCtx = useContext(notifyContext);
  const navigate = useNavigate();

  const getSidebarCredits = useCallback(async () => {
    globalCtx.setSidebarCredits({
      loading: true,
      available: 0,
      used: 0,
    });
    try {
      const config = axiosConfig({ method: "GET", uri: "/credits/summary" });
      const response = await axiosInstance.current({
        ...config,
      });

      if ("available" in response.data && response.data.available) {
        globalCtx.setSidebarCredits({
          loading: false,
          available: response.data.available,
          used: response.data.used,
          error: false,
        });
      } else {
        globalCtx.setSidebarCredits({
          loading: false,
          available: 0,
          used: 0,
          error: false,
        });
      }
    } catch (error) {
      globalCtx.setSidebarCredits({
        loading: false,
        value: 0,
        error: true,
      });
      httpErrorHandler(error, notifyCtx, navigate);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getSidebarCredits();
  }, [getSidebarCredits]);

  return (
    <SocketProvider>
      <Box sx={{ display: "flex" }} className="Layout-Component">
        <SideBar
          drawerWidth={DRAWER_WIDTH}
          open={open}
          setOpen={setOpen}
          getSidebarCredits={() => {
            getSidebarCredits();
          }}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { lg: `calc(100% - ${DRAWER_WIDTH}px)` },
            position: "relative",
          }}
          className="Layout-Main-Area"
        >
          <NavBar
            drawerWidth={DRAWER_WIDTH}
            handleDrawerToggle={() => setOpen(!open)}
            height={90}
          />
          <Box
            className="Layout-Main-Area-Body"
            sx={{ height: "calc(100% - 90px)" }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </SocketProvider>
  );
}

export default AuthHoc(Layout);
