//////////////Internal Components/////////
import Btn from "../buttons/Btn";
////////////MUI Components ////////////////
import { Paper, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
function StepperControl(props) {
  const navigate = useNavigate();
  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "40px",
      }}
      elevation={0}
      className="stepper-control"
    >
      <Box>
        <Btn
          onClick={props.handleBack}
          color="#fff"
          type="contained"
          title="Back"
          sx={{ mr: 2 }}
          disabled={props.activeStep === 0}
        />
        <Btn
          onClick={() => {
            navigate("/montages/");
          }}
          type="text"
          title="Cancel Montage"
          color="#000"
        />
      </Box>
      <Btn
        onClick={props.activeStep < 3 ? props.handleNext : props.handleDone}
        color="#fff"
        disabled={props.disableNext}
        type="contained"
        title={
          props.activeStep === 2
            ? "Save"
            : props.activeStep === 3
            ? "Done"
            : "Next"
        }
      />
    </Paper>
  );
}

export default StepperControl;
