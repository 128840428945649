// 3rd parth modules
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
//MUi
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
// custom hooks and components
import { AuthProvider } from "./context/authContext";
import Layout from "./components/Layout";
import Notify from "./components/helpers/notify";

// pages
import SignIn from "./pages/signIn";
import ResetPassword from "./pages/resetPassword";
import Credits from "./pages/credits";
import Profile from "./pages/profile";
import Montage from "./pages/montage";
import Stepper from "./components/stepper/Stepper";
import FileUpload from "./components/stepper/FileUpload";
import MapChannels from "./components/stepper/MapChannels";
import PreviewChannel from "./components/stepper/Preview/PreviewChannels";
import CallInfo from "./pages/callInfo";
import Dashboard from "./pages/dashboard";
import MontageDashboard from "./components/montages/MontageDashboard";
import CallList from "./pages/CallsList";
import Error4xx from "./components/errors/Error4xx";
import ForgotPassword from "./pages/forgotPassword";
import VerifyEmail from "./pages/verifyEmail";
import Usage from "./pages/Usage";
// utils
import urlpatterns from "./utils/urls.json";
// -----------------------Context-------------
import { GlobalContextProvider } from "./context/globalContext";
import { NotifyProvider } from "./context/notifyContext";
import ErrorPageHandler from "./components/errors/ErrorPageHandler";
import { useEffect } from "react";

//3rd party
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AppRoutes = () => {
  return (
    <GlobalContextProvider>
      <NotifyProvider>
        <AuthProvider>
          <Router>
            <Routes>
              <Route exact path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route exact path={urlpatterns.profile} element={<Profile />} />
                <Route exact path={urlpatterns.credits} element={<Credits />} />
                <Route
                  exact
                  path={urlpatterns.your_usage}
                  element={<Usage />}
                />
                <Route exact path={urlpatterns.montages} element={<Montage />}>
                  <Route index element={<MontageDashboard />} />
                  <Route
                    exact
                    path={urlpatterns.montage_create}
                    element={<Stepper />}
                  >
                    <Route index element={<FileUpload />}></Route>
                    <Route
                      exact
                      path={urlpatterns.montage_config}
                      element={<MapChannels />}
                    ></Route>
                    <Route
                      exact
                      path={urlpatterns.montage_preview}
                      element={<PreviewChannel />}
                    ></Route>
                  </Route>

                  <Route
                    exact
                    path={`${urlpatterns.montage_view}:montageName/:montageId`}
                    element={<CallList />}
                  />
                  <Route
                    exact
                    path={`${urlpatterns.call_info}`}
                    element={<CallInfo />}
                  />
                </Route>
              </Route>
              <Route
                path={urlpatterns.confirm_email}
                element={<VerifyEmail />}
              />
              <Route
                exact
                path={urlpatterns.forgot_password}
                element={<ForgotPassword />}
              />
              <Route
                exact
                path={urlpatterns.reset_password}
                element={<ResetPassword />}
              />
              <Route exact path={urlpatterns.signin} element={<SignIn />} />

              <Route exact path="*" element={<Error4xx />} />

              <Route
                exact
                path="/error/:error"
                element={<ErrorPageHandler />}
              />
            </Routes>
          </Router>
          {/* <Notify /> */}
        </AuthProvider>
      </NotifyProvider>
    </GlobalContextProvider>
  );
};

export default function App() {
  useEffect(() => {
    localStorage.removeItem("searchText");
  }, []);
  const matches = useMediaQuery("(max-width:1199px)");

  return (
    <>
      {navigator.cookieEnabled ? (
        <Box>
          {matches ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100vh" }}
            >
              <Typography
                component="h3"
                sx={{ fontWeight: "700", fontSize: "calc(.5rem + 3vw)" }}
              >
                Oops!! Device Not Supported!
              </Typography>
              <Typography
                component="h3"
                sx={{ fontWeight: "400", fontSize: "calc(.5rem + 1vw)" }}
              >
                This device resolution is not supported.
              </Typography>
            </Stack>
          ) : (
            <Box className="App-Root-Container">
              <AppRoutes />
              <ToastContainer
                limit={5}
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </Box>
          )}
        </Box>
      ) : (
        <Notify message="Cookies are not enabled! Please enable them, we use cookies for authentication" />
      )}
    </>
  );
}
