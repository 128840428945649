// ---------------------React Imports----------
import { useState, useContext, useRef } from "react";

// ---------------------MUI Imports----------------------
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
// import FormControl from "@mui/material/FormControl";
// ------------------Internal Components----------------
import Btn from "../buttons/Btn";
// -------------------Context-----------------------
import { globalContext } from "../../context/globalContext";
import { notifyContext } from "../../context/notifyContext";

//hooks
import useAxios from "../../hooks/useAxios";
// ----------------------3rd party Imports----------------------
import { useNavigate } from "react-router-dom";
//////////////Helpers and utils/////////////
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";
import Notify from "../helpers/notify";

export default function CreateMontage({ title }) {
  //////////Local states///////////////
  const context = useContext(globalContext);

  const [montageName, setMontageName] = useState("");
  const [created] = useState(false);
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const navigate = useNavigate();
  const notifyCtx = useContext(notifyContext);

  const submitRef = useRef();

  const montageNameSet = async () => {
    (async () => {
      context.setOpenProgressStatus(true);
      context.setProgressStatusMessage("Creating Montage");
      try {
        const config = axiosConfig({
          uri: "/montages/",
          method: "POST",
          data: { title: montageName },
        });
        const response = await axiosInstance.current({
          ...config,
        });
        localStorage.setItem("current_montage", JSON.stringify(response.data));
        localStorage.setItem("acs", 0);
        navigate(`/montages/${montageName}`);

        notificationsHandler(
          notifyCtx,
          "success",
          "Montage Created Successfully"
        );
      } catch (error) {
        httpErrorHandler(error, notifyCtx, navigate);
      }
      context.setOpenProgressStatus(false);
    })();
  };

  ///////Event Handler's and other utility function///////////////
  const handleChange = (event) => {
    setMontageName(event.target.value);
  };

  const verifyMontageName = (name) => {
    if (name.length > 0 && name.length < 80) {
      context.setPageTitle(name);
      context.setMontageNamingDone(true);
      localStorage.setItem("mnd", true);
      localStorage.setItem("montage_name", name);
      localStorage.setItem("mc_status", true);
      context.setCreateMontageDialog(false);
      montageNameSet();
    } else {
      notificationsHandler(
        notifyCtx,
        "warning",
        "Montage name should not be more than 80 characters"
      );
    }
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      submitRef.current.click();
    }
  };

  ///////Main Code////////////
  return (
    <>
      {created && (
        <Notify
          message="Montage Created!"
          severity="success"
          vertical="top"
          horizontal="right"
        />
      )}
      <Dialog
        onClose={() => {
          context.setCreateMontageDialog(false);
        }}
        open={context.createMontageDialog}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
          },
        }}
      >
        <Paper
          sx={{
            p: { xl: "40px", md: "20px", sm: "18px", xs: "15px" },
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            width: { xl: "600px", md: "600px", xs: "400px" },
          }}
        >
          <DialogTitle
            sx={{
              fontWeight: "500",
              fontSize: { xl: "24px", md: "20px", xs: "18px" },
              lineHeight: "31px",
              mb: { xl: "32px", md: "22px", xs: "18px" },
              color: "#000000",
              p: "0",
            }}
          >
            {title}
          </DialogTitle>
          <TextField
            variant="outlined"
            label="Enter the name of the montage"
            value={montageName}
            onChange={handleChange}
            sx={{
              background: "#F4F4F4",
              boxShadow: "0px 5px 29px rgba(112, 112, 112, 0.06)",
              width: "100%",
            }}
            autoFocus
            onKeyUp={handleEnter}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              mt: { xl: "32px", md: "28px", xs: "24px" },
            }}
          >
            <Btn
              title="Cancel"
              type="text"
              color="#959595"
              onClick={() => {
                context.setCreateMontageDialog(false);
              }}
              sx={{ p: 0 }}
            />

            <Btn
              title="Next"
              color="#fff"
              onClick={() => {
                verifyMontageName(montageName);
              }}
              innerRef={submitRef}
            />
          </Box>
        </Paper>
      </Dialog>
    </>
  );
}

CreateMontage.defaultProps = {
  title: "Create New Montage",
};
