//react
import { useEffect, useRef, useCallback, useContext, useState } from "react";
//axiosInstance hook
import useAxios from "../hooks/useAxios";
//utils and helper
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
//notifyContext
import { notifyContext } from "../context/notifyContext";
//3rd party
import { Navigate, useNavigate } from "react-router-dom";

function MapChannelsHOC(WrappedComponent) {
  return function Component(props) {
    const axiosInstance = useRef();
    axiosInstance.current = useAxios();
    const navigate = useNavigate();
    let notifyCtx = useRef();
    notifyCtx.current = useContext(notifyContext);
    const getMontageDetail = useCallback(
      async (montageId, notifyCtx, navigate) => {
        try {
          const config = axiosConfig({
            uri: `/montages/${montageId}`,
            method: "GET",
          });
          const response = await axiosInstance.current({
            ...config,
          });
          return response;
        } catch (error) {
          httpErrorHandler(error, notifyCtx, navigate);
        }
      },
      []
    );
    const [isMontageConfigured, setIsMontageConfigured] = useState(false);
    const montage = useRef(null);
    montage.current = JSON.parse(localStorage.getItem("current_montage"));
    useEffect(() => {
      (async () => {
        if (montage.current) {
          try {
            const response = await getMontageDetail(
              montage.current.id,
              notifyCtx,
              navigate
            );
            if (response.data.config) {
              //config not null (montage already configured)
              setIsMontageConfigured(true);
            }
          } catch (error) {
            httpErrorHandler(error, notifyCtx, navigate);
          }
        }
      })(0);
    }, [getMontageDetail, navigate]);

    return isMontageConfigured ? (
      <Navigate
        to={`/montages/view/${montage.current.title}/${montage.current.id}`}
        replace={true}
      />
    ) : (
      <WrappedComponent />
    );
  };
}

export default MapChannelsHOC;
