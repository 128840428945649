// -----------React Imports--------------
import { useEffect, useState, useContext, useCallback, useRef } from "react";
// Internal Components
import Btn from "../buttons/Btn";
import CustomPagination from "../CustomPagination";

// -----------MUI Imports----------------------
import { Grid, Stack, Typography, Box } from "@mui/material";

// Context
import { globalContext } from "../../context/globalContext";
import { notifyContext } from "../../context/notifyContext";

// --------------Internal Components--------------
import CreateMontage from "./CreateMontage";
import ProgressStatus from "../progress_status/ProgressStatus";
import PageLoader from "../loader/PageLoader";
import NoMontages from "./NoMontages";

////////////////////3rd party//////////
//hooks
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";

//////////////Helpers and utils/////////////
import {
  httpErrorHandler,
  axiosConfig,
  notificationsHandler,
  getDate,
} from "../../utils/helpers";

///////Custom Hooks////
import SearchMontage from "./SearchMontage";

////////////Main code/////////////
const validateSearchKey = (searchKey, notifyCtx) => {
  if (searchKey === null) return null;
  if (searchKey === "") {
    return false;
  }
  if (searchKey?.trim()?.length > 0) {
    return searchKey?.trim();
  } else {
    notificationsHandler(
      notifyCtx,
      "error",
      "Search Key can't be empty and must contain some alphanumeric characters"
    );
    return false;
  }
};

function MontageStatus({ status, ...props }) {
  let statusText = "No Status";
  let backgroundColor = "#EAEAEA";
  let color = "#8A8A8A";

  switch (status) {
    case "not_configured":
      statusText = "Not Configured";
      backgroundColor = "#EAEAEA";
      color = "#8A8A8A";
      break;
    case "processing":
      statusText = "EDFs Processing";
      backgroundColor = "#F6FBFF";
      color = "#2CA9E3";
      break;
    case "ready":
      statusText = "Ready";
      backgroundColor = "#D8F2E2";
      color = "#10A44B";
      break;
    default:
      break;
  }
  return (
    <Typography
      sx={{
        fontSize: "14px",
        fontWeight: "500px",
        lineHeight: "17px",
        backgroundColor: backgroundColor,
        color: color,
        width: "max-content",
        p: "4px 8px",
        borderRadius: "2px",
      }}
    >
      {statusText}
    </Typography>
  );
}
const NewMontageItem = ({ montage, id }) => {
  const navigate = useNavigate();
  const handleMontageItemClick = (montage) => {
    navigate(
      "/montages/view/" + encodeURIComponent(montage?.title) + "/" + montage.id
    );
  };
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      lg={4}
      xl={3}
      id={id}
      alignItems="center"
      sx={{ cursor: "pointer" }}
      onClick={() => {
        localStorage.setItem("current_montage", JSON.stringify(montage));
        handleMontageItemClick(montage, id);
      }}
    >
      <Box
        sx={{
          backgroundColor: "#fff",
          border: "2px solid #EAEAEA",
          borderRadius: "4px",
          p: "16px",
          transition: "border .3s ease",
          ":hover": {
            borderColor: "#2f7ec7",
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "24px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              mb: "4px",
              mr: "16px",
            }}
            title={montage.title}
          >
            {montage.title}
          </Typography>
        </Stack>
        <Stack direction="column">
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "17px",
              color: "#6d6d6d",
              mb: "24px",
            }}
          >
            Created on {getDate(montage.created_at)}
          </Typography>
          <MontageStatus status={montage.status}></MontageStatus>
        </Stack>
      </Box>
    </Grid>
  );
};
//montage grid
const MontageGrid = ({ montageData, setPage, pageSize, loader, ...props }) => {
  return (
    <Box sx={{ px: "40px", width: "100%", mb: "2rem" }}>
      <Grid
        container
        rowSpacing={4}
        columnSpacing={5}
        marginTop={1}
        marginBottom={2}
      >
        {montageData &&
          montageData.results &&
          montageData.results.length &&
          montageData.results.map((montage, index) => {
            return (
              // <MontageItem key={index} montage={montage} id={montage.id} />
              <NewMontageItem key={index} montage={montage} id={montage.id} />
            );
          })}
      </Grid>
      {!montageData.previous && !montageData.next ? null : (
        <CustomPagination
          disabled={loader.loading}
          last_page_no={Math.ceil(montageData.count / pageSize)}
          limit={montageData.results.length}
          handlePaginationChange={(_, value) => setPage(value)}
        />
      )}
    </Box>
  );
};

export default function MontageDashboard(props) {
  const { setCreateMontageDialog, setPageTitle } = useContext(globalContext);
  // const [formError, setFormError] = useState({});
  const [searchKey, setSearchKey] = useState(null);

  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const navigate = useNavigate();
  const [montageData, setMontageData] = useState([]);

  const paginationCall = useRef(false);
  const notifyCtx = useRef();
  notifyCtx.current = useContext(notifyContext);
  const [page, setPage] = useState(1);

  const [count, setCount] = useState(0);

  const [loader, setLoader] = useState({
    loading: false,
    message: "Loading",
  });

  const PAGE_SIZE = 9;

  const fetchMontages = useCallback(
    async (searchKey, abortController, page) => {
      const config = axiosConfig({
        uri: "/montages/",
        method: "GET",
        params: { page: page, page_size: PAGE_SIZE, search: searchKey },
      });

      const response = await axiosInstance.current({
        ...config,
        signal: abortController.signal,
      });
      setMontageData(response.data);
      setCount(response.data?.count);
    },
    []
  );

  useEffect(() => {
    setPageTitle("Montages");

    const abortController = new AbortController();
    //initial page load
    const validationResponse = validateSearchKey(searchKey, notifyCtx);
    //initial call for fetching

    if (validationResponse && !paginationCall.current) {
      setLoader({ loading: true, message: "Getting Montages" });
      fetchMontages(searchKey, abortController, 1)
        .then(() => {
          setLoader({ loading: false, message: "Getting Montages" });
        })
        .catch((error) => {
          if (!error.message === "canceled") {
            setLoader({ loading: false, message: "Getting Montages" });
          }
          httpErrorHandler(error, notifyCtx, navigate);
        });
    } else {
      setLoader({ loading: true, message: "Getting Montages" });
      fetchMontages(searchKey, abortController, page)
        .then((response) => {
          setLoader({ loading: false, message: "Getting Montages" });
        })
        .catch((error) => {
          if (!error.message === "canceled") {
            setLoader({ loading: false, message: "Getting Montages" });
          }
          httpErrorHandler(error, notifyCtx, navigate);
        });
    }
    paginationCall.current = false;
  }, [fetchMontages, setPageTitle, searchKey, navigate, page]);

  return (
    <>
      <ProgressStatus />
      <Box sx={{ px: "40px", width: "100%", mb: "1rem" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <SearchMontage setSearchKey={setSearchKey} />

          <Btn
            title="Create Montage"
            onClick={() => {
              setCreateMontageDialog(true);
            }}
            color="#fff"
          />
          <CreateMontage />
        </Stack>
      </Box>
      {loader.loading ? (
        <PageLoader open={loader.loading} message={loader.message} />
      ) : (
        <>
          {montageData.count > 0 ? (
            <MontageGrid
              montageData={montageData}
              setPage={setPage}
              pageSize={PAGE_SIZE}
              loader={loader}
            />
          ) : (
            <NoMontages sx={{ height: "100%" }} />
          )}
        </>
      )}
      <CustomPagination
        disabled={loader?.loading}
        last_page_no={Math.ceil(count / PAGE_SIZE)}
        limit={(montageData && montageData?.count) || 0}
        handlePaginationChange={(_, value) => {
          setPage(value);
          paginationCall.current = true;
        }}
      />
    </>
  );
}
