// ---------------React Imports----------------
import { useContext } from "react";
// -------------------3rd party libs --------------
import { Navigate } from "react-router-dom";
// -------------------context----------------------
import { globalContext } from "../context/globalContext";
// -------------------utils & helpers-------------------
import urlpatterns from "../utils/urls.json";

export default function CreateMontageHOC(WrappedComponent) {
  return function Component(props) {
    const context = useContext(globalContext);
    // useEffect(() => {
    //   if (localStorage.getItem("mnd") != null) {
    //     context.setPageTitle(localStorage.getItem("montage_name"));
    //   }
    // }, []);
    if (context.montageNamingDone) {
      return <WrappedComponent />;
    } else if (localStorage.getItem("current_montage") != null) {
      return <WrappedComponent />;
    }
    return <Navigate to={urlpatterns.montages} />;
  };
}
