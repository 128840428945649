////////////System Imports//////////////
import React, { useContext } from "react";

////////////MUI Imports////////////////
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
//internal
import ComponentLoader from "../loader/ComponentLoader";
//context
import { globalContext } from "../../context/globalContext";

export default function AvailableCredits(props) {
  const globalCtx = useContext(globalContext);

  return (
    <Paper
      elevation={0}
      sx={{
        background: "transparent",
      }}
    >
      <Box
        sx={{
          width: "248px",
          background: "rgba(44, 169, 227, 0.25)",
          borderRadius: "4px",
          mx: "auto",
          pb: "24px",
        }}
      >
        <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
          <IconButton
            aria-label="refresh-credits"
            title="Refresh Credits"
            onClick={
              props.getSidebarCredits ? props.getSidebarCredits : () => {}
            }
            sx={{ color: props.theme === "dark" ? "#000" : "#fff" }}
          >
            <ReplayIcon />
          </IconButton>
        </Box>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
        >
          {globalCtx.sidebarCredits.loading ||
          globalCtx.sidebarCredits.error ? (
            <ComponentLoader
              error={globalCtx.sidebarCredits.error}
              loading={globalCtx.sidebarCredits.loading}
              transparent={true}
              message={false}
              minHeight={false}
              theme="light"
              retry={props.getSidebarCredits}
              sx={{ minHeight: "111px" }}
            />
          ) : (
            <>
              <Typography
                sx={{ fontSize: "28px", fontWeight: "500", color: "#FFFFFF" }}
              >
                {globalCtx.sidebarCredits?.available?.toFixed(2)}
              </Typography>
              <Typography
                component="p"
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#FFFFFF",
                  lineHeight: "19px",
                  display: "block",
                  "&&": {
                    mb: "20px",
                  },
                }}
              >
                Total Credits Available
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "19px",
                  color: "#FFFFFF",
                }}
              >
                <span
                  style={{
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#FFFFFF",
                  }}
                >
                  {globalCtx.sidebarCredits?.used?.toFixed(2)}
                </span>
                Credits Used
              </Typography>
            </>
          )}
        </Stack>
      </Box>
    </Paper>
  );
}

AvailableCredits.defaultProps = {
  getSidebarCredits: () => {},
};
