export const input_select = {
  display: "none",
};
export const label_button = {
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "21px",
  color: "#8A8A8A",
  fontFamily: "Inter,sans-serif",
  border: "1px solid #8A8A8A",
  height: "45px",
  width: "174px",
  margin: "17px 0",
  borderRadius: "3px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  /* identical to box height */
  letterSpacing: "0.02em",
  cursor: "pointer",
};
export const input_label_container = {};
