import { useState } from "react";
import CustomServicesCell from "../../components/custom_service";
//utils and helpers

import { downloadCalls, submitCalls } from "../../utils/helpers";
//mui
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ReplayIcon from "@mui/icons-material/Replay";
// libs
import { differenceInMinutes } from "date-fns";
// internal
import SingleFileDownloadPopup from "../../components/montages/SingleFileDownloadPopup";
import Btn from "../../components/buttons/Btn";

export const tableColsConfig = [
  {
    name: "Filename",
    accessor: "root",
    getAccessor: (el) => {
      return el?.file_name;
    },

    sx: {
      headerCell: { pl: "56px" },
      rowCell: {
        pl: "56px",
        maxWidth: "100px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  },
  {
    name: "Status",
    accessor: "root",
    getAccessor: (el) => {
      return el?.status;
    },
    linkTo: null,
  },
  {
    name: "Credits Used",
    accessor: "root",
    getAccessor: (el) => {
      return el?.credit_used;
    },
    linkTo: null,
  },
  {
    name: "Total Time Taken",
    accessor: "root",
    getAccessor: (el) => {
      if (el?.time_call_ended && el?.time_call_started)
        return differenceInMinutes(
          new Date(el?.time_call_ended),
          new Date(el?.time_call_started)
        );
      return "";
    },
    linkTo: null,
  },
  {
    name: "Services Required",
    accessor: "root",
    getAccessor: (el) => {
      return el?.services;
    },
    linkTo: null,
    component: CustomServicesCell,
  },
];

export const table_headers = [
  { name: "EDF File", styles: { textAlign: "left" } },
  { name: "Status", styles: { width: "150px" } },
  { name: "Credits", styles: {} },
  { name: "Total Time Taken", styles: {} },

  { name: "Service Used", styles: {} },
];

////////utility function - Pass a status and it will return a corresponding button//////
export function GetStatusButton({
  notifyCtx,
  globalCtx,
  callId,
  status,
  setUpdateCallsList,
  buttonLoading,
  setButtonLoading,
  setCallList,
  navigate,
  axiosInstance,
}) {
  //state for download pop up
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  switch (status) {
    case "init":
      return (
        <Btn
          // title="Start Processing"
          title="Download"
          onClick={() => {
            submitCalls(
              globalCtx,
              notifyCtx,
              callId,
              setUpdateCallsList,
              setButtonLoading,
              navigate
            );
          }}
          color="#fff"
          sx={{ px: "24px", py: "8px", width: "163px" }}
          // disabled={buttonLoading.processing.includes(callId) ? true : false}
          disabled={true}
        />
      );

    case "processing":
      return (
        <Btn
          sx={{ px: "24px", py: "8px", width: "163px" }}
          title="Download"
          type="contained"
          color="#959595"
          hover={{
            backgroundColor: "rgba(0,0,0, 0.2)",
            color: "#000",
          }}
          disabled={true}
        />
      );

    case "processed":
      return (
        <>
          <Btn
            sx={{ px: "24px", py: "8px", width: "163px" }}
            title="Download"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setOpen((previousState) => {
                return !previousState;
              });
              // setButtonLoading((prevState) => {
              //   return {
              //     ...prevState,
              //     downloading: [...prevState.downloading, callId],
              //   };
              // });
              // downloadCalls(navigate, globalCtx, notifyCtx, [callId]);
            }}
            callId={callId}
            color="#fff"
            bg="#10A44B"
            hover="#0e8b40"
            startIcon={<FileDownloadOutlinedIcon />}
            disabled={buttonLoading.downloading.includes(callId) ? true : false}
          />
          {anchorEl ? (
            <SingleFileDownloadPopup
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              open={open}
              setOpen={setOpen}
              downloadCalls={downloadCalls}
              callId={callId}
            />
          ) : (
            <> </>
          )}
        </>
      );

    case "error":
      return (
        <Btn
          sx={{ px: "24px", py: "8px", width: "163px" }}
          title="Retry"
          onClick={(e) => {
            e.stopPropagation();
            submitCalls({
              notifyCtx,
              callId,
              setUpdateCallsList,
              setButtonLoading,
              navigate,
              retry: true,
              setCallList,
              axiosInstance,
            });
          }}
          color="#fff"
          bg="#2F7EC7"
          disabled={buttonLoading?.retrying.includes(callId) ? true : false}
          startIcon={<ReplayIcon />}
        />
      );

    case "submitted":
      return (
        <Btn
          title="Download"
          // onClick={() => {
          //   console.log("file donwload clicked");
          // }}
          color="#fff"
          sx={{ px: "24px", py: "8px", width: "163px" }}
          disabled={true}
        />
      );
    case "edf_missing":
      return (
        <Btn
          title="Error"
          color="#fff"
          sx={{ px: "24px", py: "8px", width: "163px" }}
          disabled={true}
        />
      );
    case "insufficient_credits":
      return (
        <Btn
          title="Retry"
          onClick={() => {
            submitCalls(globalCtx, notifyCtx, callId, setUpdateCallsList);
          }}
          color="#fff"
          sx={{ px: "24px", py: "8px", width: "163px" }}
          disabled={false}
        />
      );
    case "anonymized":
      return (
        <Btn
          sx={{ px: "24px", py: "8px", width: "163px" }}
          title="Download"
          type="contained"
          color="#959595"
          hover={{
            backgroundColor: "rgba(0,0,0, 0.2)",
            color: "#000",
          }}
          disabled={true}
        />
      );

    default:
      return (
        <Btn
          title={`${status}`}
          onClick={() => {
            console.log("A new  status clicked");
          }}
          color="#fff"
          sx={{ px: "24px", py: "8px", width: "163px" }}
          disabled={true}
        />
      );
  }
}
