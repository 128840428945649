//react
import { useState } from "react";
//mui
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export default function GridRow({
  row,
  rowId,
  channels,
  dispatch,
  channelsList,
}) {
  // local state variables
  const [open, setOpen] = useState(row.backups.length ? false : false);
  const [backups] = useState(row.backups.length ? row.backups : []);

  // represents a singe channel row
  const Row = ({ rowId }) => (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        padding: "24px 56px",
      }}
      id={`channel-${rowId}`}
    >
      {/* channel name */}
      <Grid item xs={12} md={3}>
        <Typography fontWeight={500} fontSize={18}>
          {row.channel}
        </Typography>
      </Grid>
      {/* primary channel */}
      <Grid item xs={6} md={3}>
        <Select
          labelId="primary-channel-select"
          id="primary-channels"
          value={channels[rowId] ? channels[rowId].primaryChannel : "N/A"}
          label=""
          onChange={(event) => {
            dispatch({
              type: "set_primary",
              rowId: rowId,
              value: event.target.value,
            });
          }}
          sx={{ MaxWidth: "100%", width: "70%", height: "40px" }}
          MenuProps={{
            style: {
              maxHeight: 400,
            },
          }}
        >
          {channelsList.map((channel, index) => (
            <MenuItem key={index} value={channel}>
              {channel}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {/* ref channel */}
      <Grid item xs={6} md={3}>
        <Select
          labelId="ref-channel-select"
          id="ref-channels"
          value={channels[rowId] ? channels[rowId].refChannel : "N/A"}
          label=""
          onChange={(event) => {
            dispatch({
              type: "set_ref",
              rowId: rowId,
              value: event.target.value,
            });
          }}
          MenuProps={{
            style: {
              maxHeight: 400,
            },
          }}
          disabled={
            channels[rowId] && channels[rowId].primaryChannel === "N/A"
              ? true
              : false
          }
          sx={{ MaxWidth: "100%", width: "70%", height: "40px" }}
        >
          {channelsList.map((channel, index) => (
            <MenuItem key={index} value={channel}>
              {channel}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {/* view/hide/add button */}
      <Grid item xs={12} md={3}>
        <Button
          variant={open || row.backups.length ? "text" : "outlined"}
          onClick={() => setOpen(!open)}
          sx={{ color: "#2F7EC7", textTransform: "none" }}
          disabled={
            channels[rowId] && channels[rowId].primaryChannel === "N/A"
              ? true
              : false
          }
        >
          {!open ? (
            row.backups.length ? (
              <>View backup channels ({row.backups.length})</>
            ) : (
              <>+ Add Backup Channels</>
            )
          ) : (
            <>Hide backup channels ({row.backups.length})</>
          )}
        </Button>
      </Grid>
    </Grid>
  );
  // represents a single backup channel row
  const SubRow = ({ row, backupRowId }) => {
    //backup channel value finder(if exist)
    const getBackupChannelsValue = (channels, rowId, primary, ref) => {
      if (channels[rowId]) {
        const foundResult = channels[rowId].backups.find((el, ind) => {
          return backupRowId === el.id;
        });
        if (foundResult && primary) {
          return foundResult.backupPrimaryChannel;
        } else if (foundResult && ref) {
          return foundResult.backupRefChannel;
        } else {
          return "N/A";
        }
      }
    };
    return (
      <Grid
        container
        style={{
          backgroundColor: "#F6F6F6",
          padding: "32px 56px",
        }}
        alignItems="center"
        justifyContent="center"
        id={`backupChannel-${backupRowId}-${rowId}`}
      >
        <Grid item xs={12} md={3}></Grid>
        {/* primary channel */}
        <Grid item xs={6} md={3}>
          <Select
            labelId="backup-channel-select"
            id="backup-channels"
            value={getBackupChannelsValue(channels, rowId, true, false)}
            label=""
            onChange={(event) => {
              dispatch({
                type: "set_backup_primary",
                rowId: rowId,
                backupRowId: backupRowId,
                value: event.target.value,
              });
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
            sx={{ MaxWidth: "100%", width: "70%", height: "40px" }}
          >
            {channelsList.map((channel, index) => (
              <MenuItem key={index} value={channel}>
                {channel}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {/* backup channel */}
        <Grid item xs={6} md={3}>
          <Select
            labelId="backupRef-channel-select"
            id="backupRef-channels"
            value={getBackupChannelsValue(channels, rowId, false, true)}
            onChange={(event) => {
              dispatch({
                type: "set_backup_ref",
                rowId: rowId,
                backupRowId: backupRowId,
                value: event.target.value,
              });
            }}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
            label=""
            sx={{ MaxWidth: "100%", width: "70%", height: "40px" }}
          >
            {channelsList.map((channel, index) => (
              <MenuItem key={index} value={channel}>
                {channel}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {/* delete button */}
        <Grid item xs={12} md={3}>
          <Button
            variant="text"
            sx={{ textTransform: "none" }}
            onClick={(event) => {
              // setBackups(backups.filter((i) => i.id !== row.id));
              dispatch({
                type: "remove_backup",
                backupRowId: backupRowId,
                rowId: rowId,
              });
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
            >
              <DeleteOutlineOutlinedIcon color="error" />
              <Typography color="error">Delete</Typography>
            </Stack>
          </Button>
        </Grid>
      </Grid>
    );
  };

  const AddMore = ({ rowId, backupRowId }) => (
    <Grid
      container
      style={{
        backgroundColor: "#F6F6F6",
        borderRadius: "3px",
        padding: "32px 56px",
        paddingTop: backups.length ? "0px" : "32px",
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3}></Grid>
      {/* add more action */}
      <Grid item xs={9}>
        <Typography
          fontSize={16}
          sx={{ cursor: "pointer", display: "inline-block", pt: "1rem" }}
          color="#2CA9E3"
          onClick={() => {
            // setBackups([
            //   ...backups,
            //   {
            //     id: backups.length ? backups[backups.length - 1].id + 1 : 0,
            //     backupPrimaryChannel: null,
            //     backupRefChannel: null,
            //   },
            // ]);
            dispatch({ type: "add_backup", rowId: rowId });
          }}
        >
          + Add backup channels
        </Typography>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
  // wrapper for all backup channels
  const SubRowWrapper = ({ backups, rowId }) => (
    <Stack direction="column">
      {backups.map((row, index) => {
        return <SubRow key={index} row={row} backupRowId={row.id} />;
      })}
      <AddMore rowId={rowId} />
    </Stack>
  );
  return (
    <Stack
      direction="column"
      style={{
        backgroundColor: "#FFF",
        borderRadius: "3px",
        boxShadow: "0px 3px 20px 4px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Row rowId={rowId} />
      <Collapse in={open}>
        <SubRowWrapper backups={row.backups} rowId={rowId} />
      </Collapse>
    </Stack>
  );
}
