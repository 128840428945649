//mui
import Paper from "@mui/material/Paper";
//react
import { useEffect, useState, useContext, useRef, useCallback } from "react";
//internal
import TableComponent from "../table";
import CustomPagination from "../CustomPagination";
import { notifyContext } from "../../context/notifyContext";
import { useNavigate } from "react-router-dom";

import { httpErrorHandler, axiosConfig } from "../../utils/helpers";
//hooks
import useAxios from "../../hooks/useAxios";
//internal
import CustomServicesCell from "../custom_service";
const decorateStatus = ["Status"];
const cols = [
  {
    name: "Credits Used",
    accessor: "root",
    getAccessor: (el) => {
      return el?.message.action === "credit.deducted"
        ? parseFloat(el?.message?.net_credits_required).toFixed(2)
        : parseFloat(el?.message?.net_credits_refunded).toFixed(2);
    },
    linkTo: null,
  },
  {
    name: "Call Name",
    accessor: "root",
    getAccessor: (el) => {
      return el?.call?.file_name;
    },
    linkTo: null,
  },
  {
    name: "Service Used",
    accessor: "root",
    getAccessor: (el) => {
      return el?.call;
    },
    component: CustomServicesCell,
    linkTo: null,
  },
  {
    name: "Time",
    accessor: "created_on",
    linkTo: null,
  },
  {
    name: "Date",
    accessor: "created_on",

    linkTo: null,
  },
  {
    name: "Status",
    accessor: "root",
    getAccessor: (el) => {
      return el.message.action === "credit.deducted"
        ? "Deducted"
        : el.message.action === "credit.refund"
        ? "Refunded"
        : el.message.action;
    },
    linkTo: null,
  },
];

function TransactionActivity({ rows, ...props }) {
  const PAGE_SIZE = 11;
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [txnHistory, setTxnHistory] = useState([]);
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const navigate = useNavigate();
  const notifyCtx = useRef(null);
  notifyCtx.current = useContext(notifyContext);
  const [loading, setLoading] = useState(false);
  const fetchTxnHistory = useCallback(
    async (abortController) => {
      setLoading(true);
      try {
        const config = axiosConfig({
          method: "GET",
          uri: "/credits/txn-history/consumer",
          params: {
            page: page,
            page_size: PAGE_SIZE,
          },
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setLoading(false);
        setTxnHistory(response.data);
        setCount(response.data?.count);
      } catch (error) {
        httpErrorHandler(error, notifyCtx, navigate);
        if (error.message !== "canceled") {
          setLoading(false);
        }
      }
    },
    [notifyCtx, navigate, page]
  );

  //for fetching credit txn history
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      await fetchTxnHistory(abortController);
    })();
    return () => abortController.abort();
  }, [fetchTxnHistory]);

  return (
    <Paper elevation={0}>
      <TableComponent
        sx={{ mb: "4rem" }}
        cols={cols}
        rows={txnHistory}
        loading={loading}
        decorateStatus={decorateStatus}
        statusColorMode="bg"
        // enableViewAll={{
        //   title: "View All",
        //   viewAll: true,
        //   viewLink: "/montages",
        //   maxResults: 2,
        // }}
        // rowLink={{
        //   linkKey: "id",
        //   linkTo: "/txn",
        // }}
        paginate={
          <CustomPagination
            disabled={loading}
            last_page_no={Math.ceil(count / PAGE_SIZE)}
            limit={txnHistory?.results?.length}
            handlePaginationChange={(_, value) => {
              setPage(value);
            }}
            sx={{ mt: "2rem" }}
          />
        }
      />
    </Paper>
  );
}

export default TransactionActivity;
