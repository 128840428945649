//mui
import { Box, FormControl, Grid, Stack, Typography } from "@mui/material";
import screenImg from "../../assets/signin/screenshot.svg";
import logo from "../../assets/logo/Neurobit-Score-Logo-Transparent 1.svg";
import { Link } from "react-router-dom";
import urlpatterns from "../../utils/urls.json";
//3rd party
import { Formik, Form } from "formik";
import * as yup from "yup";
//form validators
import { FORM_VALIDATION } from "../../utils/formValidation";
// internal
import ButtonWrapper from "./FormComponents/Button";
import TextField from "../forms/FormComponents/TextField";
//hooks
import useAuth from "../../hooks/useAuth";

const LOCAL_FORM_VALIDATION = yup.object().shape({
  email: FORM_VALIDATION["email"],
  password: FORM_VALIDATION["password1"],
});
const FORM_INITIAL_VALUES = {
  email: "",
  password: "",
};

export default function SigninForm(props) {
  const [, dispatch] = useAuth();
  return (
    <Grid
      container
      style={{
        height: "100vh",
      }}
    >
      <Grid item xs={12} lg={6}>
        <Box
          px={2}
          style={{
            height: "10vh",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{
              width: "100%",
              paddingLeft: "40px",
              paddingTop: "40px",
            }}
          >
            <img src={logo} alt="logo" width={107} height={60} />
          </Stack>
        </Box>
        <Box
          px={2}
          sx={{
            height: "90vh",
            backgroundColor: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pl: { lg: "40px", xl: "0px" },
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            py={5}
            style={{
              width: "100%",
              maxWidth: "600px",
            }}
          >
            <Typography
              style={{
                fontSize: "32px",
                fontWeight: "500",
                lineHeight: "38px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#0F0F0F",
              }}
            >
              Log In
            </Typography>
            <Typography
              style={{
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "26.04px",
                letterSpacing: "0em",
                textAlign: "left",
                color: "#8A8A8A",
              }}
            >
              Welcome to Neurobit PSG, please enter your login credentials below
              to access your account.{" "}
            </Typography>
            <Formik
              initialValues={FORM_INITIAL_VALUES}
              validationSchema={LOCAL_FORM_VALIDATION}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                const response = await props.handleSubmit(
                  values?.email,
                  values?.password
                );
                if (response?.status === 200) {
                  dispatch({
                    type: "signin",
                    payload: {
                      user: response.data.user,
                      accessToken: {
                        token: response.data.access_token,
                        expiration: response.data.access_token_expiration,
                      },
                      refreshToken: {
                        token: response.data.refresh_token,
                        expiration: response.data.refresh_token_expiration,
                      },
                    },
                    error: null,
                  });
                }
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, isValid }) => {
                return (
                  <Form>
                    <Stack my={2} direction="column" spacing={3}>
                      <FormControl>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#898989",
                          }}
                        >
                          Email address
                        </Typography>
                        <TextField
                          inputProps={{
                            style: {
                              padding: "15px",
                              width: "100%",
                              fontSize: "16px",
                              color: "#0F0F0F",

                              backgroundColor: "#FBFBFB",
                            },
                          }}
                          type="email"
                          name="email"
                          sx={{
                            borderRadius: "5px",
                            borderColor: "1px solid #C4C4C4",
                            width: "100%",
                          }}
                        />
                      </FormControl>

                      <FormControl>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#898989",
                          }}
                        >
                          Password
                        </Typography>
                        <TextField
                          name="password"
                          inputProps={{
                            style: {
                              padding: "15px",
                              fontSize: "16px",
                              color: "#0F0F0F",
                              backgroundColor: "#FBFBFB",
                            },
                          }}
                          type="password"
                          sx={{
                            borderRadius: "5px",
                            borderColor: "1px solid #C4C4C4",
                            width: "100%",
                          }}
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="column" spacing={5} py={2}>
                      <Typography
                        component={Link}
                        to={urlpatterns.forgot_password}
                        sx={{
                          width: "max-content",
                          marginLeft: "auto",
                          cursor: "pointer",
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "19px",
                          letterSpacing: "0em",
                          textAlign: "right",
                          color: "#8A8A8A",
                        }}
                      >
                        Forgot Password?
                      </Typography>

                      <ButtonWrapper
                        disableElevation
                        type="submit"
                        variant="contained"
                        sx={{
                          backgroundColor: "#2F7EC7",
                          borderRadius: "5px",
                          padding: "15px",
                        }}
                        disabled={!isValid || isSubmitting}
                      >
                        LOG IN
                      </ButtonWrapper>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>
          </Stack>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        style={{
          backgroundColor: "#F5F6FA",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "64px",
        }}
      >
        <Box sx={{ height: "100%", overflow: "hidden" }}>
          <Stack
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={3}
            style={{
              width: "100%",
              paddingTop: "120px",
            }}
          >
            <Typography
              style={{
                color: "#0F0F0F",
                fontSize: "32px",
                fontWeight: "700",
                lineHeight: "19px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Welcome to Neurobit Score{" "}
            </Typography>
            <Typography
              style={{
                color: "#868686",
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "26.04px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Neurobit Score is a Cloud AI Powered Sleep Scoring system for
              academics, researchers, healthcare providers and enterprise. We
              seamlessly integrate into your existing workflow with Zero
              disruption and high level of inter-operability which can
              significantly reduce set-up times and scalability of your
              experiments.
            </Typography>
          </Stack>
          <Box
            style={{
              // position: "relative",

              height: "100%",
              width: "100%",
            }}
          >
            <img
              src={screenImg}
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
              alt="screen"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
