import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import urlpatterns from "../utils/urls.json";

export default function AuthHOC(WrappedComponent) {
  function Component(props) {
    const [auth, dispatch] = useAuth();
    if (!auth?.authenticated) {
      return <Navigate to={urlpatterns.signin} />;
    } else if (auth?.authenticated && auth?.user?.type === "CONSUMER") {
      return <WrappedComponent user={auth.user} dispatch={dispatch} />;
    } else if (auth?.user) {
      return (
        <Navigate to={urlpatterns.signin} state={{ logout: true, dispatch }} />
      );
    }
  }

  return Component;
}
