////////////////MUI Components///////////////
import { Button } from "@mui/material";

function Btn(props) {
  return (
    <Button
      callid={props.callId ? props.callId : ""}
      variant={props.type}
      disableElevation
      onClick={props.onClick}
      disabled={props.disabled}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      ref={props.innerRef}
      sx={{
        backgroundColor:
          props.type === "text"
            ? "transparent"
            : props.type === "outlined"
            ? "transparent"
            : props.bg,
        "&:hover": {
          backgroundColor:
            props.type === "text"
              ? props.hover !== "#2668a6"
                ? `${props.hover}`
                : "#efefef"
              : `${props.hover}`,
          ...props.hover,
        },
        color: props.color,
        fontFamily: "Inter",
        textTransform: "capitalize",
        p: "8px 40px 8px",
        width: props.type === "text" ? "auto" : "initial",
        "&:focus": {
          outline: "none",
        },

        ...props.sx,
      }}
    >
      {props.title}
    </Button>
  );
}

export default Btn;

Btn.defaultProps = {
  title: "Button",
  type: "contained",
  onClick: () => {},
  bg: "#2F7EC7",
  color: "#111",
  hover: "#2668a6",
  disabled: false,
  startIcon: "",
  endIcon: "",
};
