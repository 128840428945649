////////////////React Imports//////////////////////
import { useState, useReducer, useEffect, useContext, useRef } from "react";
/////////////////MUI components////////////////////

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
//hoc
import MapChannelsHOC from "../../hoc/MapChannelsHOC";
//////////3rd party/////////
import axios from "axios";
import { useOutletContext, useNavigate } from "react-router-dom";
//////////Internal///////
import ProgressStatus from "../progress_status/ProgressStatus";
import UserConsent from "../montages/UserConsent";
import AdditionalSettings from "./map_channels/AdditionalSettings";

///////////////////////////Utils and Helper ///////////////////
import {
  axiosConfig,
  verifySchema,
  notificationsHandler,
  httpErrorHandler,
  getConsentMessage,
} from "../../utils/helpers";

//hooks
import useAxios from "../../hooks/useAxios";

////////////context//////////
import { globalContext } from "../../context/globalContext";
import { socketContext } from "../../context/socketContext";
import { notifyContext } from "../../context/notifyContext";

//internal Components
import GridRow from "./map_channels/GridRow";

const downloadChannelsFile = async (
  fileUrl,
  setChannelsList,
  notifyCtx,
  navigate
) => {
  try {
    const response = await axios({
      url: fileUrl,
      method: "GET",
      responseType: "blob",
    });

    const file = new File([response.data], "channels.json");
    const fr = new FileReader();
    fr.addEventListener("load", (e) => {
      //appending N/A in channels
      const temp_channels = JSON.parse(e.target.result);
      temp_channels.push("N/A");
      setChannelsList(temp_channels.sort());
    });
    fr.addEventListener("error", (e) => {
      notificationsHandler(
        notifyCtx,
        "error",
        "Error in Reading Channels File"
      );
    });

    fr.readAsText(file);
  } catch (error) {
    httpErrorHandler(error, notifyCtx, navigate);
  }
};

const createConfigData = (rowChannels) => {
  const data = rowChannels.map((channel, index) => {
    return {
      id: index,
      channel: channel,
      primaryChannel: "N/A",
      refChannel: "N/A",
      backups: [
        // {
        //   id: 0,
        //   backupPrimaryChannel: "A3",
        //   backupRefChannel: "A3",
        // },
      ],
    };
  });
  localStorage.setItem("montages_configurations", JSON.stringify(data));
  return data;
};

function MapChannels() {
  //states
  const [filesCollected, setFilesCollected] = useState(false);
  const [callsSubmitted, setCallsSubmitted] = useState([]);
  const [callsSkipped, setCallsSkipped] = useState([]);
  const [callsAnonymizing, setCallsAnonymizing] = useState([]);
  const [channelJsonExist, setChannelJsonExist] = useState(true);
  const [montageInfo, setMontageInfo] = useState(null);
  const [, setConfigData] = useState([]);
  const [schema, setSchema] = useState([]);
  const [channelsList, setChannelsList] = useState([]);
  const [additionalSettings, setAdditionalSettings] = useState([]);

  //context
  const ctx = useContext(globalContext);

  const notifyCtx = useContext(notifyContext);
  const socketCtx = useContext(socketContext);

  //reducer;
  const reducer = (state, action) => {
    switch (action.type) {
      case "add_backup": {
        const channels_state = state.map((element, index) => {
          if (element.id === action.rowId) {
            return {
              ...element,
              backups: element.backups.length
                ? [
                    ...element.backups,
                    {
                      id: element.backups[element.backups.length - 1].id + 1,
                      backupPrimaryChannel: "N/A",
                      backupRefChannel: "N/A",
                    },
                  ]
                : [
                    {
                      id: 0,
                      backupPrimaryChannel: "N/A",
                      backupRefChannel: "N/A",
                    },
                  ],
            };
          } else {
            return element;
          }
        });

        localStorage.setItem(
          "montages_configurations",
          JSON.stringify(channels_state)
        );
        return channels_state;
      }
      case "remove_backup": {
        const channels_state = state.map((element, index) => {
          if (element.id === action.rowId) {
            return {
              ...element,
              backups:
                element.backups.length > 1
                  ? element.backups.filter((backup_element, index) => {
                      return backup_element.id !== action.backupRowId;
                    })
                  : [],
            };
          } else {
            return element;
          }
        });

        localStorage.setItem(
          "montages_configurations",
          JSON.stringify(channels_state)
        );
        return channels_state;
      }

      case "set_backup_ref": {
        const channels_state = state.map((element, index) => {
          if (element.id === action.rowId) {
            const backupChannelsArray = element.backups.map(
              (backupChannelRow, index) => {
                if (backupChannelRow.id === action.backupRowId) {
                  return {
                    ...backupChannelRow,
                    backupRefChannel: action.value,
                  };
                } else {
                  return backupChannelRow;
                }
              }
            );

            return { ...element, backups: backupChannelsArray };
          }
          return element;
        });

        localStorage.setItem(
          "montages_configurations",
          JSON.stringify(channels_state)
        );
        return channels_state;
      }
      case "set_backup_primary": {
        const channels_state = state.map((element, index) => {
          if (element.id === action.rowId) {
            const backupChannelsArray = element.backups.map(
              (backupChannelRow, index) => {
                if (backupChannelRow.id === action.backupRowId) {
                  return {
                    ...backupChannelRow,
                    backupPrimaryChannel: action.value,
                  };
                } else {
                  return backupChannelRow;
                }
              }
            );
            return { ...element, backups: backupChannelsArray };
          }
          return element;
        });

        localStorage.setItem(
          "montages_configurations",
          JSON.stringify(channels_state)
        );
        return channels_state;
      }

      case "set_primary": {
        const channels_state = state.map((element, index) => {
          if (element.id === action.rowId) {
            return { ...element, primaryChannel: action.value };
          } else {
            return element;
          }
        });

        localStorage.setItem(
          "montages_configurations",
          JSON.stringify(channels_state)
        );
        return channels_state;
      }
      case "set_ref": {
        const channels_state = state.map((element, index) => {
          if (element.id === action.rowId) {
            return { ...element, refChannel: action.value };
          } else {
            return element;
          }
        });

        localStorage.setItem(
          "montages_configurations",
          JSON.stringify(channels_state)
        );
        return channels_state;
      }
      case "update_state": {
        return action.updatedData;
      }
      default:
        return state;
    }
  };

  const [channels, dispatch] = useReducer(reducer, []);

  //outlet context
  const { setDisableNext } = useOutletContext();
  //navigate
  const navigate = useNavigate();
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  //fetch channels - collect files api
  useEffect(() => {
    const current_montage = JSON.parse(localStorage.getItem("current_montage"));
    ctx.setPageTitle(
      current_montage?.title ? current_montage?.title : "Montage"
    );
    setDisableNext(true);
    // setStepperActiveStep(1, setActiveStep, true);
    (async () => {
      //setting active step
      //get the schema from the backend
      const getSchema = async () => {
        ctx.setOpenProgressStatus(true);
        ctx.setProgressStatusMessage("Getting Schema For Montage Config");
        try {
          const config = axiosConfig({
            uri: `/montages/schemas/`,
            method: "GET",
          });
          const response = await axiosInstance.current({
            ...config,
          });
          const data = response.data.length
            ? response.data[0].data
            : response.data;
          setSchema(data);
          setAdditionalSettings(data.optionals);
          if ("channels" in data && Object.keys(data.channels).length) {
            localStorage.setItem("schema", JSON.stringify(data));
          }
        } catch (error) {
          setDisableNext(true);
          httpErrorHandler(error, notifyCtx, navigate);
        }

        ctx.setOpenProgressStatus(false);
      };
      //after schema start file collection or get the channels file
      const startFilesCollection = async (montage) => {
        //setting pop up
        ctx.setOpenProgressStatus(true);
        ctx.setProgressStatusMessage("Finding Channels");
        try {
          const config = axiosConfig({
            uri: `/montages/channel/collect-files?montage_id=${montage.id}`,
            method: "GET",
          });
          const response = await axiosInstance.current({
            ...config,
          });

          setFilesCollected(true);

          if (response.status === 200) {
            //setting calls submitted and skipped
            const calls = response.data.calls_submitted;
            setCallsSubmitted(calls);
            setCallsSkipped(response.data.calls_skipped);
            setCallsAnonymizing(response.data.calls_anonymizing);
            if (!JSON.parse(localStorage.getItem("mc_status"))) {
              ctx.setOpenProgressStatus(false);

              //setting the consent message
              const message = getConsentMessage(
                response.data.calls_submitted,
                response.data.calls_skipped,
                response.data.calls_anonymizing
              );

              //setting consent data
              ctx.setConsent(true, "Action Needed", message, {
                onNext: {
                  title: "Configure Montage",
                  handler: () => {
                    setChannelJsonExist(false);
                    ctx.setConsent(false);
                  },
                  disable:
                    response.data.calls_anonymizing.length === 0 &&
                    response.data.calls_submitted.length
                      ? false
                      : true,
                },
                onBack: {
                  title: "+Upload Files",
                  handler: () => {
                    ctx.setIsUserConsentOpen(true);
                    navigate(`/montages/${encodeURIComponent(montage.title)}/`);
                    ctx.setConsent(false);
                  },
                  disable: false,
                },
                onCancel: {
                  title: "Cancel",
                  handler: () => {
                    navigate(`/montages/`);
                    ctx.setConsent(false);
                  },
                  disable: false,
                },
              });
            } else {
              setChannelJsonExist(false);
            }
            // necessary for sending channel extraction request
          } else if (response.status === 202) {
            setChannelJsonExist(true);
            //removing temporay edge case check
            localStorage.removeItem("mces");

            ctx.setProgressStatusMessage("Getting Channels Info");
            await downloadChannelsFile(
              response.data.channels_file,
              setChannelsList,
              notifyCtx,
              navigate
            );
            //create config data
            // dispatch({type:'set_state',schema})
            ctx.setOpenProgressStatus(false);
          }
        } catch (error) {
          ctx.setOpenProgressStatus(false);
          if (error.response) {
            if (error.response.status === 404) {
              //no calls found. Empty montage
              notificationsHandler(
                notifyCtx,
                "warning",
                "Montage not contains any file. Please upload a file first."
              );
              navigate(`/montages/${encodeURIComponent(montage.title)}`);
            } else if (
              error.response.status === 400 &&
              error.response.data.detail === "Ongoing channel extraction"
            ) {
              ctx.setOpenProgressStatus(true);
              ctx.setProgressStatusMessage(
                "Already extracting channels, Please Wait"
              );
              return;
            } else {
              httpErrorHandler(error, notifyCtx, navigate);
            }
          }
        }
        ctx.setOpenProgressStatus(false);
      };
      const montage = JSON.parse(localStorage.getItem("current_montage"));

      if (montage) {
        if (montage.id && !filesCollected) {
          setMontageInfo(montage);
          await getSchema();
          await startFilesCollection(montage);
        }
      } else {
        notificationsHandler(notifyCtx, "error", "Montage Info Not Found");
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // extract channels - extract channels api
  useEffect(() => {
    const startChannelExtraction = async (montageId) => {
      ctx.setOpenProgressStatus(true);
      ctx.setProgressStatusMessage("Extracting Channels");

      try {
        const calls = callsSubmitted.map((call, index) => {
          return call.id;
        });

        const config = axiosConfig({
          uri: `/montages/channel/extract?montage_id=${montageId}`,
          method: "POST",

          data: { calls: calls },
        });
        await axiosInstance.current({
          ...config,
        });

        ctx.setProgressStatusMessage("Waiting For Channels Extraction");
      } catch (error) {
        httpErrorHandler(error, notifyCtx, navigate);
        ctx.setOpenProgressStatus(false);
      }
    };
    const montage = JSON.parse(localStorage.getItem("current_montage"));
    if (montage) {
      if (montage.id && !channelJsonExist) {
        //temporary edge case handling - should be handled by backend (montage channel extraction status)
        // const montageChannleExtractionAlreadyStarted =
        //   JSON.parse(localStorage.getItem("mces")) === montage.id;
        // if (!montageChannleExtractionAlreadyStarted) {
        //   localStorage.setItem("mces", JSON.stringify(montage.id));
        //   setMontageInfo(montage);

        // } else {
        //   ctx.setProgressStatusMessage("Already Extracting Channels");
        //   ctx.setOpenProgressStatus(true);
        // }
        startChannelExtraction(montage.id);
      }
    } else {
      notificationsHandler(notifyCtx, "error", "Montage Info Not Found");
    }
    return () => {
      ctx.setOpenProgressStatus(false);
    };
  }, [channelJsonExist]); // eslint-disable-line react-hooks/exhaustive-deps

  // creates local config schema
  useEffect(() => {
    if (channelsList.length) {
      const locally_saved_configurations = localStorage.getItem(
        "montages_configurations"
      );
      if (
        "channels" in schema &&
        schema.channels.length &&
        channelsList.length
      ) {
        if (
          locally_saved_configurations !== undefined &&
          locally_saved_configurations !== "undefined" &&
          locally_saved_configurations
        ) {
          if (!verifySchema(JSON.parse(locally_saved_configurations), schema)) {
            dispatch({
              type: "update_state",
              updatedData: JSON.parse(locally_saved_configurations),
            });
            notificationsHandler(
              notifyCtx,
              "info",
              "Locally Saved Montage Configurations Loaded"
            );
          } else {
            dispatch({
              type: "update_state",
              updatedData: createConfigData(
                schema.channels,
                channelsList,
                setConfigData
              ),
            });
          }
          setDisableNext(false);
        } else {
          dispatch({
            type: "update_state",
            updatedData: createConfigData(
              schema.channels,
              channelsList,
              setConfigData
            ),
          });
          setDisableNext(false);
        }
      } else {
        notificationsHandler(
          notifyCtx,
          "warning",
          "Something Went Wrong in Schema Creation"
        );
      }
    }
  }, [schema, channelsList]); // eslint-disable-line react-hooks/exhaustive-deps

  //look up for socket messsage
  useEffect(() => {
    (async () => {
      if (socketCtx.message.type === "extract_channels.success") {
        const channelsFileUrl = socketCtx.message.data.files;
        ctx.setProgressStatusMessage("Downloading Channels File");
        ctx.setOpenProgressStatus(true);
        await downloadChannelsFile(
          channelsFileUrl[0],
          setChannelsList,
          notifyCtx,
          navigate
        );
        ctx.setOpenProgressStatus(false);
      } else if (socketCtx.message.type === "preprocessing.fail") {
        // localStorage.removeItem("mces");
        alert("Channel Extraction Failed. Please Report the error");
      }
    })();
  }, [socketCtx.message]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOutputFormat = (event) => {
    const finalSettings = additionalSettings.map((el, index) => {
      if (parseInt(event.target.id) === el.id) {
        return { ...el, selected: !el.selected };
      }
      return el;
    });
    setAdditionalSettings(finalSettings);
    localStorage.setItem("additional_settings", JSON.stringify(finalSettings));
  };

  // grid header
  const Header = () => (
    <Grid
      container
      style={{
        backgroundColor: "#E5F3FF",
        padding: "20px 56px",
      }}
    >
      <Grid item xs={12} md={3}>
        <Typography fontWeight={500} fontSize={18}>
          Channel
        </Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography fontWeight={500} fontSize={18}>
          Primary Channel (default N/A)
        </Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Typography fontWeight={500} fontSize={18}>
          Reference Channel (default N/A)
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}></Grid>
      <Typography fontWeight={500} fontSize={18}></Typography>
    </Grid>
  );

  return (
    <>
      {ctx.showUserConsent ? (
        <UserConsent
          files={callsSubmitted}
          skipped={callsSkipped}
          anonymizing={callsAnonymizing}
          montageInfo={montageInfo}
        />
      ) : (
        <></>
      )}

      <Paper
        sx={{
          height: "100%",
          mb: "8rem",
          background: "transparent",
        }}
        elevation={0}
      >
        <ProgressStatus componentLevel={true} />
        <Stack direction="column" spacing={3}>
          <Header />
          {channels.length ? (
            channels.map((row, index) => {
              return (
                <GridRow
                  key={index}
                  rowId={row.id}
                  row={row}
                  channels={channels}
                  dispatch={dispatch}
                  channelsList={channelsList}
                />
              );
            })
          ) : (
            <></>
          )}
          {channels.length ? (
            <AdditionalSettings
              handleAdditionalSettings={handleOutputFormat}
              additionalSettings={additionalSettings}
            />
          ) : (
            <></>
          )}
        </Stack>
      </Paper>
    </>
  );
}
export default MapChannelsHOC(MapChannels);
