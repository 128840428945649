////////////React////////////////
import { useState, useEffect, useContext } from "react";
////////////MUI Imports//////////////////
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
/////////////3rd party////////
import { useOutletContext } from "react-router-dom";
///////////helpers and utils///////
import {
  notificationsHandler,
  setStepperActiveStep,
} from "../../../utils/helpers";
//internal
import GridRow from "./GridRow";
import AdditionalSettings from "./AdditionalSettings";
import Header from "./Header";
import ProgressStatus from "../../progress_status/ProgressStatus";
///////////////Context////////////
import { notifyContext } from "../../../context/notifyContext";
import { globalContext } from "../../../context/globalContext";

export default function PreviewChannel(props) {
  //outlet context
  const { setActiveStep } = useOutletContext();
  const ROWS = JSON.parse(localStorage.getItem("montages_configurations"));
  const [rows] = useState(ROWS ? ROWS : []);
  const notifyCtx = useContext(notifyContext);
  const { setPageTitle } = useContext(globalContext);
  useEffect(() => {
    const current_montage = JSON.parse(localStorage.getItem("current_montage"));
    setPageTitle(current_montage?.title ? current_montage?.title : "Montage");
    //notification
    notificationsHandler(
      notifyCtx,
      "info",
      "Channel will be ignored if it's primary channel is 'N/a' "
    );
  }, [notifyCtx, setPageTitle]);
  //edit channels handler
  const handleEditChannels = (step) => {
    setStepperActiveStep(step, setActiveStep);
  };

  return (
    <>
      <ProgressStatus />
      <Paper
        elevation={0}
        sx={{
          margin: "0px",
          mb: "8rem",

          background: "transparent",
        }}
      >
        <Stack direction="column" spacing={2}>
          <Header />
          {rows.map((row, index) => (
            <GridRow
              key={index}
              row={row}
              editChannels={() => {
                handleEditChannels(1);
              }}
              removeEdit={props.removeEdit}
            />
          ))}
          <AdditionalSettings />
        </Stack>
      </Paper>
    </>
  );
}
PreviewChannel.defaultProps = {
  showOutputFormat: true,
};
