import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  backgroundColor: "#FFFFFF",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function SearchMontage({ setSearchKey }) {
  const [searchText, setSearchText] = useState(null);

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   localStorage.setItem("searchText", searchText);

  //   if (searchText !== "") {
  //     const tempData = originalMontageData.results.filter((item) => {
  //       return item.title.startsWith(searchText);
  //     });
  //     setMontageData({
  //       ...originalMontageData,
  //       count: tempData.length,
  //       results: tempData,
  //     });
  //   } else {
  //     fetchMontages(1);
  //   }
  // };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchText !== null) setSearchKey(searchText);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchText, setSearchKey]);
  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon sx={{ color: "#B1B1B1" }} />
      </SearchIconWrapper>
      <form>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </form>
    </Search>
  );
}
