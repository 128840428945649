import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
//////////////////Internal Components//////////////////
import Status from "../components/status/Status";

const getDate = (datetimestring) => {
  if (datetimestring == null) {
    return "-";
  }
  const options = { day: "numeric", month: "short", year: "numeric" };
  const date_ = new Intl.DateTimeFormat("en-GB", options).format(
    new Date(datetimestring)
  );
  return date_;
};

export default function CreditsTable({ cols, rows, status, sx }) {
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        width: "100%",
        overflowX: "auto",
        mx: "36px",
        ...sx,
      }}
    >
      <Table aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
          <TableRow>
            {cols.map((col, index) => {
              return (
                <TableCell
                  align="left"
                  style={{
                    width: 100,
                    color: "#888888",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                  key={index}
                >
                  {col.name}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.count > 0 ? (
            rows.results.map((row, index) => {
              if (status) {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {parseFloat(row.added).toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#0F0F0F",
                      }}
                    >
                      {parseFloat(row.available).toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#0F0F0F",
                      }}
                    >
                      {parseFloat(row.used).toFixed(2)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#0F0F0F",
                      }}
                    >
                      {getDate(row.added_on)}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#0F0F0F",
                      }}
                    >
                      {getDate(row.validity)}
                    </TableCell>
                    {status ? (
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#0F0F0F",
                        }}
                      >
                        <Status status={row.active ? "active" : "expired"} />
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                );
              } else if (true) {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.credit_used
                        ? parseFloat(row.credit_used).toFixed(2)
                        : 0}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#0F0F0F",
                      }}
                    >
                      {row.montage.title}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#0F0F0F",
                      }}
                    >
                      {row.file_name}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#0F0F0F",
                      }}
                    >
                      {row.service}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#0F0F0F",
                      }}
                    >
                      {getDate(row.time_call_started)}
                    </TableCell>
                    {status ? (
                      <TableCell
                        sx={{
                          fontSize: "16px",
                          fontWeight: "400",
                          color: "#0F0F0F",
                        }}
                      >
                        <Status status={row.active ? "active" : "expired"} />
                      </TableCell>
                    ) : (
                      <></>
                    )}
                  </TableRow>
                );
              } else {
                return <></>;
              }
            })
          ) : (
            <TableRow>
              <TableCell
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "#0F0F0F",
                  textAlign: "center",
                }}
                colSpan={cols?.length}
              >
                No Data Available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
