//////React Imports////////
import { useState, useCallback } from "react";
////////////Mui Imports//////////
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
///////////assets///////////
import FileUploadIcon from "../../assets/montage/file_upload.svg";

//////////styles//////////
import * as styles from "../stepper/stepper_styles";
////////////Internal/////////
import AfterFileDrop from "../stepper/AfterFileDrop";

function FileUploadDialogInner(props) {
  const [dropAreaColor, setDropAreaColor] = useState("#F8F8F8");
  const [selectedFilesList, setSelectedFilesList] = useState([]);
  const [filesProgress, setFilesProgress] = useState([]);
  // const ctx = useContext(globalContext);

  const [filesDropped, setFilesDropped] = useState(false);
  // const navigate = useNavigate();

  const dragOver = (e) => {
    e.preventDefault();
    // console.log("dragover ev triggered");
  };

  const dragEnter = (e) => {
    e.preventDefault();
    // console.log("drag enter triggered");
    setDropAreaColor("#fffffff");
  };

  const dragLeave = (e) => {
    e.preventDefault();
    // console.log("drag leave triggered");
    setDropAreaColor("#f8f8f8");
  };

  const fileDrop = useCallback((e) => {
    e.preventDefault();

    const selectedFiles = e.dataTransfer.files;
    const maxFileCount = selectedFiles.length;
    for (let file = 0; file < maxFileCount; file++) {
      setSelectedFilesList((previousFiles) => {
        return [
          ...previousFiles,
          {
            file: selectedFiles[file],
            fileIndex: previousFiles.length,
            progress: 0,
            uploaded: false,
          },
        ];
      });
      setFilesProgress((prev) => {
        return [
          ...prev,
          {
            file: selectedFiles[file],
            fileIndex: prev.length,
            progress: 0,
          },
        ];
      });
    }
    setFilesDropped(true);
  }, []);

  const getFile = useCallback((event) => {
    const selectedFiles = event.target.files;
    const maxFileCount = selectedFiles.length;

    for (let file = 0; file < maxFileCount; file++) {
      setSelectedFilesList((previousFiles) => {
        return [
          ...previousFiles,
          {
            file: selectedFiles[file],
            fileIndex: previousFiles.length,
            progress: 0,
            uploaded: false,
          },
        ];
      });
      setFilesProgress((prev) => {
        return [
          ...prev,
          {
            file: selectedFiles[file],
            fileIndex: prev.length,
            progress: 0,
          },
        ];
      });
    }
    // console.log(event.target.value);
    // event.target.value = "";
    setFilesDropped(true);
  }, []);
  return (
    <Paper
      sx={{ height: "100%", minHeight: "400px", position: "relative" }}
      elevation={0}
      className="FileUpload-Component"
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
    >
      {!filesDropped ? (
        <Paper
          sx={{
            height: { xs: "100%" },
            width: { xs: "100%" },
            top: "0",
            position: "absolute",
            p: "24px",
          }}
          elevation={0}
        >
          <Paper
            sx={{
              background: `${dropAreaColor}`,
              width: "100%",
              height: "100%",
              border:
                dropAreaColor === "#ffffff" ? "none" : "1.5px dashed black",
            }}
            elevation={0}
          >
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <img
                src={FileUploadIcon}
                alt=""
                style={{
                  width: "100px",
                  height: "100px",
                  marginBottom: "10px",
                }}
              />
              <>
                <Box sx={{ ...styles.input_label_container }}>
                  <label htmlFor="edf_input" style={{ ...styles.label_button }}>
                    Browse Files
                  </label>
                  <input
                    type="file"
                    name="edfs"
                    id="edf_input"
                    style={{ ...styles.input_select }}
                    onChange={getFile}
                    // accept=".edf"
                    multiple
                  />
                </Box>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: "500",
                    fontSize: "16px",
                    lineHeight: "21px",
                    /* identical to box height */
                    letterSpacing: "0.02em",

                    color: "#000000",
                  }}
                >
                  Or drop file's here.
                </Typography>
              </>
            </Stack>
          </Paper>
        </Paper>
      ) : (
        <AfterFileDrop
          selectedFilesList={selectedFilesList}
          setSelectedFilesList={setSelectedFilesList}
          filesProgress={filesProgress}
          setFilesProgress={setFilesProgress}
          getFile={getFile}
          updateCalls={true}
          montage={props.montage}
          setFilesUploading={props.setFilesUploading}
          filesUploading={props.filesUploading}
          disableSetNext={true}
          setCallList={props.setCallList}
        />
      )}
    </Paper>
  );
}
const FileUploadDialog = (props) => {
  const [filesUploading, setFilesUploading] = useState(false); //to block the onclose of dialog
  return (
    <Dialog
      onClose={() => {
        if (filesUploading) {
          return;
        } else {
          props.handleClose();
        }
      }}
      open={props.open}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <DialogContent sx={{ p: 0 }}>
        <FileUploadDialogInner
          {...props}
          setFilesUploading={setFilesUploading}
          filesUploading={filesUploading}
          setCallList={props.setCallList}
        />
      </DialogContent>
    </Dialog>
  );
};
export default FileUploadDialog;
