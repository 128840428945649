import { tooltipClasses } from "@mui/material/Tooltip";
export const statusTooltipConfig = {
  init: {
    tooltip: false,
    sx: {},
  },
  error: {
    tooltip: true,
    sx: {
      [`.${tooltipClasses.tooltip}`]: {
        backgroundColor: "#FFF3F3",
      },
      [`.${tooltipClasses.arrow}`]: {
        color: "#FFF3F3",
      },
    },
  },
  saved: {
    tooltip: false,
    sx: {
      backgroundColor: "#FFF3F3",
    },
  },
};
