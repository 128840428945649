//////////////react ///////////////
import {
  memo,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
///////////////////MUI////////////////
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Checkbox from "@mui/material/Checkbox";
// -----------------Internal Components-----------------
import Btn from "../buttons/Btn";
//context
import { globalContext } from "../../context/globalContext";
import { notifyContext } from "../../context/notifyContext";

//helpers
import {
  downloadCalls,
  axiosConfig,
  httpErrorHandler,
} from "../../utils/helpers";

import ComponentLoader from "../loader/ComponentLoader";
/////////////assests///////////////
import FormatIconImg from "../../assets/montage/csv.svg";
///3rd party
import { useNavigate } from "react-router-dom";
//hooks
import useAxios from "../../hooks/useAxios";

function SingleFileDownloadPopup(props) {
  //context
  const globalCtx = useContext(globalContext);
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();
  const notifyCtx = useContext(notifyContext);
  //states
  const [selectedMenuItem, setSelectedMenuItem] = useState([]);
  const [outputFormats, setOutputFormats] = useState([]);
  const [fetchData, setFetchData] = useState(false);
  const [loader, setLoader] = useState({
    loading: false,
    message: "Getting Available Formats",
    error: false,
  });
  const navigate = useNavigate();

  const handleDownload = () => {
    downloadCalls(
      navigate,
      globalCtx,
      notifyCtx,
      props.filesDownload
        ? props.filesDownload
        : [props.callId ? props.callId : ""],
      axiosInstance,
      selectedMenuItem
    );
    props.setOpen((previousOpen) => !previousOpen);
    localStorage.setItem("selected_formats", JSON.stringify(selectedMenuItem));
  };

  const handleClose = (event) => {
    props.setOpen((previousOpen) => !previousOpen);
    localStorage.setItem("selected_formats", JSON.stringify(selectedMenuItem));
    props.setAnchorEl(null);
  };

  const getAvailableFormats = useCallback(async (abortController) => {
    setLoader({
      loading: true,
      message: "Getting Available Formats",
      error: false,
    });
    try {
      const config = axiosConfig({
        uri: `/montages/schemas/`,
        method: "GET",
      });
      const response = await axiosInstance.current({
        ...config,
        signal: abortController.signal,
      });
      setOutputFormats(response.data[0].data.formats);
      localStorage.setItem(
        "download_formats",
        JSON.stringify(response.data[0].data.formats)
      );
      setLoader({
        loading: false,
        message: "",
        error: false,
      });
    } catch (error) {
      httpErrorHandler(error, notifyCtx, navigate);
      if (error && !error.message === "canceled") {
        setLoader({
          loading: false,
          message: "Getting Available Formats",
          error: true,
        });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //getting available formats
  useEffect(() => {
    const abortController = new AbortController();
    getAvailableFormats(abortController);
    setFetchData(false);
    return () => {
      return abortController.abort();
    };
  }, [fetchData]); // eslint-disable-line react-hooks/exhaustive-deps

  const canBeOpen = props.open && Boolean(props.anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          if (!props.disableClickAway) {
            handleClose();
          }
        }}
      >
        <Popper
          id={id}
          open={props.open ? props.open : false}
          anchorEl={props.anchorEl ? props.anchorEl : null}
          placement={props.location ? props.location : "left-start"}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  bgcolor: "background.paper",
                  zIndex: 3000,
                  // marginTop: "-108px",
                  marginBottom: ".9rem",
                  py: "15px",
                  paddingBottom: "0",
                  maxHeight: "300px",
                  overflow: "auto",
                }}
                // elevation={0}
              >
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      width: "280px",
                      px: "12px",
                    }}
                  >
                    <Typography
                      component="h4"
                      sx={{
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "18px",
                        mb: "15px",
                        color: "#0F0F0F",
                      }}
                    >
                      Select File Type
                    </Typography>
                    <CloseIcon
                      sx={{
                        cursor: "pointer",
                        color: "#A4A4A4",
                        width: "18px",
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    />
                  </Box>
                  <Divider />
                  {loader.loading || loader.error ? (
                    <ComponentLoader
                      loading={loader.loading}
                      message={loader.message}
                      error={loader.error}
                      retry={() => {
                        setFetchData(true);
                      }}
                    />
                  ) : (
                    <>
                      <MenuList sx={{ mt: "1px", mb: "24px", p: 0 }}>
                        {outputFormats.length > 0
                          ? outputFormats.map((format, index) => {
                              return (
                                <MenuItem
                                  key={format + index}
                                  data-value={format}
                                  sx={{
                                    ":hover": { background: "#F2F3F5" },
                                    py: "15px",
                                    "&.selected": {
                                      background: "#F2F3F5",
                                    },
                                    background: selectedMenuItem.includes(
                                      format
                                    )
                                      ? "#F2F3F5"
                                      : "transparent",
                                  }}
                                >
                                  <Checkbox
                                    id={`${format}-${index}`}
                                    inputProps={{
                                      "data-value": format,
                                    }}
                                    sx={{
                                      "&.Mui-checked": {
                                        color: "#10A44B",
                                      },
                                    }}
                                    checked={selectedMenuItem.includes(format)}
                                    data-value={format}
                                    onChange={(e) => {
                                      const currentValue =
                                        e.currentTarget.dataset.value;
                                      selectedMenuItem.length > 0
                                        ? selectedMenuItem.map(
                                            (format, index) => {
                                              if (
                                                selectedMenuItem.includes(
                                                  currentValue
                                                )
                                              ) {
                                                setSelectedMenuItem(
                                                  selectedMenuItem.filter(
                                                    (item) => {
                                                      return (
                                                        item !== currentValue
                                                      );
                                                    }
                                                  )
                                                );
                                              } else {
                                                setSelectedMenuItem([
                                                  ...selectedMenuItem,
                                                  currentValue,
                                                ]);
                                              }
                                              return format;
                                            }
                                          )
                                        : setSelectedMenuItem([
                                            ...selectedMenuItem,
                                            currentValue,
                                          ]);
                                    }}
                                  />
                                  <ListItemIcon>
                                    <img
                                      src={FormatIconImg}
                                      alt="format-icon"
                                    />
                                  </ListItemIcon>
                                  <ListItemText
                                    sx={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      lineHeight: "18px",
                                      color: "#0F0F0F",
                                    }}
                                  >
                                    {format}
                                  </ListItemText>
                                </MenuItem>
                              );
                            })
                          : ""}
                      </MenuList>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                          position: "sticky",
                          bottom: "0",
                          backgroundColor: "#fff",
                          padding: "10px 0px",
                        }}
                      >
                        <Btn
                          onClick={() => {
                            handleDownload();
                          }}
                          type="contained"
                          title={`Download `}
                          startIcon={<FileDownloadOutlinedIcon />}
                          bg="#10A44B"
                          sx={{
                            color: "#fff",
                            border: "1px solid #10A44B",

                            ":hover": {
                              color: "#fff",
                              backgroundColor: "#10A44B",
                              border: "1px solid #10A44B",
                            },
                          }}
                        />
                      </Box>
                    </>
                  )}
                </>
              </Paper>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
    </>
  );
}

export default memo(SingleFileDownloadPopup);
