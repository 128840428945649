/////////////////MUI/////////////////////////
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
///////////////Internal /////////////
import ProgressStatus from "../progress_status/ProgressStatus";
import ServiceCard from "./ServiceCard";
//////////////Context//////////////////
///////////Main Code////////////
export default function ServicesInfo({ servicesData }) {
  return (
    <Box mb="2rem">
      <Stack sx={{ width: "100%", my: "30px" }} direction="column" spacing={2}>
        <Stack direction="column" spacing={1} sx={{}}>
          <Typography fontSize="20px" fontWeight="500" color="#0F0F0F">
            Services - Credits Info
          </Typography>
          <Typography fontSize="16px" fontWeight="400" color="#898989">
            View which service requires how much credits for processing
          </Typography>
        </Stack>
      </Stack>
      <>
        {servicesData ? (
          <Grid
            container
            spacing={3}
            sx={{ width: "100%" }}
            alignItems="stretch"
          >
            {servicesData ? (
              servicesData.map((data, index) => {
                return (
                  <Grid item xs={6} key={index}>
                    <ServiceCard data={data} />
                  </Grid>
                );
              })
            ) : (
              <></>
            )}
          </Grid>
        ) : (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", height: "100%" }}
          >
            <Typography component="p">No Data Available</Typography>
          </Stack>
        )}
      </>

      <ProgressStatus />
    </Box>
  );
}
