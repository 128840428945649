// mui
import { Grid, Typography } from "@mui/material";
// grid header component
export default function Header(props) {
  return (
    <Grid
      container
      style={{
        backgroundColor: "#E5F3FF",
        padding: "20px 0px",
      }}
    >
      <Grid item xs={props.removeEdit ? 4 : 3}>
        <Typography align="center" fontWeight={600} fontSize={18}>
          Channel
        </Typography>
      </Grid>

      <Grid item xs={props.removeEdit ? 4 : 3}>
        <Typography align="center" fontWeight={600} fontSize={18}>
          Primary Channel
        </Typography>
      </Grid>
      <Grid item xs={props.removeEdit ? 4 : 3}>
        <Typography align="center" fontWeight={600} fontSize={18}>
          Reference Channel
        </Typography>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}
