////////////MUI Imports//////////////////
import { Grid, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
//internal
import AdditionalSettings from "../../components/stepper/Preview/AdditionalSettings";
///////////helpers and utils///////

import {
  createMontageConfigPreview,
  getDate,
  getTime,
} from "../../utils/helpers";

function GridRow({ row, editChannels, removeEdit }) {
  const PrimaryRow = () => (
    <Grid container>
      <Grid
        item
        xs={4}
        style={{
          borderBottom: "1px solid #EBEBEB",
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Typography py="30px" fontSize={18} fontWeight={500} align="center">
          {row.channel}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          borderBottom: "1px solid #EBEBEB",
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Typography py="30px" fontSize={18} fontWeight={500} align="center">
          {row.primaryChannel}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          borderBottom: "1px solid #EBEBEB",
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Typography py="30px" fontSize={18} fontWeight={500} align="center">
          {row.refChannel || "N/A"}
        </Typography>
      </Grid>
    </Grid>
  );

  const BackupRow = ({ backupRow }) => (
    <Grid container>
      <Grid
        item
        xs={6}
        style={{
          borderBottom: "1px solid #EBEBEB",
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Typography
          py="30px"
          fontSize={18}
          fontWeight={500}
          align="center"
          color="#8A8A8A"
        >
          {backupRow.backupPrimaryChannel}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          borderBottom: "1px solid #EBEBEB",
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Typography
          py="30px"
          fontSize={18}
          fontWeight={500}
          align="center"
          color="#8A8A8A"
        >
          {backupRow.backupRefChannel || "NULL"}
        </Typography>
      </Grid>
    </Grid>
  );

  const BackupRows = ({ backup }) => (
    <Grid container>
      <Grid
        item
        xs={4}
        style={{
          borderRight: "1px solid #EBEBEB",
        }}
      >
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Typography
            py="30px"
            fontSize={18}
            fontWeight={500}
            align="center"
            color="#8A8A8A"
          >
            Backup Channels
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={8}>
        {backup.map((backupRow, index) => (
          <BackupRow key={index} backupRow={backupRow} />
        ))}
      </Grid>
    </Grid>
  );

  const Row = () => (
    <Grid container>
      <Grid item xs={12}>
        <Stack direction="column">
          <PrimaryRow />
          {row.backups.length ? (
            <BackupRows backup={row.backups || []} />
          ) : null}
        </Stack>
      </Grid>
    </Grid>
  );

  return (
    <Stack
      direction="column"
      style={{
        backgroundColor: "#fff",
        borderRadius: "3px",
      }}
    >
      <Row />
    </Stack>
  );
}
function ViewMontageConfigDialogInner(props) {
  const configData = createMontageConfigPreview(
    props.configData ? props.configData : []
  );

  // grid header
  const Header = () => (
    <Grid
      container
      style={{
        backgroundColor: "#E5F3FF",
        padding: "20px 0px",
      }}
    >
      <Grid item xs={4}>
        <Typography align="center" fontWeight={600} fontSize={18}>
          Channel
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <Typography align="center" fontWeight={600} fontSize={18}>
          Primary Channel
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography align="center" fontWeight={600} fontSize={18}>
          Reference Channel
        </Typography>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          margin: "0px",

          background: "transparent",
        }}
      >
        <Stack direction="column" spacing={2}>
          <Header />
          {configData ? (
            configData.map((row, index) => (
              <GridRow key={index} row={row} removeEdit={props.removeEdit} />
            ))
          ) : (
            <></>
          )}
          <AdditionalSettings data={props.optionals} />
        </Stack>
      </Paper>
    </>
  );
}
export default function ViewMontageConfigDialog(props) {
  return (
    <Dialog
      onClose={() => {
        props.handleClose();
      }}
      open={props.open}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <DialogContent sx={{ px: "40px", py: "30px", background: "#F5F6FA" }}>
        <Box>
          <Stack sx={{ mb: "20px" }}>
            <Typography
              component="span"
              sx={{
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "26px",
                color: "#0F0F0F",
              }}
            >
              Montage Configuiration
            </Typography>
            <Typography
              component="span"
              sx={{
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#959595",
              }}
            >
              Created On -{" "}
              {`${getDate(props.montageData.created_at)} ${getTime(
                props.montageData.created_at
              )}`}
            </Typography>
          </Stack>
          <ViewMontageConfigDialogInner
            configData={props.configData}
            optionals={props.optionals}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

ViewMontageConfigDialogInner.defaultProps = {
  configData: [],
  optionals: [],
  montageData: [],
};
