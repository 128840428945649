import logo from "../../assets/logo/Neurobit-Score-Logo-Transparent 1.svg";
import {
  Container,
  Paper,
  Typography,
  Stack,
  FormControl,
} from "@mui/material";

//3rd party
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FORM_VALIDATION } from "../../utils/formValidation";
// internal
import ButtonWrapper from "./FormComponents/Button";
import TextField from "../forms/FormComponents/TextField";

const LOCAL_FORM_VALIDATION = yup.object().shape({
  password1: FORM_VALIDATION["password1"],
  password2: FORM_VALIDATION["password1"],
});
const FORM_INITIAL_VALUES = {
  password1: "",
  password2: "",
};
export default function ResetPasswordForm({ handleSubmit }) {
  return (
    <Container
      maxWidth={false}
      style={{
        padding: "50px",
        backgroundColor: "#E5E5E5",
        height: "100vh",
      }}
    >
      <Stack justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          style={{
            width: "100%",
            maxWidth: "700px",
            padding: "64px 64px  200px 64px",
            borderRadius: "12px",
          }}
        >
          <Stack direction="column" spacing={8}>
            <Stack justifyContent="center" alignItems="center">
              <img src={logo} alt="logo" width={108} height={60} />
            </Stack>
            <Stack direction="column" spacing={4} sx={{ width: "100%" }}>
              <Typography
                style={{
                  fontSize: "28px",
                  fontWeight: "500",
                  lineHeight: "36.46px",
                  letterSpacing: "0em",
                  color: "#0F0F0F",
                }}
              >
                Reset Password{" "}
              </Typography>

              <Formik
                initialValues={FORM_INITIAL_VALUES}
                validationSchema={LOCAL_FORM_VALIDATION}
                onSubmit={async (values, { setSubmitting }) => {
                  setSubmitting(true);
                  await handleSubmit(values.password1, values.password2);
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting, errors, touched, isValid }) => {
                  return (
                    <Form>
                      <FormControl fullWidth sx={{ marginBottom: "24px" }}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color:
                              touched?.password1 && errors?.password1
                                ? "#d32f2f"
                                : "#898989",
                          }}
                        >
                          New Password
                        </Typography>
                        <TextField name="password1" type="password" />
                      </FormControl>
                      <FormControl fullWidth>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            color:
                              touched?.password2 && errors?.password2
                                ? "#d32f2f"
                                : "#898989",
                          }}
                        >
                          Confirm Password{" "}
                        </Typography>
                        <TextField name="password2" type="password" />
                      </FormControl>
                      <ButtonWrapper
                        fullWidth
                        disableElevation
                        disabled={!isValid || isSubmitting}
                      >
                        RESET PASSWORD
                      </ButtonWrapper>
                    </Form>
                  );
                }}
              </Formik>
            </Stack>
          </Stack>

          <Stack direction="column">
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "400",
                color: "#8A8A8A",
                textAlign: "center",
                lineHeight: "0.1em",
                width: "100%",
                marginTop: "72px",
              }}
            >
              If you need any help, contact{" "}
              <font color="#2F7EC7">Neurobit PSG Support</font>
            </Typography>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
}
