// libraries
import { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";

// material ui system
import { useTheme } from "@mui/system";

// material ui modules
import SvgIcon from "@mui/material/SvgIcon";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Grow from "@mui/material/Grow";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";

// material ui icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";

// utils
import urlpatterns from "../utils/urls.json";
import { httpErrorHandler } from "../utils/helpers";
import useAuth from "../hooks/useAuth";

//assets
import { ReactComponent as ProfileIcon } from "../assets/navbar/user_icon.svg";
import { ReactComponent as LogoutIcon } from "../assets/navbar/logout.svg";

//context
import { notifyContext } from "../context/notifyContext";
import { useNavigate } from "react-router-dom";
import { globalContext } from "../context/globalContext";

// Dropdown menu: Shows the user's name afnd logout button
const DropdownMenu = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [auth, dispatch] = useAuth();
  const theme = useTheme();

  const avatarStyle = {
    backgroundColor: "#2f7ec7",
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: "5px",
    marginRight: "10px",
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }
  const globalCtx = useContext(globalContext);
  const notifyCtx = useContext(notifyContext);

  const navigate = useNavigate();

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = async () => {
    try {
      dispatch({ type: "signout", error: null, globalCtx: globalCtx });
    } catch (error) {
      httpErrorHandler(error, notifyCtx, navigate);
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const avatar = {
    backgroundColor: "#2f7ec7",
    width: theme.spacing(3),
    height: theme.spacing(3),
    padding: "5px",
    marginRight: "10px",
  };

  return (
    <Box sx={{}}>
      <Button
        ref={anchorRef}
        id="account-menu"
        aria-controls={open ? "account-options" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        size="small"
        variant="text"
      >
        <Avatar alt={auth?.user?.first_name} sx={avatarStyle}>
          <PersonIcon fontSize="small" />
        </Avatar>

        <Typography
          variant="body2"
          sx={{
            color: theme.palette.primary,
            marginRight: "3px",
            textTransform: "capitalize",
            fontSize: "1rem",
          }}
          noWrap
        >
          {auth?.user?.first_name}
        </Typography>
        <ExpandMoreIcon
          fontSize="small"
          style={{ color: "#939393", marginTop: "2px" }}
        />
      </Button>

      <Stack direction="row" spacing={2}>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          style={{ zIndex: "1000" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    onKeyDown={handleListKeyDown}
                    sx={{ width: "240px", py: 0 }}
                  >
                    <MenuItem sx={{ py: "10px" }}>
                      <Avatar alt={auth?.user?.first_name} sx={avatar}>
                        <PersonIcon fontSize="small" />
                      </Avatar>
                      <ListItemText>
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.primary,
                            marginRight: "3px",
                            textTransform: "capitalize",
                            fontSize: "12px",
                          }}
                          noWrap
                        >
                          {auth?.user?.first_name} {auth?.user?.last_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#666666",
                            fontSize: "12px",
                          }}
                          noWrap
                        >
                          {auth?.user?.email}
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                    <Divider style={{ margin: 0 }} />
                    <MenuItem
                      component={Link}
                      to={urlpatterns.profile}
                      sx={{ py: "10px" }}
                    >
                      <ListItemIcon>
                        <SvgIcon>
                          <ProfileIcon></ProfileIcon>
                        </SvgIcon>
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#333333",
                            fontSize: "14px",
                          }}
                          onClick={handleClose}
                          noWrap
                        >
                          My Profile
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                    <Divider style={{ margin: 0 }} />
                    <MenuItem onClick={handleLogout} sx={{ py: "10px" }}>
                      <ListItemIcon>
                        <SvgIcon>
                          <LogoutIcon></LogoutIcon>
                        </SvgIcon>
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#333333",
                            fontSize: "14px",
                          }}
                          noWrap
                        >
                          Log Out
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Stack>
    </Box>
  );
};

export default DropdownMenu;
