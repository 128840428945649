import PropTypes from "prop-types";
//mui
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
function ServiceCard({ data, ...props }) {
  return (
    <Paper
      elevation={0}
      sx={{
        padding: "32px",
        width: "100%",
        height: "100%",
      }}
    >
      <Stack sx={{ width: "100%" }} direction="column" spacing={3}>
        <Stack sx={{ width: "100%" }} direction="row">
          <Stack
            sx={{ width: "100%" }}
            direction="column"
            justifyContent="center"
            alignItems="start"
            spacing={0.5}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#888888",
              }}
            >
              Service Type
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                color: "#0F0F0F",
              }}
            >
              {data.service.name.toUpperCase()}
            </Typography>
          </Stack>
          <Stack
            sx={{ width: "100%" }}
            direction="column"
            justifyContent="center"
            alignItems="start"
            spacing={0.5}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                color: "#888888",
              }}
            >
              Credits Per Test
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                color: "#0F0F0F",
              }}
            >
              {data.price}
            </Typography>
          </Stack>
        </Stack>
        <Stack sx={{ width: "100%" }} direction="row">
          <Stack
            sx={{ width: "100%" }}
            direction="column"
            justifyContent="center"
            alignItems="start"
            spacing={0.5}
          >
            <Typography fontSize="16px" fontWeight="400" color="#888888">
              Service Description
            </Typography>
            <Typography fontSize="16px" fontWeight="400" color="#4A4A4A">
              {data.service.description}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
}

export default ServiceCard;

ServiceCard.propTypes = {
  data: PropTypes.object.isRequired,
};
